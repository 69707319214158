/* eslint-disable camelcase */
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash-es';
import { loading, arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import Grid from 'components/reusables/Grid';
import { clearViewData } from 'actions';
import { fetchAssemblies, fetchCreateAssembly, fetchUpdateAssembly, fetchDeleteAssembly } from './actions';
import { FETCH_ASSEMBLIES, FETCH_CREATE_ASSEMBLY, FETCH_UPDATE_ASSEMBLY, FETCH_DELETE_ASSEMBLY } from './actionTypes';
import { fetchBhomeModels } from '../BhomeModels/actions';

const Assemblies = () => {
    const [, setGridApi] = useState(null);

    const dispatch = useDispatch();

    const assemblies = useSelector(state => state.assemblies.assemblies, arrayOfObjectsShallowEqual);
    const isFetched = useSelector(state => state.assemblies.isFetched);
    const models = useSelector(state => state.bhomeModels.models, arrayOfObjectsShallowEqual);
    const areModelsFetched = useSelector(state => state.bhomeModels.isFetched);

    useEffect(() => {
        dispatch(fetchAssemblies());
        dispatch(fetchBhomeModels());

        return () => dispatch(clearViewData());
    }, [dispatch]);

    const handleOnGridReady = useCallback(gridApi => {
        setGridApi(gridApi);
    }, []);

    const handleNewItemSave = useCallback(
        newItem => {
            const { id, bhome_model_id, ...rest } = newItem;
            const modelId = get(
                models.find(model => model.name === bhome_model_id),
                'id',
                null
            );

            dispatch(
                fetchCreateAssembly({
                    ...rest,
                    bhome_model_id: modelId,
                })
            );
        },
        [dispatch, models]
    );

    const handleItemDelete = useCallback(data => dispatch(fetchDeleteAssembly(data.id)), [dispatch]);

    const onCellChange = useCallback(
        ({ data }) => {
            const { id, bhome_model_id, ...assembly } = data;

            dispatch(
                fetchUpdateAssembly(id, {
                    ...assembly,
                    bhome_model_id: get(
                        models.find(model => model.id === bhome_model_id || model.name === bhome_model_id),
                        'id',
                        null
                    ),
                })
            );
        },
        [dispatch, models]
    );

    const gridOptions = useMemo(
        () => ({
            columnDefs: [
                { headerName: 'ID', field: 'id', editable: false },
                {
                    headerName: 'Description',
                    field: 'description',
                    editable: true,
                },
                { headerName: 'Version', field: 'version', editable: true },
                {
                    headerName: 'Bhome model ID',
                    field: 'bhome_model_id',
                    cellRenderer: 'link',
                    cellRendererParams: {
                        url: '/bhome-models',
                    },
                    cellEditor: 'agSelectCellEditor',
                    editable: !!models.length,
                    cellEditorParams: {
                        values: [null, ...models.map(model => model.name)],
                    },
                    valueGetter: params =>
                        params.data.addMode
                            ? params.data.bhome_model_id
                            : get(
                                  models.find(model => model.id === params.data.bhome_model_id),
                                  'name'
                              ),
                },
            ],
        }),
        [models]
    );

    const canRenderGrid = useMemo(() => !!(isFetched && areModelsFetched), [isFetched, areModelsFetched]);

    return (
        <div id="view" className="page assemblies">
            {canRenderGrid && (
                <Grid
                    onGridReady={handleOnGridReady}
                    rowData={assemblies}
                    gridOptions={gridOptions}
                    handleNewItemSave={handleNewItemSave}
                    handleItemDelete={handleItemDelete}
                    onCellChange={onCellChange}
                />
            )}
        </div>
    );
};

export default loading([
    FETCH_ASSEMBLIES.default,
    FETCH_CREATE_ASSEMBLY.default,
    FETCH_UPDATE_ASSEMBLY.default,
    FETCH_DELETE_ASSEMBLY.default,
])(Assemblies);
