import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const SwitchOff = forwardRef(({ size = 24, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height={size}
    viewBox="0 0 40 24"
    {...rest}
  >
    <rect width="40" height="24" rx="12" fill="#E6E6E6" />
    <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white" stroke="#e6e6e6" />
  </svg>
));

SwitchOff.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

SwitchOff.displayName = 'SwitchOff';

export default SwitchOff;
