import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const BorderedBee = forwardRef(({ color = '#111111', size = 18, ...rest }, ref) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    stroke={color}
    {...rest}
  >
    <path
      d="M13.75 1.125H15.875C16.4273 1.125 16.875 1.57272 16.875 2.125V4.25"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.25 16.875H2.125C1.57272 16.875 1.125 16.4273 1.125 15.875V13.75"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.875 13.75V15.875C16.875 16.4273 16.4273 16.875 15.875 16.875H13.75"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.125 4.25V2.125C1.125 1.57272 1.57272 1.125 2.125 1.125H4.25"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.6163 10.3509C8.90293 9.06428 9.37703 7.45233 8.67523 6.75054C7.97344 6.04875 6.3615 6.52287 5.07487 7.80951C3.78824 9.09616 3.31414 10.7081 4.01593 11.4099C4.71773 12.1117 6.32967 11.6376 7.6163 10.3509Z"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 7.64116C9 9.40708 11.3261 11.7343 13.0932 11.7343C13.5861 11.7343 14.3084 11.5006 14.3084 10.5191C14.3084 8.75323 11.9823 6.42596 10.2152 6.42596C10.0074 6.42086 9.80104 6.46203 9.6111 6.54648"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.18359 11.4089C6.30453 12.1722 6.63024 12.8883 7.12602 13.4811C7.6218 14.0738 8.26909 14.521 8.99893 14.775C9.72762 14.5214 10.374 14.0752 10.8695 13.4838C11.365 12.8924 11.6911 12.1778 11.8131 11.416"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.73579 6.60173C6.64318 6.45377 6.59334 6.28306 6.5918 6.10851C6.5918 5.31076 7.66964 4.66406 8.99921 4.66406C10.3288 4.66406 11.4066 5.31076 11.4066 6.10851C11.4053 6.27743 11.3585 6.44286 11.2711 6.58746"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6541 11.6153C11.6541 11.6153 11.2585 11.2483 10.7045 11.089C10.1505 10.9296 9.57634 10.8516 8.99994 10.8572C8.42275 10.8515 7.84781 10.9298 7.29316 11.0897C6.73851 11.2495 6.3457 11.6153 6.3457 11.6153"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.7043 12.9845C10.1503 12.8251 9.57615 12.7471 8.99975 12.7527C8.42256 12.747 7.84762 12.8253 7.29297 12.9852"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.9668 3.3999C5.9668 3.3999 7.48347 3.65268 8.49458 4.6638"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0336 3.3999C12.0336 3.3999 10.517 3.65268 9.50586 4.6638"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

BorderedBee.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

BorderedBee.displayName = 'BorderedBee';

export default BorderedBee;
