import { useState } from 'react';
import { getFieldValue, getInitialActiveState, POSITIONS, TYPES } from './utils';

const useTextField = ({ value, inputRef, onFocus, onBlur, onChange, type, inputProps }) => {
  const initialActiveState = getInitialActiveState({ value, inputRef });

  const [active, setActive] = useState(initialActiveState);

  const isLabelDisplayedOnTop = inputProps?.position === POSITIONS.START || initialActiveState;

  const handleOnFocus = (e) => {
    setActive(true);
    onFocus?.(e);
  };

  const handleOnBlur = (e) => {
    const { current } = inputRef;
    if (!isLabelDisplayedOnTop || !current.value) {
      setActive(false);
    }
    onBlur?.(e);
  };

  const handleOnChange = (e) => {
    let val = getFieldValue(type, e);
    if (type === TYPES.PERCENTAGE) {
      val = val.replace('%', '');
    }
    onChange && onChange(val, e);
  };

  return {
    active,
    handleOnFocus,
    handleOnBlur,
    handleOnChange,
    initialActiveState
  };
};

export default useTextField;
