import { FETCH_USERS, FETCH_CREATE_USER, FETCH_DELETE_USER, FETCH_UPDATE_USER } from '../actionTypes';

export const fetchUsers = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_USERS.default,
        url: '/users/get-all',
    },
});

export const fetchCreateUser = user => ({
    type: 'FETCH',
    params: {
        type: FETCH_CREATE_USER.default,
        url: '/users',
        method: 'POST',
        body: {
            user,
        },
    },
});

export const fetchDeleteUser = id => ({
    type: 'FETCH',
    params: {
        type: FETCH_DELETE_USER.default,
        url: `/users/${id}`,
        method: 'DELETE',
    },
});

export const fetchUpdateUser = (id, user) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_USER.default,
        url: `/users/${id}`,
        method: 'PUT',
        body: {
            user,
        },
    },
});
