import { CLEAR_VIEW_DATA } from 'actionTypes';
import { FETCH_BHOMES, FETCH_CREATE_BHOME, FETCH_UPDATE_BHOME, FETCH_DELETE_BHOME } from '../actionTypes';

const initialState = {
    bhomes: [],
    isFetched: false,
};

const bhomes = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BHOMES.success:
            return {
                ...state,
                bhomes: action.data.bhomes,
                isFetched: true,
            };
        case FETCH_CREATE_BHOME:
            return {
                ...state,
                bhomes: [...state, action.data.bhome],
            };
        case FETCH_UPDATE_BHOME.success:
            return {
                ...state,
                bhomes: state.bhomes.map(item => {
                    if (item.id === action.data.bhome.id) {
                        return {
                            ...action.data.bhome,
                        };
                    }

                    return item;
                }),
            };
        case FETCH_DELETE_BHOME:
            return {
                ...state,
                bhomes: state.bhomes.filter(bhome => bhome.id !== action.data.id),
            };

        case CLEAR_VIEW_DATA:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default bhomes;
