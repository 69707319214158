import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loading, arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { showToast } from '@beewise/toast';
import Grid from 'components/reusables/Grid';
import { clearViewData } from 'actions';
import { fetchSettings, fetchUpdateSetting } from './actions';
import { FETCH_SETTINGS, FETCH_UPDATE_SETTING } from './actionTypes';

const Users = () => {
    const [, setGridApi] = useState(null);

    const dispatch = useDispatch();

    const settings = useSelector(state => state.settings.settings, arrayOfObjectsShallowEqual);
    const isFetched = useSelector(state => state.settings.isFetched);

    useEffect(() => {
        dispatch(fetchSettings());

        return () => dispatch(clearViewData());
    }, [dispatch]);

    const handleOnGridReady = useCallback(gridApi => {
        setGridApi(gridApi);
    }, []);

    const onCellChange = useCallback(
        ({ data }) => {
            const { id, ...setting } = data;
            try {
                const parsedData = JSON.parse(setting.data);
                dispatch(
                    fetchUpdateSetting(id, {
                        data: parsedData,
                    })
                );
            } catch (e) {
                showToast('Invalid JSON.', { toastType: 'toast-error' });
            }
        },
        [dispatch]
    );

    const gridOptions = useMemo(
        () => ({
            columnDefs: [
                { headerName: 'ID', field: 'id', editable: false },
                { headerName: 'Type', field: 'type', editable: false },
                {
                    headerName: 'Data',
                    field: 'data',
                    editable: true,
                    valueGetter: params => JSON.stringify(params.data.data),
                },
                {
                    headerName: 'Last Updated',
                    field: 'last_updated',
                    editable: false,
                },
                {
                    headerName: 'Created At',
                    field: 'created_at',
                    editable: false,
                },
            ],
        }),
        []
    );

    // load everything before render grid so valueGetters will work properly
    const canRenderGrid = useMemo(() => isFetched, [isFetched]);

    return (
        <div id="view" className="page settings">
            {canRenderGrid && (
                <Grid
                    onGridReady={handleOnGridReady}
                    rowData={settings}
                    gridOptions={gridOptions}
                    onCellChange={onCellChange}
                />
            )}
        </div>
    );
};

export default loading([FETCH_SETTINGS.default, FETCH_UPDATE_SETTING.default])(Users);
