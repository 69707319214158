import {
    FETCH_CREATE_USER_PERMISSION,
    FETCH_DELETE_USER_PERMISSION,
    FETCH_UPDATE_USER_PERMISSION,
    FETCH_USER_PERMISSIONS,
} from '../actionTypes';

export const fetchPermissions = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_USER_PERMISSIONS.default,
        url: '/users/permissions',
    },
});

export const fetchCreatePermission = permission => ({
    type: 'FETCH',
    params: {
        type: FETCH_CREATE_USER_PERMISSION.default,
        url: '/users/permissions',
        method: 'POST',
        body: {
            permission,
        },
    },
});

export const fetchDeletePermission = id => ({
    type: 'FETCH',
    params: {
        type: FETCH_DELETE_USER_PERMISSION.default,
        url: `/users/permissions/${id}`,
        method: 'DELETE',
    },
});

export const fetchUpdatePermission = (id, permission) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_USER_PERMISSION.default,
        url: `/users/permissions/${id}`,
        method: 'PUT',
        body: {
            permission,
        },
    },
});
