import constants from '@beewise/constants';

export const isArrayNonEmpty = (arr) => Array.isArray(arr) && arr.length;

export const getIsShiftedFrame = ({ initialPartitionIndex, currentPartitionIndex, frame, index }) =>
  frame.initialFrameIndex !== index &&
  frame.initialFrameIndex > initialPartitionIndex &&
  index > currentPartitionIndex;

export const isContractPlanner = ({ currentPartitionIndex, movedFrames, initialPartitionIndex }) =>
  currentPartitionIndex > 0 &&
  movedFrames
    .slice(0, currentPartitionIndex)
    .some((frame) => frame.initialFrameIndex > initialPartitionIndex);

export const isExpandPlanner = ({ currentPartitionIndex, initialPartitionIndex, movedFrames }) =>
  movedFrames
    .slice(currentPartitionIndex + 1)
    .some((frame) => frame.initialFrameIndex < initialPartitionIndex);

export const isShiftPlanner = ({ currentPartitionIndex, movedFrames, initialPartitionIndex }) =>
  movedFrames.some((frame, index) =>
    getIsShiftedFrame({ frame, initialPartitionIndex, currentPartitionIndex, index })
  );

/**
 * Checks if the moved frames data is valid.
 * @param {Object} param0 - Object containing movedFrames.
 * @param {Array} param0.inputFrame - frame which was moved.
 * @param {Array} param0.inputFrameIndex - current index of moved frame.
 * @param {Array} param0.combWidth - width of comb frame
 * @param {Array} param0.movedFrames - Array of moved frames.
 */

export const calculateLayout = ({ movedFrames, inputFrame, inputFrameIndex, combWidth }) => {
  const initialIndex = inputFrame.initialFrameIndex;
  const combWidthShift = initialIndex < inputFrameIndex ? -combWidth : combWidth;
  movedFrames.forEach((frame, index) => {
    if (index === inputFrameIndex) {
      frame.place.position.x = inputFrame.finalPositionX;
    } else if (
      (index >= initialIndex && index < inputFrameIndex) ||
      (index <= initialIndex && index > inputFrameIndex)
    ) {
      frame.place.position.x += combWidthShift;
    }
  });
  return movedFrames;
};

/**
 * Checks if the moved frames data is valid.
 * @param {Object} param0 - Object containing movedFrames.
 * @param {Array} param0.frames - Array of whole bhome frames before movement.
 * @param {Array} param0.selectedFrames - Array of frames that been selected as hive.
 * @param {Array} param0.movedFrames - Array of moved frames.
 * @returns {boolean} True if frames, selectedFrames, movedFrames are arrays and have elements,
 *                    and if selectedFrames and movedFrames have same length, false otherwise.
 */
export const isFramesDataValid = ({ selectedFrames, movedFrames }) =>
  isArrayNonEmpty(selectedFrames) &&
  isArrayNonEmpty(movedFrames) &&
  selectedFrames.length === movedFrames.length;

/**
 * Creates a command to move a frame.
 * @param {Object} param0 - Object containing frame, place, shake, and untuck_direction.
 * @param {Object} param0.frame - The frame to be moved.
 * @param {Object} param0.place - The place to move the frame to.
 * @param {boolean} param0.shake - Whether to shake the frame or not.
 * @param {number} param0.untuckDirection - The direction to untuck the frame.
 * @returns {Object} An object representing the move frame command.
 */
export const createMoveFrameCommand = ({ frame, place, shake, extraShake, untuckDirection }) => ({
  command: constants.COMMANDS.MOVE_FRAME,
  params: {
    frame,
    place,
    shake,
    ...(extraShake ? { extra_shake: extraShake } : {}),
    untuck_direction: untuckDirection
  }
});

/**
 * Creates a command to make space.
 * @param {Object} param0 - Object containing frame, place, shake, and untuck_direction.
 * @param {String} param0.frameType - The frame tyoe to be moved.
 * @param {Object} param0.place - The place to move the frame to.
 * @param {number} param0.direction - The direction to make space -1 | 1.
 * @returns {Object} An object representing the move frame command.
 */
export const createMakeSpaceCommand = ({ frameType, place, direction }) => ({
  command: constants.COMMANDS.MAKE_SPACE,
  params: {
    frame_type: frameType,
    place,
    direction
  }
});

/**
 * Creates a command to close the hive.
 * @param {Object} param0 - Object containing frame.
 * @param {Object} param0.frame - The frame involved in the close hive action.
 * @returns {Object} An object representing the close hive command.
 */
export const createCloseHiveCommand = ({ frame }) => ({
  command: constants.COMMANDS.CLOSE_HIVE,
  params: { tuck: { direction: 1, frame } }
});

/**
 * Creates a command to move motor.
 * Used as workaround to prevent the drift in the position of the X-axis.
 * Is user before each shiftHive/contractHive/expandHive action and after closeHive command
 * @returns {Object} An object representing the move motor command.
 */
export const createHomeXCommand = () => ({
  command: constants.COMMANDS.MOVE_MOTOR,
  params: {
    axis: 'X',
    change: 0,
    maxIrun: false,
    skipEncoder: false,
    allowUnsafeTMove: false,
    allowUnsafeMotorMove: false
  }
});

/**
 * Retrieves data for a specific frame.
 * @param {Object} param0 - Object containing frame, station, and positionX.
 * @param {Object} param0.frame - The frame object.
 * @param {string} param0.station - The station where the frame is located.
 * @param {number} param0.positionX - The X position of the frame.
 * @returns {Object} An object containing frame data.
 */
export const getFrameData = ({ frame, station, positionX }) => ({
  frame_type: frame.type,
  id: frame.rfid,
  place: {
    station: station ?? frame.place.station,
    position: { x: positionX ?? frame.place.position.x }
  }
});
