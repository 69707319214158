import { CLEAR_VIEW_DATA } from 'actionTypes';
import { FETCH_DELETE_COMPANIES_TYPES, FETCH_CREATE_COMPANIES_TYPES, FETCH_COMPANIES_TYPES } from '../actionTypes';

const initialState = {
    types: [],
    isFetched: false,
};

const companiesTypes = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_COMPANIES_TYPES.success:
            return {
                ...state,
                types: action.data.types,
                isFetched: true,
            };
        case FETCH_CREATE_COMPANIES_TYPES.success:
            return {
                ...state,
                types: [...state.types, action.data.type],
            };
        case FETCH_DELETE_COMPANIES_TYPES.success:
            return {
                ...state,
                types: state.types.filter(
                    item => item.company_id !== action.data.companyId || item.company_type_id !== action.data.typeId
                ),
            };
        case CLEAR_VIEW_DATA:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default companiesTypes;
