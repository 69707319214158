import { components } from 'react-select';
import PropTypes from 'prop-types';
import React from 'react';

const Menu = (props) => {
  const { label, onClick } = props.selectProps.addNewOption;

  return (
    <components.Menu {...props} className="menu-list--creatable">
      {props.children}
      <button className="select-field-add" onClick={onClick}>
        {label}
      </button>
    </components.Menu>
  );
};

Menu.propTypes = {
  selectProps: PropTypes.shape({
    addNewOption: PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired
    })
  }).isRequired,
  children: PropTypes.node
};

export default Menu;
