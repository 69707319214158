import { CLEAR_VIEW_DATA } from 'actionTypes';
import { FETCH_LOCATIONS, FETCH_CREATE_LOCATION, FETCH_UPDATE_LOCATION, FETCH_DELETE_LOCATION } from '../actionTypes';

const initialState = {
    locations: [],
    isFetched: false,
};

const locations = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_LOCATIONS.success:
            return {
                ...state,
                locations: action.data.locations,
                isFetched: true,
            };
        case FETCH_CREATE_LOCATION.success:
            return {
                ...state,
                locations: [action.data.location, ...state.locations],
            };
        case FETCH_DELETE_LOCATION.success:
            return {
                ...state,
                locations: state.locations.filter(location => location.id !== action.data.id),
            };
        case FETCH_UPDATE_LOCATION.success:
            return {
                ...state,
                locations: state.locations.map(location => {
                    if (location.id === action.data.location.id) {
                        return {
                            ...action.data.location,
                        };
                    }

                    return location;
                }),
            };
        case CLEAR_VIEW_DATA:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default locations;
