import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const UnpackedBox = forwardRef(({ color = '#111111', width = 17, height = 17 }, ref) => (
  <svg
    ref={ref}
    width={width}
    height={height}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 6.25V1M8.5 1L10.75 3.25M8.5 1L6.25 3.25M2.5 8.125V15.625H14.5V8.125M2.5 8.125L1 6.25M2.5 8.125H7M14.5 8.125L16 6.25M14.5 8.125H10M7 8.125V12.25L8.5 10.75L10 12.25V8.125M7 8.125H10"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

UnpackedBox.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

export default UnpackedBox;
