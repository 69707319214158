import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@beewise/button';
import { SelectField } from '@beewise/select-field';
import TextField from '@beewise/text-field';
import { clearCreatedBhome, fetchCreateNewBhome } from '../actions';

const BHOME_CAPACITY_OPTIONS = [
    { label: '696 (Bhome 24)', value: 696 },
    { label: '720 (Bhome 24)', value: 720 },
];

const BhomeCreationTab = ({ isSuperViewer }) => {
    const [slotsAmount, setSlotsAmount] = useState(BHOME_CAPACITY_OPTIONS[0].value);
    const [id, setId] = useState('');

    const createdBhome = useSelector(state => state.dashboard.createdBhome);
    const dispatch = useDispatch();

    const handleSaveBhome = useCallback(() => {
        dispatch(fetchCreateNewBhome(slotsAmount, Number(id)));
    }, [dispatch, id, slotsAmount]);

    const handleClearBhome = useCallback(() => {
        if (createdBhome) {
            dispatch(clearCreatedBhome());
        }
    }, [createdBhome, dispatch]);

    return (
        <div className="bhome-creation">
            {createdBhome ? (
                <div>
                    <div>Bhome with ID: {createdBhome.id} was successfully created</div>
                    <Button className="clear-bhome" onClick={handleClearBhome}>
                        Create one more beehome
                    </Button>
                </div>
            ) : (
                <>
                    <h2>Create New Beehome</h2>
                    <h4>New Beehome will be assigned to Beewise Company (id: 1)</h4>
                    <div className="bhome-creation-form">
                        <div className="bhome-creation-form-group">
                            <TextField
                                label="ID (optional)"
                                className="distance-input"
                                value={id}
                                onChange={setId}
                                type="number"
                                size="small"
                            />
                        </div>
                        <div className="bhome-creation-label">Slots Amount</div>
                        <SelectField
                            options={BHOME_CAPACITY_OPTIONS}
                            onChange={setSlotsAmount}
                            value={slotsAmount}
                            placeholder="Select beehome slots amount"
                            size="small"
                        />
                        {!isSuperViewer && (
                            <Button className="btn-primary" onClick={handleSaveBhome}>
                                Create new Beehome
                            </Button>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

BhomeCreationTab.propTypes = {
    isSuperViewer: PropTypes.bool,
};

export default BhomeCreationTab;
