import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const DefaultMarker = forwardRef(({ size = 42, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 42 42"
    fill="none"
    {...rest}
  >
    <path
      d="M29.1553 6.89845L29.145 6.89241L29.1347 6.88653L22.7259 3.25402C21.6553 2.64722 20.3447 2.64722 19.2742 3.25402L20.0084 4.54938L19.2741 3.25402L12.8653 6.88653L12.855 6.89241L12.8447 6.89845L6.49446 10.6324C5.43367 11.2561 4.77839 12.3911 4.7686 13.6216L4.71005 20.9881L4.70995 21L4.71005 21.0119L4.7686 28.3784C4.77839 29.6089 5.43367 30.7439 6.49446 31.3676L12.8447 35.1016L12.855 35.1076L12.8653 35.1135L19.2741 38.746C20.3447 39.3528 21.6553 39.3528 22.7259 38.746L29.1347 35.1135L29.145 35.1076L29.1553 35.1016L35.5055 31.3676C36.5663 30.7439 37.2216 29.6089 37.2314 28.3784L37.29 21.0119L37.29 21L37.29 20.9881L37.2314 13.6216C37.2216 12.3911 36.5663 11.2561 35.5055 10.6324L29.1553 6.89845Z"
      fill="white"
      stroke="#4E4540"
      strokeWidth="3"
    />
    <path
      d="M19.3423 24.64C19.5983 24.64 19.7903 24.6987 19.9183 24.816C20.0463 24.9333 20.1103 25.0987 20.1103 25.312C20.1103 25.7707 19.8543 26 19.3423 26H13.9983C13.4756 26 13.2143 25.7707 13.2143 25.312C13.2143 25.0987 13.2783 24.9333 13.4062 24.816C13.5449 24.6987 13.7423 24.64 13.9983 24.64H15.8383V16.656L14.1903 17.696C14.0836 17.76 13.9769 17.792 13.8703 17.792C13.6889 17.792 13.5289 17.712 13.3903 17.552C13.2623 17.392 13.1983 17.2107 13.1983 17.008C13.1983 16.7627 13.3049 16.5707 13.5182 16.432L15.9823 14.864C16.2276 14.7147 16.4623 14.64 16.6863 14.64C16.9209 14.64 17.1129 14.7147 17.2623 14.864C17.4116 15.0133 17.4863 15.216 17.4863 15.472V24.64H19.3423ZM29.048 24.64C29.304 24.64 29.496 24.6987 29.624 24.816C29.7627 24.9333 29.832 25.0987 29.832 25.312C29.832 25.7707 29.5707 26 29.048 26H22.872C22.6373 26 22.4507 25.936 22.312 25.808C22.184 25.6693 22.12 25.4987 22.12 25.296C22.12 25.0507 22.216 24.8267 22.408 24.624L26.168 20.512C26.6693 19.968 27.032 19.472 27.256 19.024C27.48 18.576 27.592 18.1333 27.592 17.696C27.592 17.1307 27.4267 16.6987 27.096 16.4C26.776 16.0907 26.3173 15.936 25.72 15.936C24.8133 15.936 23.912 16.2827 23.016 16.976C22.8987 17.0507 22.7973 17.1093 22.712 17.152C22.6373 17.184 22.5573 17.2 22.472 17.2C22.3227 17.2 22.1947 17.1307 22.088 16.992C21.9813 16.8533 21.928 16.688 21.928 16.496C21.928 16.2293 22.0347 16.016 22.248 15.856C22.696 15.472 23.2347 15.168 23.864 14.944C24.4933 14.7093 25.128 14.592 25.768 14.592C26.824 14.592 27.6613 14.864 28.28 15.408C28.9093 15.9413 29.224 16.6667 29.224 17.584C29.224 18.2347 29.08 18.8533 28.792 19.44C28.5147 20.0267 28.0453 20.6827 27.384 21.408L24.36 24.64H29.048Z"
      fill="#4E4540"
    />
    <path
      d="M20.0138 0.558978C20.6256 0.212238 21.3745 0.212238 21.9862 0.558978L30.135 5.17772L38.2093 9.92542C38.8155 10.2818 39.19 10.9304 39.1955 11.6336L39.27 21L39.1955 30.3664C39.19 31.0696 38.8155 31.7182 38.2093 32.0746L30.135 36.8223L21.9862 41.441C21.3744 41.7878 20.6255 41.7878 20.0138 41.441L11.865 36.8223L3.79066 32.0746C3.1845 31.7182 2.81005 31.0696 2.80446 30.3664L2.73 21L2.80446 11.6336C2.81005 10.9304 3.1845 10.2818 3.79066 9.92541L11.865 5.17772L20.0138 0.558978Z"
      fill="white"
    />
    <path
      d="M20.7535 4.86394C20.9064 4.77725 21.0936 4.77725 21.2465 4.86394L28.08 8.73714L34.851 12.7184C35.0025 12.8076 35.0961 12.9697 35.0975 13.1455L35.16 21L35.0975 28.8545C35.0961 29.0303 35.0025 29.1924 34.851 29.2815L28.08 33.2629L21.2465 37.1361C21.0936 37.2227 20.9064 37.2227 20.7535 37.1361L13.9201 33.2629L7.14904 29.2816C6.99749 29.1924 6.90388 29.0303 6.90248 28.8545L6.84005 21L6.90248 13.1455C6.90388 12.9697 6.9975 12.8076 7.14903 12.7184L13.9201 8.73711L20.7535 4.86394Z"
      fill="white"
      stroke="#4E4540"
      strokeWidth="3"
    />
    <path
      d="M20.0138 11.559C20.6255 11.2122 21.3744 11.2122 21.9862 11.559L25.35 13.4656L28.6831 15.4254C29.2892 15.7818 29.6637 16.4304 29.6693 17.1336L29.7 21L29.6693 24.8664C29.6637 25.5696 29.2892 26.2182 28.6831 26.5746L25.35 28.5344L21.9862 30.441C21.3745 30.7878 20.6255 30.7878 20.0138 30.441L16.65 28.5344L13.3169 26.5746C12.7108 26.2182 12.3363 25.5696 12.3307 24.8664L12.3 21L12.3307 17.1336C12.3363 16.4304 12.7108 15.7818 13.3169 15.4254L16.65 13.4656L20.0138 11.559Z"
      fill="#4E4540"
    />
  </svg>
));

DefaultMarker.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

DefaultMarker.displayName = 'DefaultMarker';

export default DefaultMarker;
