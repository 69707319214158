import React from 'react';
import PropTypes from 'prop-types';
import { InputFormField } from '@beewise/hook-form';

const CreationFields = ({ control }) => (
    <>
        <InputFormField
            className="software-bundle-input"
            label="New Middleware Version"
            name="middlewareVersion"
            control={control}
        />
        <InputFormField
            className="software-bundle-input"
            label="New Middleware Update Script"
            name="middlewareUpdateScript"
            control={control}
        />
        <InputFormField
            className="software-bundle-input"
            label="New Firmware Version"
            name="firmwareVersion"
            control={control}
        />
        <InputFormField
            className="software-bundle-input"
            label="New Firmware Binary URL"
            name="firmwareBinaryUrl"
            control={control}
        />
        <InputFormField
            className="software-bundle-input"
            label="New Camera Server Version"
            name="cameraServerVersion"
            control={control}
        />
        <InputFormField className="software-bundle-input" label="New Cli Version" name="cliVersion" control={control} />
    </>
);

CreationFields.propTypes = {
    control: PropTypes.shape().isRequired,
};

export default CreationFields;
