import { FETCH_COMPANIES, FETCH_CREATE_COMPANY, FETCH_DELETE_COMPANY, FETCH_UPDATE_COMPANY } from '../actionTypes';

export const fetchCompanies = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_COMPANIES.default,
        url: '/companies',
    },
});

export const fetchCreateCompany = company => ({
    type: 'FETCH',
    params: {
        type: FETCH_CREATE_COMPANY.default,
        url: '/companies',
        method: 'POST',
        body: {
            company,
        },
    },
});

export const fetchDeleteCompany = id => ({
    type: 'FETCH',
    params: {
        type: FETCH_DELETE_COMPANY.default,
        url: `/companies/${id}`,
        method: 'DELETE',
    },
});

export const fetchUpdateCompany = (id, company) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_COMPANY.default,
        url: `/companies/${id}`,
        method: 'PUT',
        body: {
            company,
        },
    },
});
