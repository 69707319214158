/* eslint-disable camelcase */
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash-es';
import { loading, arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import Grid from 'components/reusables/Grid';
import { fetchUserTypes } from 'components/views/UserTypes/actions';
import { fetchUserRoles } from 'components/views/UserRoles/actions';
import { clearViewData } from 'actions';
import { fetchCreateUser, fetchDeleteUser, fetchUpdateUser, fetchUsers } from './actions';
import { FETCH_USERS, FETCH_CREATE_USER, FETCH_DELETE_USER, FETCH_UPDATE_USER } from './actionTypes';

const Users = () => {
    const [, setGridApi] = useState(null);

    const dispatch = useDispatch();

    const users = useSelector(state => state.users.users, arrayOfObjectsShallowEqual);
    const types = useSelector(state => state.userTypes.types, arrayOfObjectsShallowEqual);
    const roles = useSelector(state => state.userRoles.roles, arrayOfObjectsShallowEqual);
    const isFetched = useSelector(state => state.users.isFetched);
    const areTypesFetched = useSelector(state => state.userTypes.isFetched);
    const areRolesFetched = useSelector(state => state.userRoles.isFetched);

    useEffect(() => {
        dispatch(fetchUsers());
        dispatch(fetchUserTypes());
        dispatch(fetchUserRoles());

        return () => dispatch(clearViewData());
    }, [dispatch]);

    const handleOnGridReady = useCallback(gridApi => {
        setGridApi(gridApi);
    }, []);

    const handleNewItemSave = useCallback(
        newItem => {
            const { id, user_type, role_id, ...rest } = newItem;
            const typeId = get(
                types.find(item => item.name === user_type),
                'id',
                null
            );
            const roleId = get(
                roles.find(item => item.name === role_id),
                'id',
                null
            );

            dispatch(
                fetchCreateUser({
                    ...rest,
                    user_type: typeId,
                    role_id: roleId,
                })
            );
        },
        [dispatch, types, roles]
    );

    const handleItemDelete = useCallback(data => dispatch(fetchDeleteUser(data.id)), [dispatch]);

    const onCellChange = useCallback(
        ({ data }) => {
            const { id, invitation_date, last_visit, user_type, role_id, ...user } = data;

            dispatch(
                fetchUpdateUser(id, {
                    ...user,
                    user_type: get(
                        types.find(item => item.id === user_type || item.name === user_type),
                        'id',
                        null
                    ),
                    role_id: get(
                        roles.find(item => item.id === role_id || item.name === role_id),
                        'id',
                        null
                    ),
                })
            );
        },
        [dispatch, types, roles]
    );

    const gridOptions = useMemo(
        () => ({
            columnDefs: [
                { headerName: 'ID', field: 'id', editable: false },
                {
                    headerName: 'Email',
                    field: 'email',
                    editable: params => params.data.addMode,
                },
                { headerName: 'Username', field: 'username', editable: true },
                {
                    headerName: 'User Type',
                    field: 'user_type',
                    cellRenderer: 'link',
                    cellRendererParams: {
                        url: '/user-types',
                    },
                    cellEditor: 'agSelectCellEditor',
                    editable: !!types.length,
                    cellEditorParams: {
                        values: [null, ...types.map(item => item.name)],
                    },
                    valueGetter: params =>
                        params.data.addMode
                            ? params.data.user_type
                            : get(
                                  types.find(item => item.id === params.data.user_type),
                                  'name'
                              ),
                },
                {
                    headerName: 'Role ID',
                    field: 'role_id',
                    cellRenderer: 'link',
                    cellRendererParams: {
                        url: '/user-roles',
                    },
                    cellEditor: 'agSelectCellEditor',
                    editable: !!roles.length,
                    cellEditorParams: {
                        values: [null, ...roles.map(item => item.name)],
                    },
                    valueGetter: params =>
                        params.data.addMode
                            ? params.data.role_id
                            : get(
                                  roles.find(item => item.id === params.data.role_id),
                                  'name'
                              ),
                },
                { headerName: 'Phone', field: 'phone', editable: true },
                {
                    headerName: 'Last Visit',
                    field: 'last_visit',
                    editable: false,
                    valueGetter: params =>
                        params.data.last_visit ? new Date(params.data.last_visit).toLocaleString() : '',
                },
                {
                    headerName: 'Invitation Date',
                    field: 'invitation_date',
                    editable: false,
                    valueGetter: params =>
                        params.data.invitation_date ? new Date(params.data.invitation_date).toLocaleString() : '',
                },
            ],
        }),
        [types, roles]
    );

    // load everything before render grid so valueGetters will work properly
    const canRenderGrid = useMemo(
        () => isFetched && areTypesFetched && areRolesFetched,
        [isFetched, areTypesFetched, areRolesFetched]
    );

    return (
        <div id="view" className="page users">
            {canRenderGrid && (
                <Grid
                    onGridReady={handleOnGridReady}
                    rowData={users}
                    gridOptions={gridOptions}
                    handleNewItemSave={handleNewItemSave}
                    handleItemDelete={handleItemDelete}
                    onCellChange={onCellChange}
                    disableCreate
                />
            )}
        </div>
    );
};

export default loading([
    FETCH_USERS.default,
    FETCH_UPDATE_USER.default,
    FETCH_DELETE_USER.default,
    FETCH_CREATE_USER.default,
])(Users);
