import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash-es';
import { showToast } from '@beewise/toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import { UI_ENV } from '../../../../config';

const getUiLinkPrefix = () => {
    switch (UI_ENV) {
        case 'prod':
            return 'app';
        case 'lab':
            return 'dev';
        default:
            return UI_ENV;
    }
};

const CopyRenderer = ({ value }) => {
    const handleCopy = useCallback(() => {
        navigator.clipboard.writeText(`https://${getUiLinkPrefix()}.beewise.ag/public/${value}`);
        showToast('Copied', {
            toastType: 'toast-success',
        });
    }, [value]);

    return (
        <div className="copy-renderer">
            {value}
            {!!value && !isEmpty(value) && <FontAwesomeIcon className="icon-copy" icon={faCopy} onClick={handleCopy} />}
        </div>
    );
};

CopyRenderer.propTypes = {
    value: PropTypes.string,
};

export default CopyRenderer;
