import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Ai = forwardRef(({ color = 'currentColor', size = 16, ...rest }, ref) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    stroke={color}
    {...rest}
  >
    <circle cx="2.34783" cy="2.34783" r="0.847826" fill={color} />
    <circle cx="2.34783" cy="5.174" r="0.847826" fill={color} />
    <circle cx="7.99999" cy="5.174" r="0.847826" fill={color} />
    <circle cx="13.6521" cy="5.174" r="0.847826" fill={color} />
    <circle cx="2.34783" cy="7.99993" r="0.847826" fill={color} />
    <circle cx="7.99999" cy="7.99993" r="0.847826" fill={color} />
    <circle cx="13.6521" cy="7.99993" r="0.847826" fill={color} />
    <circle cx="10.8261" cy="7.99993" r="0.847826" fill={color} />
    <circle cx="5.17394" cy="7.99993" r="0.847826" fill={color} />
    <circle cx="2.34783" cy="10.8261" r="0.847826" fill={color} />
    <circle cx="7.99999" cy="10.8261" r="0.847826" fill={color} />
    <circle cx="13.6521" cy="10.8261" r="0.847826" fill={color} />
    <circle cx="2.34783" cy="13.6523" r="0.847826" fill={color} />
    <circle cx="5.17394" cy="2.34783" r="0.847826" fill={color} />
    <circle cx="5.17394" cy="13.6523" r="0.847826" fill={color} />
    <circle cx="7.99999" cy="2.34783" r="0.847826" fill={color} />
    <circle cx="7.99999" cy="13.6523" r="0.847826" fill={color} />
    <circle cx="10.8261" cy="2.34783" r="0.847826" fill={color} />
    <circle cx="10.8261" cy="13.6523" r="0.847826" fill={color} />
    <circle cx="13.6521" cy="2.34783" r="0.847826" fill={color} />
    <circle cx="13.6521" cy="13.6523" r="0.847826" fill={color} />
  </svg>
));

Ai.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

Ai.displayName = 'Ai';

export default Ai;
