import React from 'react';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import { useForm } from 'react-hook-form';
import { getDirtyFormFields } from '@beewise/react-utils';
import {
    InputFormField,
    SelectFormField,
    ROOT_ERRORS,
    getRootLevelErrors,
    FormField,
    CheckboxFormField,
} from '@beewise/hook-form';
import { DefaultEditor } from 'react-simple-wysiwyg';
import { useDispatch } from 'react-redux';
import { fetchCreateSoftwareBundle } from '../actions';
import CreationFields from './BhomeSoftwareUpdate/CreationFields';

import { bhomeVersions } from './enum';

const defaultValues = {
    bhomeVersion: bhomeVersions[0].value,
    middlewareVersion: '',
    cliVersion: '',
    firmwareVersion: '',
    middlewareUpdateScript: '',
    firmwareBinaryUrl: '',
    versionNumber: '',
    cameraServerVersion: '',
    note: '',
};

const handleValidate = state => {
    if (state.firmwareVersion || state.firmwareBinaryUrl) {
        return state.firmwareVersion && state.firmwareBinaryUrl;
    }
    return state.middlewareVersion || state.middlewareUpdateScript || state.cameraServerVersion || state.cliVersion;
};

const BhomeSoftwareBundle = ({ isSuperViewer }) => {
    const form = useForm({
        defaultValues,
    });
    const dispatch = useDispatch();

    const {
        handleSubmit,
        control,
        formState: { errors, dirtyFields },
        setError,
        reset,
    } = form;

    const onSubmit = state => {
        const isValid = handleValidate(state);
        if (!isValid) {
            return setError(ROOT_ERRORS.FORM_ERROR, { type: 'validation', message: 'Please fill all required fields' });
        }
        const fields = getDirtyFormFields({ dirtyFields, initialFields: defaultValues, newFields: state });
        dispatch(fetchCreateSoftwareBundle(fields, () => reset(defaultValues)));
    };

    const rootErrors = getRootLevelErrors(errors);

    return (
        <div className="software-bundle">
            <div>
                <SelectFormField
                    name="bhomeVersion"
                    label="Select Bhome Version"
                    options={bhomeVersions}
                    control={control}
                    className="software-bundle-input"
                    required
                />
                <CreationFields control={control} />
                <InputFormField
                    name="versionNumber"
                    label="New Version Number"
                    control={control}
                    className="software-bundle-input"
                    required
                />
                <CheckboxFormField name="rebootAfterUpdate" label="Reboot After Update" control={control} />
                <div className="error">{rootErrors}</div>
                {!isSuperViewer && (
                    <Button className="btn-primary" onClick={handleSubmit(onSubmit)}>
                        Create Bundle
                    </Button>
                )}
            </div>
            <div>
                <FormField control={control} Component={DefaultEditor} name="note" />
            </div>
        </div>
    );
};

BhomeSoftwareBundle.propTypes = {
    isSuperViewer: PropTypes.bool,
};

export default BhomeSoftwareBundle;
