import React from 'react';
import { BrowserRouter as Router, Routes as DomRoutes, Route } from 'react-router-dom';
import PrivateRoute from 'components/reusables/PrivateRoute';
import PublicRoute from 'components/reusables/PublicRoute';
import AppHeader from 'components/reusables/AppHeader';
import { AnalProvider, PermissionsProvider } from '@beewise/react-utils';
import { UI_ENV, TRACKING_CODE } from 'config';

import SignIn from 'components/views/SignIn';
import ChangePassword from 'components/views/PasswordSetup';

import Companies from 'components/views/Companies';
import CompanyType from 'components/views/CompanyType';
import CompaniesTypes from 'components/views/CompaniesTypes';
import CompanyBhomes from 'components/views/CompanyBhomes';
import CompanyUsers from 'components/views/CompanyUsers';
import Users from 'components/views/Users';
import UserTypes from 'components/views/UserTypes';
import UserRoles from 'components/views/UserRoles';
import UserBhomes from 'components/views/UserBhomes';
import UserPermissions from 'components/views/UserPermissions';
import LocationTypes from './components/views/LocationTypes';
import BhomeModels from './components/views/BhomeModels';
import BhomeAssemblies from './components/views/BhomeAssemblies';
import Assemblies from './components/views/Assemblies';
import Locations from './components/views/Locations';
import Bhomes from './components/views/Bhomes';
import Dashboard from './components/views/Dashboard';
import Settings from './components/views/Settings';
import Ranches from './components/views/Ranches';
import RanchLocations from './components/views/RanchLocations';
import RanchOrders from './components/views/RanchMilestones';
import Conference from './components/views/Conference';
import SoftwareBundles from './components/views/SoftwareBundles';
import WeatherData from './components/views/WeatherData';
import Incubators from './components/views/Incubators';
import PublicBhomeCodes from './components/views/PublicBhomeCodes';

const Routes = () => (
    <Router>
        <PermissionsProvider app="admin">
            <AnalProvider trackingCode={TRACKING_CODE} UI_ENV={UI_ENV}>
                <AppHeader />
                <section id="view">
                    <DomRoutes>
                        <Route path="/signin" element={<PublicRoute component={SignIn} />} />
                        <Route path="/setup-password" element={<PublicRoute component={ChangePassword} />} />
                        <Route path="/" element={<PrivateRoute component={Dashboard} />} />
                        <Route path="/bhomes" element={<PrivateRoute component={Bhomes} />} />
                        <Route path="/companies" element={<PrivateRoute component={Companies} />} />
                        <Route path="/company-type" element={<PrivateRoute component={CompanyType} />} />
                        <Route path="/companies-types" element={<PrivateRoute component={CompaniesTypes} />} />
                        <Route path="/company-bhomes" element={<PrivateRoute component={CompanyBhomes} />} />
                        <Route path="/company-users" element={<PrivateRoute component={CompanyUsers} />} />
                        <Route path="/users" element={<PrivateRoute component={Users} />} />
                        <Route path="/user-types" element={<PrivateRoute component={UserTypes} />} />
                        <Route path="/user-roles" element={<PrivateRoute component={UserRoles} />} />
                        <Route path="/user-bhomes" element={<PrivateRoute component={UserBhomes} />} />
                        <Route path="/user-permissions" element={<PrivateRoute component={UserPermissions} />} />
                        <Route path="/location-types" element={<PrivateRoute component={LocationTypes} />} />
                        <Route path="/bhome-models" element={<PrivateRoute component={BhomeModels} />} />
                        <Route path="/bhome-assemblies" element={<PrivateRoute component={BhomeAssemblies} />} />
                        <Route path="/assemblies" element={<PrivateRoute component={Assemblies} />} />
                        <Route path="/locations" element={<PrivateRoute component={Locations} />} />
                        <Route path="/settings" element={<PrivateRoute component={Settings} />} />
                        <Route path="/ranches" element={<PrivateRoute component={Ranches} />} />
                        <Route path="/ranch-locations" element={<PrivateRoute component={RanchLocations} />} />
                        <Route path="/ranch-milestones" element={<PrivateRoute component={RanchOrders} />} />
                        <Route path="/conference" element={<PrivateRoute component={Conference} />} />
                        <Route path="/software-bundles" element={<PrivateRoute component={SoftwareBundles} />} />
                        <Route path="/weather-data" element={<PrivateRoute component={WeatherData} />} />
                        <Route path="/incubators" element={<PrivateRoute component={Incubators} />} />
                        <Route path="/public-bhome-codes" element={<PrivateRoute component={PublicBhomeCodes} />} />
                    </DomRoutes>
                </section>
            </AnalProvider>
        </PermissionsProvider>
    </Router>
);

export default Routes;
