import React from 'react';
import Tooltip from '@beewise/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNote } from '@fortawesome/pro-light-svg-icons';

import PropTypes from 'prop-types';

const NoteRenderer = ({ value }) =>
    value ? (
        <Tooltip content={value} hoverable position="bottom center">
            <FontAwesomeIcon icon={faNote} />
        </Tooltip>
    ) : null;

NoteRenderer.propTypes = {
    value: PropTypes.string,
};

export default NoteRenderer;
