import { FETCH_LOCATIONS, FETCH_CREATE_LOCATION, FETCH_UPDATE_LOCATION, FETCH_DELETE_LOCATION } from '../actionTypes';

export const fetchLocations = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_LOCATIONS.default,
        url: '/companies/company-locations',
    },
});

export const fetchCreateLocation = location => ({
    type: 'FETCH',
    params: {
        type: FETCH_CREATE_LOCATION.default,
        url: '/companies/company-locations',
        method: 'POST',
        body: {
            location,
        },
    },
});

export const fetchDeleteLocation = id => ({
    type: 'FETCH',
    params: {
        type: FETCH_DELETE_LOCATION.default,
        url: `/companies/company-locations/${id}`,
        method: 'DELETE',
    },
});

export const fetchUpdateLocation = (id, location) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_LOCATION.default,
        url: `/companies/company-locations/${id}`,
        method: 'PUT',
        body: {
            location,
        },
    },
});
