import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@beewise/text-field';
import FormField from '../FormField';

const InputFormField = React.forwardRef(({ ...props }, ref) => (
  <FormField {...props} Component={TextField} ref={ref} />
));

InputFormField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape().isRequired,
  required: PropTypes.bool,
  label: PropTypes.string
};

export default InputFormField;
