import React, { useEffect, useCallback, useMemo, useState } from 'react';
import Tooltip from '@beewise/tooltip';
import Icon from '@beewise/icon';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import { useWatch } from 'react-hook-form';
import { arrayOfObjectsShallowEqual, usePermission } from '@beewise/react-utils';
import { useDispatch, useSelector } from 'react-redux';
import { SelectFormField, CheckboxFormField, InputFormField } from '@beewise/hook-form';
import SelectGridField from 'components/reusables/SelectGridField';
import { isBoolean, isEqual } from 'lodash-es';
import { validateDependentFields } from './utils';
import { gridBhomeSoftwareUpdateOptions } from '../utils';
import { bhomeVersions } from '../enum';

import { fetchDeleteBhomeBundle } from '../../actions';

const ALL_VERSIONS = 'All';

const bhomeVersionsAll = [{ value: ALL_VERSIONS, label: ALL_VERSIONS }, ...bhomeVersions];

const getFullBundle = ({ bundlesDisplayed, bundle }) => {
    const bundleObject = bundlesDisplayed.find(({ id }) => id === bundle);
    return { ...bundleObject, id: bundle };
};

const RegularBhomeUpdate = ({ bhomeOptions, form }) => {
    const [manualBhomeIds, setManualBhomeIds] = useState('');
    const dispatch = useDispatch();
    const isDeleteAllowed = usePermission('deleteBundle', 'admin');

    const { setValue, control, getValues } = form;
    const [bhomeVersion, bundle, bhomeIds] = useWatch({ name: ['bhomeVersion', 'bundle', 'bhomeIds'], control });

    const bundles = useSelector(state => state.dashboard.softwareBundles, arrayOfObjectsShallowEqual);

    const bundlesDisplayed = useMemo(
        () =>
            bhomeVersion === ALL_VERSIONS ? bundles : bundles.filter(bundle => bundle.bhomeVersion === bhomeVersion),
        [bhomeVersion, bundles]
    );

    const resetFields = fields => fields.forEach(field => setValue(field, null));

    useEffect(() => {
        if (bhomeVersion) {
            resetFields(['bundle', 'bhomeIds']);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bhomeVersion]);

    useEffect(() => {
        if (!bhomeIds?.length && !manualBhomeIds?.length) {
            return;
        }

        if (!isEqual(bhomeIds?.join(' '), manualBhomeIds.trim())) {
            setManualBhomeIds(bhomeIds?.join(' ') || '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bhomeIds]);

    useEffect(() => {
        const softwareBundle = bundlesDisplayed.find(({ id }) => id === bundle);
        setValue('rebootAfterUpdate', softwareBundle?.bundle?.rebootAfterUpdate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bundle]);

    const bundleOptions = bundlesDisplayed.map(item => ({
        label: item.bundle.versionNumber,
        value: item.id,
    }));

    const fullBundle = getFullBundle({ bundlesDisplayed, bundle });

    const handleDeleteBundle = useCallback(() => {
        if (bundle) {
            dispatch(fetchDeleteBhomeBundle(bundle, () => setValue('bundle', null)));
        }
    }, [dispatch, bundle, setValue]);

    const handleInputChange = useCallback(
        e => {
            setManualBhomeIds(e);
            const ids = e.split(' ').reduce((acc, id) => {
                if (!id) {
                    return acc;
                }
                acc.push(+id);
                return acc;
            }, []);

            setValue('bhomeIds', ids);
        },
        [setValue]
    );

    return (
        <div className="software-update-content">
            <div className="software-update-section">
                <SelectFormField
                    name="bhomeVersion"
                    label="Select Bhome Version"
                    options={bhomeVersionsAll}
                    className="software-update-input"
                    required
                    control={control}
                />
                <div className="row">
                    <SelectGridField
                        name="bhomeIds"
                        gridName="Select Bhomes"
                        isMulti
                        gridOptions={gridBhomeSoftwareUpdateOptions}
                        onSelect={value => setValue('bhomeIds', value)}
                        label="Select Bhome"
                        options={bhomeOptions}
                        className="software-update-input"
                        control={control}
                        isDisabled={!bhomeVersion}
                        validate={validateDependentFields('isAllBhomes', getValues)}
                        size="extra-large"
                        selectedBhomeIds={bhomeIds || []}
                    />
                    <InputFormField
                        disabled={!bhomeVersion}
                        className="select-grid-field-input"
                        label="Select Bhome"
                        name="manualBhomeIds"
                        value={manualBhomeIds}
                        control={control}
                        onChange={handleInputChange}
                    />
                    <CheckboxFormField
                        name="isAllBhomes"
                        className="software-update-checkbox"
                        label="All"
                        disabled={!bhomeOptions.length || !bhomeVersion}
                        control={control}
                        validate={validateDependentFields('bhomeIds', getValues)}
                    />
                </div>
                <CheckboxFormField
                    name="rebootAfterUpdate"
                    className="software-reboot-update"
                    label="Reboot after update"
                    disabled={!bhomeOptions.length}
                    control={control}
                />
                <CheckboxFormField
                    name="enforceImmediateAction"
                    className="software-reboot-update"
                    label="Enforce Immediate"
                    disabled={!bhomeOptions.length}
                    control={control}
                />
            </div>
            <div className="software-update-section">
                <div className="row">
                    <SelectFormField
                        name="bundle"
                        search
                        label="Select Bundle"
                        options={bundleOptions}
                        isDisabled={!bhomeVersion}
                        className="software-update-input software-update-select-bundle"
                        required
                        control={control}
                    />
                    {fullBundle?.note && (
                        <Tooltip
                            content={
                                <div
                                    className="text-edited"
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                        __html: fullBundle.note,
                                    }}
                                />
                            }
                            hoverable
                            position="bottom center"
                            action="click"
                            className="software-update-tooltip"
                        >
                            <Icon type="help-circle" className="help-grey" />
                        </Tooltip>
                    )}
                </div>
                {fullBundle?.bundle && (
                    <div className="software-bundle-info">
                        {Object.keys(fullBundle?.bundle)?.map(key => (
                            <div key={key}>
                                <span className="bold">{key}:</span> {/* eslint-disable-next-line no-nested-ternary */}
                                {isBoolean(fullBundle.bundle[key])
                                    ? fullBundle.bundle[key]
                                        ? 'yes'
                                        : 'no'
                                    : fullBundle.bundle[key]}
                            </div>
                        ))}
                    </div>
                )}
                {isDeleteAllowed && bundle && (
                    <Button onClick={handleDeleteBundle} className="btn-primary">
                        Delete Bundle
                    </Button>
                )}
            </div>
        </div>
    );
};

RegularBhomeUpdate.propTypes = {
    bhomeOptions: PropTypes.arrayOf(PropTypes.shape()),
    form: PropTypes.shape(),
};

export default RegularBhomeUpdate;
