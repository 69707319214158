import React from 'react';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import { usePermission } from '@beewise/react-utils';

const DeleteItem = ({ handleDelete, enableDelete }) => {
    const canDelete = usePermission('canDelete', 'admin');

    if (!canDelete && !enableDelete) {
        return null;
    }

    return <Button onClick={handleDelete}>Delete</Button>;
};

DeleteItem.propTypes = {
    handleDelete: PropTypes.func,
    enableDelete: PropTypes.bool,
};

export default DeleteItem;
