/* eslint-disable camelcase */
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loading, arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import Grid from 'components/reusables/Grid';
import { clearViewData } from 'actions';
import { fetchCompanyUsers, fetchCreateCompanyUser, fetchDeleteCompanyUser } from './actions';
import { FETCH_COMPANY_USERS, FETCH_CREATE_COMPANY_USER, FETCH_DELETE_COMPANY_USER } from './actionTypes';

const CompanyUsers = () => {
    const [, setGridApi] = useState(null);

    const dispatch = useDispatch();

    const users = useSelector(state => state.companyUsers.users, arrayOfObjectsShallowEqual);

    useEffect(() => {
        dispatch(fetchCompanyUsers());

        return () => dispatch(clearViewData());
    }, [dispatch]);

    const handleOnGridReady = useCallback(gridApi => {
        setGridApi(gridApi);
    }, []);

    const handleNewItemSave = useCallback(
        ({ company_id, user_id }) => {
            dispatch(fetchCreateCompanyUser(company_id, user_id));
        },
        [dispatch]
    );

    const handleItemDelete = useCallback(
        ({ company_id, user_id }) => dispatch(fetchDeleteCompanyUser(company_id, user_id)),
        [dispatch]
    );

    const gridOptions = useMemo(
        () => ({
            columnDefs: [
                {
                    headerName: 'Company ID',
                    field: 'company_id',
                    editable: params => params.data.addMode,
                    cellRenderer: 'link',
                    cellRendererParams: {
                        url: '/companies',
                    },
                },
                {
                    headerName: 'User ID',
                    field: 'user_id',
                    editable: params => params.data.addMode,
                    cellRenderer: 'link',
                    cellRendererParams: {
                        url: '/users',
                    },
                },
            ],
        }),
        []
    );

    return (
        <div id="view" className="page company-users">
            <Grid
                onGridReady={handleOnGridReady}
                rowData={users}
                gridOptions={gridOptions}
                handleNewItemSave={handleNewItemSave}
                handleItemDelete={handleItemDelete}
            />
        </div>
    );
};

export default loading([
    FETCH_COMPANY_USERS.default,
    FETCH_CREATE_COMPANY_USER.default,
    FETCH_DELETE_COMPANY_USER.default,
])(CompanyUsers);
