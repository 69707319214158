import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const Link = ({ url, value }) => {
    const navigate = useNavigate();

    const goTo = useCallback(() => {
        navigate(url);
    }, [url, navigate]);

    return (
        <div className="grid-link-wrapper" onClick={goTo} role="presentation">
            {value}
        </div>
    );
};

Link.propTypes = {
    url: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Link;
