import 'es6-symbol/implement';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BeewiseToastContainer } from '@beewise/toast';
import store from './store';
import Routes from './routes';

import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

const App = () => (
    <Provider store={store}>
        <div id="app" className="app">
            <Routes />
            <BeewiseToastContainer />
        </div>
    </Provider>
);

const root = createRoot(document.getElementById('root'));
root.render(<App />);
