import { isEmpty } from 'lodash-es';
import { FETCH_SIGNIN, SIGN_OUT, SOCKET_CONNECTION_FAILED } from 'components/views/SignIn/actionTypes';
import { auth, PERMISSIONS_ACTION_TYPES } from '@beewise/react-utils';

auth.init({
    USER: 'beewise_admin_app_user',
    JWT_TOKEN: 'beewise_admin_app_jwt_token',
    SOCKET_JWT_TOKEN: 'beewise_admin_app_socket_jwt_token',
});

const parsedUser = auth.getUser();

const initialState = {
    user: parsedUser || {},
    isLoggedIn: !isEmpty(parsedUser),
    permission: null,
    isPermissionFetched: false,
};

const app = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SIGNIN.success:
            const { token, socketToken } = action.data;
            auth.setJwtToken(token);
            auth.setSocketJwtToken(socketToken);

            return {
                ...state,
                user: auth.getUser(),
                isLoggedIn: true,
            };
        case PERMISSIONS_ACTION_TYPES.FETCH_PERMISSION.success: {
            return {
                ...state,
                permission: action.data.permission,
                isPermissionFetched: true,
            };
        }
        case SIGN_OUT:
        case SOCKET_CONNECTION_FAILED: {
            auth.clearUser();
            return {
                ...state,
                user: {},
                isLoggedIn: false,
                currentBhome: null,
            };
        }
        default:
            return state;
    }
};

export default app;
