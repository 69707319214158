import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loading, arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { clearViewData } from 'actions';
import { noop } from 'lodash-es';
import Grid from 'components/reusables/Grid';
import { fetchAllIncubators, fetchCreateIncubator, fetchDeleteIncubator, fetchUpdateIncubator } from './actions';
import {
    FETCH_ALL_INCUBATORS,
    FETCH_CREATE_INCUBATOR,
    FETCH_DELETE_INCUBATOR,
    FETCH_UPDATE_INCUBATOR,
} from './actionTypes';

const Incubators = () => {
    const dispatch = useDispatch();
    const incubators = useSelector(state => state.incubators.incubators, arrayOfObjectsShallowEqual);
    const isFetched = useSelector(state => state.incubators.isFetched);
    // load everything before render grid so valueGetters will work properly
    const canRenderGrid = useMemo(() => isFetched, [isFetched]);

    useEffect(() => {
        dispatch(fetchAllIncubators());

        return () => dispatch(clearViewData());
    }, [dispatch]);

    const gridOptions = useMemo(
        () => ({
            columnDefs: [
                {
                    headerName: 'ID',
                    field: 'id',
                    editable: false,
                },
                {
                    headerName: 'Bhome ID',
                    field: 'bhome_id',
                    editable: params => params.data.addMode,
                    cellRenderer: 'link',
                    cellRendererParams: {
                        url: '/bhomes',
                    },
                },
                {
                    headerName: 'Treatment',
                    field: 'treatment',
                    editable: true,
                },
                {
                    headerName: 'Group',
                    field: 'group',
                    editable: true,
                },
                {
                    headerName: 'Created at',
                    field: 'created_at',
                    editable: false,
                    valueGetter: params => new Date(params.data.created_at).toLocaleString(),
                },
            ],
        }),
        []
    );

    const handleNewItemSave = useCallback(
        newItem => {
            const { id, ...rest } = newItem;

            dispatch(
                fetchCreateIncubator({
                    ...rest,
                })
            );
        },
        [dispatch]
    );

    const handleItemDelete = useCallback(data => dispatch(fetchDeleteIncubator(data.id)), [dispatch]);

    const onCellChange = useCallback(
        ({ data }) => {
            // eslint-disable-next-line camelcase
            const { id, is_active, created_at, updated_at, ...incubator } = data;

            dispatch(fetchUpdateIncubator(id, incubator));
        },
        [dispatch]
    );

    return (
        <div id="view" className="page incubators">
            {canRenderGrid && (
                <Grid
                    onGridReady={noop}
                    rowData={incubators}
                    gridOptions={gridOptions}
                    handleNewItemSave={handleNewItemSave}
                    handleItemDelete={handleItemDelete}
                    onCellChange={onCellChange}
                    enableDelete
                />
            )}
        </div>
    );
};

export default loading([
    FETCH_ALL_INCUBATORS.default,
    FETCH_CREATE_INCUBATOR.default,
    FETCH_DELETE_INCUBATOR.default,
    FETCH_UPDATE_INCUBATOR.default,
])(Incubators);
