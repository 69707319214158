import { createAsyncAction } from '@beewise/utils';

export const GET_ALL_COMPANIES = createAsyncAction('GET_ALL_COMPANIES');
export const FETCH_SIGNUP = createAsyncAction('FETCH_SIGNUP');
export const FETCH_REASSIGN_BHOME = createAsyncAction('FETCH_REASSIGN_BHOME');
export const FETCH_BULK_UPDATE_SETTINGS = createAsyncAction('FETCH_BULK_UPDATE_SETTINGS');
export const GET_ALL_AVAILABLE_BHOMES = createAsyncAction('GET_ALL_AVAILABLE_BHOMES');
export const UPDATE_ADMIN_COMPANY = createAsyncAction('UPDATE_ADMIN_COMPANY');
export const UPDATE_ADMIN_COMPANY_BY_BHOME_ID = createAsyncAction('UPDATE_ADMIN_COMPANY_BY_BHOME_ID');
export const FETCH_ALL_BHOMES = createAsyncAction('FETCH_ALL_BHOMES');
export const FETCH_CREATE_NEW_BHOME = createAsyncAction('FETCH_CREATE_NEW_BHOME');
export const GET_USER_ROLES = createAsyncAction('GET_USER_ROLES');
export const FETCH_CLEAR_USERS_STORAGE = createAsyncAction('FETCH_CLEAR_USERS_STORAGE');
export const FETCH_UPDATE_BHOME_SOFTWARE = createAsyncAction('FETCH_UPDATE_BHOME_SOFTWARE');
export const FETCH_DELETE_BHOME_BUNDLE = createAsyncAction('FETCH_DELETE_BHOME_BUNDLE');
export const FETCH_DELETE_COMPANIES_AND_CONTENT = createAsyncAction('FETCH_DELETE_COMPANIES_AND_CONTENT');
export const FETCH_DELETE_BHOMES_AND_CLOUD_DATA = createAsyncAction('FETCH_DELETE_BHOMES_AND_CLOUD_DATA');
export const FETCH_CREATE_SOFTWARE_BUNDLE = createAsyncAction('FETCH_CREATE_SOFTWARE_BUNDLE');
export const FETCH_SOFTWARE_BUNDLES = createAsyncAction('FETCH_SOFTWARE_BUNDLES');
export const GET_COMPANY_TYPES = createAsyncAction('GET_COMPANY_TYPES');
export const FETCH_BHOME_SETTINGS = createAsyncAction('FETCH_BHOME_SETTINGS');
export const FETCH_POPULATED_HIVES_CSV_REPORT = createAsyncAction('FETCH_POPULATED_HIVES_CSV_REPORT');
export const FETCH_BHOME_SETTINGS_HISTORY = createAsyncAction('FETCH_BHOME_SETTINGS_HISTORY');
export const FETCH_SAVE_BHOME_SETTINGS = createAsyncAction('FETCH_SAVE_BHOME_SETTINGS');
export const SET_CURRENT_BHOME = 'SET_CURRENT_BHOME';
export const CLEAR_CREATED_BHOME = 'CLEAR_CREATED_BHOME';
export const FETCH_CREATE_BHOME_LIGHT = createAsyncAction('FETCH_CREATE_BHOME_LIGHT');

export const FETCH_BHOME_CONNECTIVITY_MAP = createAsyncAction('FETCH_BHOME_CONNECTIVITY_MAP');
