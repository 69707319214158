import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Error from './Error';
import {
  SIZES,
  TYPES,
  POSITIONS,
  getDisplayValue,
  getCurrentInputType,
  getIsWithIcon
} from './utils';
import usePasswordVisibility from './usePasswordVisibility';
import useTextField from './useTextField';
import BaseField from './BaseField';
import Label from './Label';
import Icon from './Icon';

import './TextField.scss';

const TextField = React.forwardRef(
  (
    {
      error,
      name,
      label,
      required,
      value,
      onFocus,
      onBlur,
      onChange,
      multiline,
      size = SIZES.NORMAL,
      type = TYPES.TEXT,
      inputProps,
      className,
      placeholder,
      info,
      ...rest
    },
    ref
  ) => {
    const inputRef = useRef(null);

    const { isPasswordShown, togglePasswordVisibility } = usePasswordVisibility();
    const { handleOnFocus, handleOnBlur, handleOnChange, active, initialActiveState } =
      useTextField({
        value,
        inputRef,
        onFocus,
        onBlur,
        onChange,
        type,
        inputProps
      });

    const currentInputType = getCurrentInputType({ type, isPasswordShown });
    const displayValue = getDisplayValue({ type, value });

    const isWithIcon = getIsWithIcon({ type, inputProps });

    const setRef = (r) => {
      if (ref) {
        ref.current = r;
      }
      inputRef.current = r;
    };

    return (
      <fieldset className={cn('text-field-root', className)}>
        <div
          className={cn('text-field-container', {
            'text-field--error': error,
            'text-field-container-reverse':
              inputProps?.position === POSITIONS.START && type !== TYPES.PASSWORD
          })}
        >
          <BaseField
            ref={setRef}
            name={name}
            id={name}
            type={currentInputType}
            value={displayValue}
            placeholder={placeholder}
            multiline={multiline}
            size={size}
            active={active}
            error={error}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            {...rest}
          />
          <Icon
            type={type}
            inputProps={inputProps}
            isPasswordShown={isPasswordShown}
            togglePasswordVisibility={togglePasswordVisibility}
          />
        </div>
        <Label
          label={label}
          name={name}
          // keep label on the top if placeholder is present
          active={active || placeholder || initialActiveState}
          error={error}
          multiline={multiline}
          size={size}
          required={required}
          iconUsed={isWithIcon}
        />
        <Error info={info} error={error} />
      </fieldset>
    );
  }
);

TextField.propTypes = {
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  multiline: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(SIZES)),
  inputProps: PropTypes.shape({
    component: PropTypes.element.isRequired,
    position: PropTypes.oneOf(Object.values(POSITIONS))
  }),
  info: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(Object.values(TYPES)),
  className: PropTypes.string
};

export default TextField;
