import { FETCH_RANCH_LOCATIONS, UPDATE_RANCH_LOCATION } from '../actionTypes';

export const fetchRanchLocations = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_RANCH_LOCATIONS.default,
        url: '/ranch/locations',
    },
});

export const updateRanchLocation = (locationId, { bhome, status }) => ({
    type: 'FETCH',
    params: {
        method: 'PUT',
        type: UPDATE_RANCH_LOCATION.default,
        url: `/ranch/location/${locationId}`,
        body: {
            bhome,
            status,
        },
    },
});
