import React, { forwardRef } from 'react';

const Activity = forwardRef(({ ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="17"
    viewBox="0 0 24 17"
    fill="none"
    {...rest}
  >
    <path
      d="M1.8457 9.55104H4.99123L8.58613 1.8269L12.181 15.0034L17.124 5.00743L19.3708 9.55104H22.9657"
      stroke="#4F4F4F"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

Activity.displayName = 'Activity';

export default Activity;
