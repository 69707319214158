import {
    FETCH_BHOME_MODELS,
    FETCH_CREATE_BHOME_MODEL,
    FETCH_UPDATE_BHOME_MODEL,
    FETCH_DELETE_BHOME_MODEL,
} from '../actionTypes';

export const fetchBhomeModels = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_BHOME_MODELS.default,
        url: '/bhomes/bhome-models',
    },
});

export const fetchCreateBhomeModel = model => ({
    type: 'FETCH',
    params: {
        type: FETCH_CREATE_BHOME_MODEL.default,
        url: '/bhomes/bhome-models',
        method: 'POST',
        body: {
            model,
        },
    },
});

export const fetchUpdateBhomeModel = (id, model) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_BHOME_MODEL.default,
        url: `/bhomes/bhome-models/${id}`,
        method: 'PUT',
        body: {
            model,
        },
    },
});

export const fetchDeleteBhomeModel = id => ({
    type: 'FETCH',
    params: {
        type: FETCH_DELETE_BHOME_MODEL.default,
        url: `/bhomes/bhome-models/${id}`,
        method: 'DELETE',
    },
});
