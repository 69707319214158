import { useMemo, useState } from 'react';

export default ({ value, onChange, onFocus, onBlur, addNewOption, ...rest }) => {
  const [isActive, setIsActive] = useState(!!value);

  const handleOnFocus = (e) => {
    setIsActive(true);
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleOnBlur = (e) => {
    if (!value) {
      setIsActive(false);
    }
    if (onBlur) {
      onBlur(e);
    }
  };

  const handleOnChange = (e) =>
    Array.isArray(e) ? onChange(e.map((item) => item.value)) : onChange(e?.value);

  const valueWithLabel = useMemo(
    () =>
      Array.isArray(value)
        ? rest.options.filter((option) => value.includes(option.value))
        : rest.options.find((option) => option.value === value),
    [value, rest.options]
  );

  return {
    isActive,
    handleOnFocus,
    handleOnBlur,
    handleOnChange,
    valueWithLabel,
    addNewOption
  };
};
