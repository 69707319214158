import { CLEAR_VIEW_DATA } from 'actionTypes';
import { FETCH_USERS, FETCH_CREATE_USER, FETCH_DELETE_USER, FETCH_UPDATE_USER } from '../actionTypes';

const initialState = {
    users: [],
    isFetched: false,
};

const users = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USERS.success:
            return {
                ...state,
                users: action.data.users,
                isFetched: true,
            };
        case FETCH_CREATE_USER.success:
            return {
                ...state,
                users: [action.data.user, ...state.users],
            };
        case FETCH_DELETE_USER.success:
            return {
                ...state,
                users: state.users.filter(item => item.id !== action.data.id),
            };
        case FETCH_UPDATE_USER.success:
            return {
                ...state,
                users: state.users.map(item => {
                    if (item.id === action.data.user.id) {
                        return {
                            ...action.data.user,
                        };
                    }

                    return item;
                }),
            };
        case CLEAR_VIEW_DATA:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default users;
