import { CLEAR_VIEW_DATA } from 'actionTypes';
import { FETCH_COMPANY_USERS, FETCH_CREATE_COMPANY_USER, FETCH_DELETE_COMPANY_USER } from '../actionTypes';

const initialState = {
    users: [],
    isFetched: false,
};

const companyUsers = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_COMPANY_USERS.success:
            return {
                ...state,
                users: action.data.users.map((item, index) => ({
                    ...item,
                    id: index,
                })),
                isFetched: true,
            };
        case FETCH_CREATE_COMPANY_USER.success:
            return {
                ...state,
                users: [action.data.user, ...state.users].map((item, index) => ({
                    ...item,
                    id: index,
                })),
            };
        case FETCH_DELETE_COMPANY_USER.success:
            return {
                ...state,
                users: state.users.filter(
                    item => item.company_id !== action.data.companyId || item.user_id !== action.data.userId
                ),
            };
        case CLEAR_VIEW_DATA:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default companyUsers;
