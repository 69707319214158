import { useState } from 'react';

export default () => {
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordShown((prev) => !prev);
  };

  return {
    isPasswordShown,
    togglePasswordVisibility
  };
};
