import { FETCH_BHOME_ASSEMBLIES, FETCH_CREATE_BHOME_ASSEMBLY, FETCH_DELETE_BHOME_ASSEMBLY } from '../actionTypes';

export const fetchBhomeAssemblies = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_BHOME_ASSEMBLIES.default,
        url: '/bhomes/bhome-assemblies',
    },
});

export const fetchCreateBhomeAssembly = assembly => ({
    type: 'FETCH',
    params: {
        type: FETCH_CREATE_BHOME_ASSEMBLY.default,
        url: '/bhomes/bhome-assemblies',
        method: 'POST',
        body: {
            assembly,
        },
    },
});

export const fetchDeleteBhomeAssembly = id => ({
    type: 'FETCH',
    params: {
        type: FETCH_DELETE_BHOME_ASSEMBLY.default,
        url: `/bhomes/bhome-assemblies/${id}`,
        method: 'DELETE',
    },
});
