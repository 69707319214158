import { CLEAR_VIEW_DATA } from 'actionTypes';
import {
    FETCH_CREATE_USER_PERMISSION,
    FETCH_DELETE_USER_PERMISSION,
    FETCH_UPDATE_USER_PERMISSION,
    FETCH_USER_PERMISSIONS,
} from '../actionTypes';

const initialState = {
    permissions: [],
    isFetched: false,
};

const userPermissions = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_PERMISSIONS.success:
            return {
                ...state,
                permissions: action.data.permissions,
                isFetched: true,
            };
        case FETCH_CREATE_USER_PERMISSION.success:
            return {
                ...state,
                permissions: [action.data.permission, ...state.permissions],
            };
        case FETCH_DELETE_USER_PERMISSION.success:
            return {
                ...state,
                permissions: state.permissions.filter(item => item.id !== action.data.id),
            };
        case FETCH_UPDATE_USER_PERMISSION.success:
            return {
                ...state,
                permissions: state.permissions.map(item => {
                    if (item.id === action.data.permission.id) {
                        return {
                            ...action.data.permission,
                        };
                    }

                    return item;
                }),
            };
        case CLEAR_VIEW_DATA:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default userPermissions;
