import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const EndVisit = forwardRef(({ color = 'currentColor', size = 16, ...rest }, ref) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.09961 4.07999V6.0001V13.5001C2.09961 13.9972 2.50255 14.4001 2.99961 14.4001H12.4197L11.6197 13.6001H2.99961C2.94438 13.6001 2.89961 13.5553 2.89961 13.5001V6.4001H4.41972L3.61972 5.6001H2.89961V4.87999L2.09961 4.07999ZM5.39961 3.42019L4.57952 2.6001H4.59961V2.0001C4.59961 1.77918 4.7787 1.6001 4.99961 1.6001C5.22052 1.6001 5.39961 1.77918 5.39961 2.0001V2.6001H10.5996V2.0001C10.5996 1.77918 10.7787 1.6001 10.9996 1.6001C11.2205 1.6001 11.3996 1.77918 11.3996 2.0001V2.6001H12.9996C13.4967 2.6001 13.8996 3.00304 13.8996 3.5001V6.0001V11.9202L13.0996 11.1202V6.4001H8.37952L7.57952 5.6001H13.0996V3.5001C13.0996 3.44487 13.0548 3.4001 12.9996 3.4001H11.3996V4.0001C11.3996 4.22101 11.2205 4.4001 10.9996 4.4001C10.7787 4.4001 10.5996 4.22101 10.5996 4.0001V3.4001H5.39961V3.42019ZM8.10182 10.0822L7.24961 10.9344L6.03245 9.71725C5.87624 9.56105 5.62298 9.56105 5.46677 9.71725C5.31056 9.87346 5.31056 10.1267 5.46677 10.2829L6.96677 11.7829C7.12298 11.9391 7.37624 11.9391 7.53245 11.7829L8.66751 10.6479L8.10182 10.0822ZM10.6231 8.64365L10.1061 8.12664C10.249 8.07177 10.4172 8.10198 10.5325 8.21726C10.6477 8.33253 10.6779 8.50067 10.6231 8.64365Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.71677 1.71725C1.87298 1.56105 2.12624 1.56105 2.28245 1.71725L14.7825 14.2173C14.9387 14.3735 14.9387 14.6267 14.7825 14.7829C14.6262 14.9392 14.373 14.9392 14.2168 14.7829L1.71677 2.28294C1.56056 2.12673 1.56056 1.87346 1.71677 1.71725Z"
      fill={color}
    />
  </svg>
));

EndVisit.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

EndVisit.displayName = 'EndVisit';

export default EndVisit;
