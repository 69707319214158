import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Hives = forwardRef(({ color = '#616161', width = 18, height = 18 }, ref) => (
  <svg
    ref={ref}
    width={width}
    height={height}
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5833 4.19174V8.85086C10.5833 9.01654 10.449 9.15086 10.2833 9.15086H1.71667C1.55098 9.15086 1.41667 9.01654 1.41667 8.85086V4.19174H0.8C0.634315 4.19174 0.5 4.05742 0.5 3.89174V3.25078C0.5 3.14032 0.589543 3.05078 0.7 3.05078H11.3C11.4105 3.05078 11.5 3.14032 11.5 3.25078V3.89174C11.5 4.05742 11.3657 4.19174 11.2 4.19174H10.5833ZM4.725 4.32373H7.275C7.33023 4.32373 7.37426 4.36795 7.36846 4.42287C7.33613 4.72922 7.14381 5.64022 6 5.64022C4.85619 5.64022 4.66387 4.72922 4.63154 4.42287C4.62574 4.36795 4.66977 4.32373 4.725 4.32373Z"
      fill={color}
    />
    <path
      d="M1.6545 1.00156C1.71145 0.937227 1.79324 0.900391 1.87916 0.900391H2.61448C2.69233 0.900391 2.74033 0.985428 2.70009 1.05208L1.97182 2.25834C1.95299 2.28953 1.94303 2.32528 1.94303 2.36171V2.65571H0.7C0.589543 2.65571 0.5 2.56617 0.5 2.45571L0.5 2.38181C0.5 2.33298 0.517867 2.28583 0.550233 2.24926L1.6545 1.00156Z"
      fill={color}
    />
    <path
      d="M10.3455 1.00156C10.2886 0.937227 10.2068 0.900391 10.1208 0.900391H9.38552C9.30767 0.900391 9.25967 0.985428 9.29991 1.05208L10.0282 2.25834C10.047 2.28953 10.057 2.32528 10.057 2.36171V2.65571H11.3C11.4105 2.65571 11.5 2.56617 11.5 2.45571L11.5 2.38181C11.5 2.33298 11.4821 2.28583 11.4498 2.24926L10.3455 1.00156Z"
      fill={color}
    />
    <path
      d="M2.35994 2.2593C2.34293 2.28935 2.33398 2.32329 2.33398 2.35783V2.65587H9.66732V2.3588C9.66732 2.32365 9.65806 2.28912 9.64046 2.2587L9.05844 1.25195H2.93018L2.35994 2.2593Z"
      fill={color}
    />
  </svg>
));

Hives.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

export default Hives;
