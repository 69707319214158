import React, { useCallback } from 'react';
import Button from '@beewise/button';
import { useDispatch } from 'react-redux';
import { fetchClearUsersStorage } from '../actions';

const ClearUsersStorage = () => {
    const dispatch = useDispatch();

    const handleClearClick = useCallback(() => dispatch(fetchClearUsersStorage()), [dispatch]);

    return (
        <div>
            <Button className="btn-primary" onClick={handleClearClick}>
                Clear Storage
            </Button>
        </div>
    );
};

export default ClearUsersStorage;
