import { FETCH_DELETE_USER_BHOME, FETCH_CREATE_USER_BHOME, FETCH_USER_BHOMES } from '../actionTypes';

export const fetchUserBhomes = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_USER_BHOMES.default,
        url: '/bhomes/user-bhomes',
    },
});

export const fetchCreateUserBhome = (userId, bhomeId) => ({
    type: 'FETCH',
    params: {
        type: FETCH_CREATE_USER_BHOME.default,
        url: '/bhomes/user-bhomes',
        method: 'POST',
        body: {
            userId,
            bhomeId,
        },
    },
});

export const fetchDeleteUserBhome = (userId, bhomeId) => ({
    type: 'FETCH',
    params: {
        type: FETCH_DELETE_USER_BHOME.default,
        url: '/bhomes/user-bhomes',
        method: 'DELETE',
        body: {
            userId,
            bhomeId,
        },
    },
});
