import {
    FETCH_USER_TYPES,
    FETCH_CREATE_USER_TYPE,
    FETCH_DELETE_USER_TYPE,
    FETCH_UPDATE_USER_TYPE,
} from '../actionTypes';

export const fetchUserTypes = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_USER_TYPES.default,
        url: '/users/types',
    },
});

export const fetchCreateUserType = type => ({
    type: 'FETCH',
    params: {
        type: FETCH_CREATE_USER_TYPE.default,
        url: '/users/types',
        method: 'POST',
        body: {
            type,
        },
    },
});

export const fetchDeleteUserType = id => ({
    type: 'FETCH',
    params: {
        type: FETCH_DELETE_USER_TYPE.default,
        url: `/users/types/${id}`,
        method: 'DELETE',
    },
});

export const fetchUpdateUserType = (id, type) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_USER_TYPE.default,
        url: `/users/types/${id}`,
        method: 'PUT',
        body: {
            type,
        },
    },
});
