import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const BorderedBox = forwardRef(({ color = '#111111', size = 18, ...rest }, ref) => (
  <svg
    ref={ref}
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke={color}
    {...rest}
  >
    <path
      d="M13.75 1.125H15.875C16.4273 1.125 16.875 1.57272 16.875 2.125V4.25"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.25 16.875H2.125C1.57272 16.875 1.125 16.4273 1.125 15.875V13.75"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.875 13.75V15.875C16.875 16.4273 16.4273 16.875 15.875 16.875H13.75"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.125 4.25V2.125C1.125 1.57272 1.57272 1.125 2.125 1.125H4.25"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.83008 4.15625L13.2051 6.65625V11.6562L8.83008 14.1562L4.45508 11.6562V6.65625L8.83008 4.15625Z"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.45508 6.65625L8.83008 9.15625L13.2051 6.65625"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.83008 9.15625V14.1562"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

BorderedBox.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

BorderedBox.displayName = 'BorderedBox';

export default BorderedBox;
