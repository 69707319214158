import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loading, arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { clearViewData } from 'actions';
import Grid from 'components/reusables/Grid';
import {
    fetchLocationTypes,
    fetchCreateLocationType,
    fetchUpdateLocationType,
    fetchDeleteLocationType,
} from './actions';
import {
    FETCH_LOCATION_TYPES,
    FETCH_CREATE_LOCATION_TYPE,
    FETCH_UPDATE_LOCATION_TYPE,
    FETCH_DELETE_LOCATION_TYPE,
} from './actionTypes';

const LocationTypes = () => {
    const [, setGridApi] = useState(null);

    const dispatch = useDispatch();

    const types = useSelector(state => state.locationTypes.types, arrayOfObjectsShallowEqual);

    useEffect(() => {
        dispatch(fetchLocationTypes());

        return () => dispatch(clearViewData());
    }, [dispatch]);

    const handleOnGridReady = useCallback(gridApi => {
        setGridApi(gridApi);
    }, []);

    const handleNewItemSave = useCallback(
        newItem => {
            dispatch(fetchCreateLocationType(newItem));
        },
        [dispatch]
    );

    const handleItemDelete = useCallback(data => dispatch(fetchDeleteLocationType(data.id)), [dispatch]);

    const onCellChange = useCallback(
        ({ data }) => {
            const { id, ...type } = data;

            dispatch(fetchUpdateLocationType(id, type));
        },
        [dispatch]
    );

    const gridOptions = useMemo(
        () => ({
            columnDefs: [
                { headerName: 'ID', field: 'id', editable: false },
                { headerName: 'Name', field: 'name', editable: true },
            ],
            columnTypes: {
                nonEditableColumn: { editable: false },
            },
        }),
        []
    );

    return (
        <div id="view" className="page location-types">
            <Grid
                onGridReady={handleOnGridReady}
                rowData={types}
                gridOptions={gridOptions}
                handleNewItemSave={handleNewItemSave}
                handleItemDelete={handleItemDelete}
                onCellChange={onCellChange}
            />
        </div>
    );
};

export default loading([
    FETCH_LOCATION_TYPES.default,
    FETCH_CREATE_LOCATION_TYPE.default,
    FETCH_UPDATE_LOCATION_TYPE.default,
    FETCH_DELETE_LOCATION_TYPE.default,
])(LocationTypes);
