import { FETCH_ASSEMBLIES, FETCH_CREATE_ASSEMBLY, FETCH_UPDATE_ASSEMBLY, FETCH_DELETE_ASSEMBLY } from '../actionTypes';

export const fetchAssemblies = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_ASSEMBLIES.default,
        url: '/bhomes/assemblies',
    },
});

export const fetchCreateAssembly = assembly => ({
    type: 'FETCH',
    params: {
        type: FETCH_CREATE_ASSEMBLY.default,
        url: '/bhomes/assemblies',
        method: 'POST',
        body: {
            assembly,
        },
    },
});

export const fetchDeleteAssembly = id => ({
    type: 'FETCH',
    params: {
        type: FETCH_DELETE_ASSEMBLY.default,
        url: `/bhomes/assemblies/${id}`,
        method: 'DELETE',
    },
});

export const fetchUpdateAssembly = (id, assembly) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_ASSEMBLY.default,
        url: `/bhomes/assemblies/${id}`,
        method: 'PUT',
        body: {
            assembly,
        },
    },
});
