import React from 'react';
import './StatusRenderer.scss';
import PropTypes from 'prop-types';

const getData = value => (value ? { text: 'Yes', className: 'good' } : { text: 'No', className: 'bad' });

const StatusRenderer = ({ value }) => {
    const { text, className } = getData(value);
    return (
        <div className="status-renderer">
            <div className={className} />
            {text}
        </div>
    );
};

StatusRenderer.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default StatusRenderer;
