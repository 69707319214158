import cn from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { SIZE_VALUES } from './utils';

const Label = ({ isActive, error, label, name, value, required, size }) => {
  const transformLabel = {
    '--height-transform': SIZE_VALUES[size]
  };

  return label ? (
    <label
      htmlFor={name}
      className={cn('select-field-label', {
        'select-field--active': isActive || value || value === 0,
        'select-field--error': error
      })}
      style={transformLabel}
    >
      <span className="select-field-label--item">
        {label}
        {required && ' *'}
      </span>
    </label>
  ) : null;
};

Label.propTypes = {
  isActive: PropTypes.bool,
  error: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.string,
  value: PropTypes.string
};

export default Label;
