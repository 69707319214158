export const gridOptions = {
    columnDefs: [
        {
            headerName: 'Id',
            field: 'id',
            editable: false,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
        },
        { headerName: 'Ranch', field: 'ranch', editable: false },
        { headerName: 'Yard', field: 'yardName', editable: false },
    ],
    columnTypes: {
        nonEditableColumn: { editable: false },
    },
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
};
