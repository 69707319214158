import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loading, arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import Grid from 'components/reusables/Grid';
import { clearViewData } from 'actions';
import { fetchCompanies, fetchCreateCompany, fetchDeleteCompany, fetchUpdateCompany } from './actions';
import { FETCH_COMPANIES, FETCH_UPDATE_COMPANY, FETCH_DELETE_COMPANY, FETCH_CREATE_COMPANY } from './actionTypes';

const Companies = () => {
    const [, setGridApi] = useState(null);

    const dispatch = useDispatch();

    const companies = useSelector(state => state.companies.companies, arrayOfObjectsShallowEqual);
    const isFetched = useSelector(state => state.companies.isFetched);

    useEffect(() => {
        dispatch(fetchCompanies());

        return () => dispatch(clearViewData());
    }, [dispatch]);

    const handleOnGridReady = useCallback(gridApi => {
        setGridApi(gridApi);
    }, []);

    const handleNewItemSave = useCallback(
        newItem => {
            const { id, ...rest } = newItem;

            dispatch(
                fetchCreateCompany({
                    ...rest,
                })
            );
        },
        [dispatch]
    );

    const handleItemDelete = useCallback(data => dispatch(fetchDeleteCompany(data.id)), [dispatch]);

    const onCellChange = useCallback(
        ({ data }) => {
            // eslint-disable-next-line camelcase
            const { id, created_at, ...company } = data;

            dispatch(
                fetchUpdateCompany(id, {
                    ...company,
                })
            );
        },
        [dispatch]
    );

    const gridOptions = useMemo(
        () => ({
            columnDefs: [
                { headerName: 'ID', field: 'id', editable: false },
                { headerName: 'Name', field: 'name', editable: true },
                { headerName: 'Country', field: 'country', editable: true },
                { headerName: 'City', field: 'city', editable: true },
                { headerName: 'State', field: 'state', editable: true },
                {
                    headerName: 'Postal Code',
                    field: 'postal_code',
                    editable: true,
                },
                {
                    headerName: 'Adress Line 1',
                    field: 'adress_line_1',
                    editable: true,
                },
                {
                    headerName: 'Adress Line 2',
                    field: 'adress_line_2',
                    editable: true,
                },
                {
                    headerName: 'Created At',
                    field: 'created_at',
                    editable: false,
                },
            ],
            columnTypes: {
                nonEditableColumn: { editable: false },
            },
        }),
        []
    );

    // load everything before render grid so valueGetters will work properly
    const canRenderGrid = useMemo(() => isFetched, [isFetched]);

    return (
        <div id="view" className="page companies">
            {canRenderGrid && (
                <Grid
                    onGridReady={handleOnGridReady}
                    rowData={companies}
                    gridOptions={gridOptions}
                    handleNewItemSave={handleNewItemSave}
                    handleItemDelete={handleItemDelete}
                    onCellChange={onCellChange}
                />
            )}
        </div>
    );
};

export default loading([
    FETCH_COMPANIES.default,
    FETCH_UPDATE_COMPANY.default,
    FETCH_DELETE_COMPANY.default,
    FETCH_CREATE_COMPANY.default,
])(Companies);
