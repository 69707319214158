/* eslint-disable import/no-unresolved */
import ReactGA from 'react-ga';
import { UI_ENV } from 'config';

const analyticsConstants = {
    EVENT_CATEGORIES: {
        STOP: 'stop',
        GENERAL: 'general',
        FRAME_ACTION: 'frameAction',
        GENERAL_ROBOT: 'generalRobot',
    },
};

/**
 * @param {Object} event
 * @property {string} event.category
 * @property {string} event.action
 * @property {string} [event.label]
 */
const sendGAEvent = event => {
    if (UI_ENV === 'prod') {
        ReactGA.event(event);
    }
};

export default {
    fireStopEvent: event =>
        sendGAEvent({
            category: analyticsConstants.EVENT_CATEGORIES.STOP,
            ...event,
        }),
    fireGeneralEvent: event =>
        sendGAEvent({
            category: analyticsConstants.EVENT_CATEGORIES.GENERAL,
            ...event,
        }),
    fireFrameActionEvent: event =>
        sendGAEvent({
            category: analyticsConstants.EVENT_CATEGORIES.FRAME_ACTION,
            ...event,
        }),
    fireGeneralRobotEvent: event =>
        sendGAEvent({
            category: analyticsConstants.EVENT_CATEGORIES.GENERAL_ROBOT,
            ...event,
        }),
};
