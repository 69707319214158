import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Beehome = forwardRef(({ color = '#111111', ...props }, ref) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M5.29999 14.5232H14.7"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.50488 8.53036V15.6302L3.5 15.63V10.5"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.60636 5.30042L16.639 5.29999L18.4679 8.51661L1.5 8.51701L3.60636 5.30042Z"
      stroke="#111111"
      strokeWidth="0.8"
      strokeLinejoin="round"
    />
    <path d="M5.24225 6.90887H14.6856" stroke={color} strokeWidth="0.8" strokeLinecap="round" />
    <path
      d="M6.15863 12.7817H13.7693"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeDasharray="1.6 1.6"
    />
    <path
      d="M18.4679 8.53036V15.6302L16.5 15.63V10.5"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

Beehome.propTypes = {
  color: PropTypes.string
};

Beehome.displayName = 'Beehome';

export default Beehome;
