import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash-es';
import { loading, arrayOfObjectsShallowEqual, usePermission } from '@beewise/react-utils';
import Grid from 'components/reusables/Grid';
import { clearViewData } from 'actions';
import { fetchUsers } from 'components/views/Users/actions';
import { fetchCreatePermission, fetchDeletePermission, fetchPermissions, fetchUpdatePermission } from './actions';
import {
    FETCH_USER_PERMISSIONS,
    FETCH_CREATE_USER_PERMISSION,
    FETCH_DELETE_USER_PERMISSION,
    FETCH_UPDATE_USER_PERMISSION,
} from './actionTypes';

const UserPermissions = () => {
    const [, setGridApi] = useState(null);
    const isChangeRoleAllowed = usePermission('changeRoles', 'admin');

    const dispatch = useDispatch();

    const permissions = useSelector(state => state.userPermissions.permissions, arrayOfObjectsShallowEqual);
    const users = useSelector(state => state.users.users, arrayOfObjectsShallowEqual);
    const isFetched = useSelector(state => state.userPermissions.isFetched);
    const areUsersFetched = useSelector(state => state.users.isFetched);

    useEffect(() => {
        dispatch(fetchPermissions());
        dispatch(fetchUsers());

        return () => dispatch(clearViewData());
    }, [dispatch]);

    const handleOnGridReady = useCallback(gridApi => {
        setGridApi(gridApi);
    }, []);

    const handleNewItemSave = useCallback(
        newItem => {
            // eslint-disable-next-line camelcase
            const { id, user_id, ...rest } = newItem;
            const userId = get(
                // eslint-disable-next-line camelcase
                users.find(item => item.id === user_id),
                'id',
                null
            );

            dispatch(
                fetchCreatePermission({
                    ...rest,
                    user_id: userId,
                })
            );
        },
        [dispatch, users]
    );

    const handleItemDelete = useCallback(data => dispatch(fetchDeletePermission(data.id)), [dispatch]);

    const onCellChange = useCallback(
        ({ data }) => {
            const { id, ...permission } = data;

            dispatch(
                fetchUpdatePermission(id, {
                    ...permission,
                    data: JSON.parse(permission.data),
                })
            );
        },
        [dispatch]
    );

    const gridOptions = useMemo(
        () => ({
            columnDefs: [
                { headerName: 'ID', field: 'id', editable: false },
                {
                    headerName: 'User ID',
                    field: 'user_id',
                    cellRenderer: 'link',
                    cellRendererParams: {
                        url: '/users',
                    },
                    cellEditor: 'agSelectCellEditor',
                    editable: false,
                    valueGetter: params =>
                        params.data.addMode
                            ? params.data.user_role_id
                            : get(
                                  users.find(item => item.id === params.data.user_id),
                                  'email'
                              ),
                },
                {
                    headerName: 'Data',
                    field: 'data',
                    editable: isChangeRoleAllowed,
                    valueGetter: params => JSON.stringify(params.data.data),
                },
            ],
        }),
        [isChangeRoleAllowed, users]
    );

    // load everything before render grid so valueGetters will work properly
    const canRenderGrid = useMemo(() => !!(isFetched && areUsersFetched), [isFetched, areUsersFetched]);

    return (
        <div id="view" className="page user-permissions">
            {canRenderGrid && (
                <Grid
                    onGridReady={handleOnGridReady}
                    rowData={permissions}
                    gridOptions={gridOptions}
                    handleNewItemSave={handleNewItemSave}
                    handleItemDelete={handleItemDelete}
                    onCellChange={onCellChange}
                />
            )}
        </div>
    );
};

export default loading([
    FETCH_USER_PERMISSIONS.default,
    FETCH_CREATE_USER_PERMISSION.default,
    FETCH_DELETE_USER_PERMISSION.default,
    FETCH_UPDATE_USER_PERMISSION.default,
])(UserPermissions);
