import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loading, arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { clearViewData } from 'actions';
import Grid from 'components/reusables/Grid';
import { fetchBhomeModels, fetchCreateBhomeModel, fetchUpdateBhomeModel, fetchDeleteBhomeModel } from './actions';
import {
    FETCH_BHOME_MODELS,
    FETCH_CREATE_BHOME_MODEL,
    FETCH_UPDATE_BHOME_MODEL,
    FETCH_DELETE_BHOME_MODEL,
} from './actionTypes';

const BhomeModels = () => {
    const [, setGridApi] = useState(null);
    const dispatch = useDispatch();

    const models = useSelector(state => state.bhomeModels.models, arrayOfObjectsShallowEqual);

    useEffect(() => {
        dispatch(fetchBhomeModels());

        return () => dispatch(clearViewData());
    }, [dispatch]);

    const handleOnGridReady = useCallback(gridApi => {
        setGridApi(gridApi);
    }, []);

    const handleNewItemSave = useCallback(
        newItem => {
            dispatch(fetchCreateBhomeModel(newItem));
        },
        [dispatch]
    );

    const handleItemDelete = useCallback(data => dispatch(fetchDeleteBhomeModel(data.id)), [dispatch]);

    const onCellChange = useCallback(
        ({ data }) => {
            const { id, ...model } = data;

            dispatch(fetchUpdateBhomeModel(id, model));
        },
        [dispatch]
    );

    const gridOptions = useMemo(
        () => ({
            columnDefs: [
                { headerName: 'ID', field: 'id', editable: false },
                { headerName: 'Name', field: 'name', editable: true },
            ],
            columnTypes: {
                nonEditableColumn: { editable: false },
            },
        }),
        []
    );

    return (
        <div id="view" className="page bhome-models">
            <Grid
                onGridReady={handleOnGridReady}
                rowData={models}
                gridOptions={gridOptions}
                handleNewItemSave={handleNewItemSave}
                handleItemDelete={handleItemDelete}
                onCellChange={onCellChange}
            />
        </div>
    );
};

export default loading([
    FETCH_BHOME_MODELS.default,
    FETCH_CREATE_BHOME_MODEL.default,
    FETCH_UPDATE_BHOME_MODEL.default,
    FETCH_DELETE_BHOME_MODEL.default,
])(BhomeModels);
