import { shallowEqual } from 'react-redux';

export const arrayOfObjectsShallowEqual = (arrA, arrB) => {
  if (arrA === arrB) {
    return true;
  }

  if (arrA.length !== arrB.length) {
    return false;
  }

  for (let i = 0; i < arrA.length; i += 1) {
    if (!shallowEqual(arrA[i], arrB[i])) {
      return false;
    }
  }

  return true;
};
