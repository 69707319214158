import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './CheckboxField.scss';

const CheckboxField = forwardRef(
  (
    { name, label, checked, labelClass, required, className, onChange, disabled, value, ...rest },
    ref
  ) => (
    <fieldset className={cn(className, 'checkbox-root')}>
      <span className="checkbox-container">
        <input
          ref={ref}
          disabled={disabled}
          type="checkbox"
          name={name}
          checked={checked || value}
          className="checkbox-input"
          onChange={onChange}
          {...rest}
        />
      </span>
      <label htmlFor={name} className={cn('checkbox-label', labelClass)}>
        {label}
        {required && ' *'}
      </label>
    </fieldset>
  )
);

CheckboxField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  labelClass: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.bool
};

export default CheckboxField;
