import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loading, arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import Grid from 'components/reusables/Grid';
import { clearViewData } from 'actions';
import { fetchSoftwareBundles } from 'components/views/Dashboard/actions';
import { FETCH_SOFTWARE_BUNDLES } from 'components/views/Dashboard/actionTypes';
import { fetchUsers } from 'components/views/Users/actions';
import { FETCH_USERS } from 'components/views/Users/actionTypes';
import { get } from 'lodash-es';

const SoftwareBundles = () => {
    const [, setGridApi] = useState(null);

    const dispatch = useDispatch();

    const bundles = useSelector(state => state.dashboard.softwareBundles, arrayOfObjectsShallowEqual);
    const users = useSelector(state => state.users.users, arrayOfObjectsShallowEqual);

    useEffect(() => {
        dispatch(fetchSoftwareBundles());
        dispatch(fetchUsers());

        return () => dispatch(clearViewData());
    }, [dispatch]);

    const handleOnGridReady = useCallback(gridApi => {
        setGridApi(gridApi);
    }, []);

    const gridOptions = useMemo(
        () => ({
            columnDefs: [
                {
                    headerName: 'ID',
                    field: 'id',
                    editable: false,
                    maxWidth: 200,
                },
                {
                    headerName: 'Bhome Version',
                    field: 'bhomeVersion',
                    editable: false,
                    maxWidth: 200,
                },
                {
                    headerName: 'Bundle',
                    field: 'bundle',
                    editable: false,
                    valueGetter: params => JSON.stringify(params.data.bundle),
                },
                {
                    headerName: 'Created By',
                    field: 'createdBy',
                    valueGetter: params =>
                        get(
                            users.find(user => user.id === params.data.createdBy),
                            'email'
                        ),
                    editable: false,
                    maxWidth: 250,
                },
                {
                    headerName: 'Created At',
                    field: 'createdAt',
                    editable: false,
                    maxWidth: 250,
                    valueGetter: params => new Date(params.data.createdAt).toLocaleString(),
                },
                {
                    headerName: 'Note',
                    field: 'note',
                    editable: false,
                    maxWidth: 200,
                },
            ],
        }),
        [users]
    );

    // load everything before render grid so valueGetters will work properly
    const canRenderGrid = useMemo(() => !!bundles?.length && !!users?.length, [bundles?.length, users?.length]);

    return (
        <div id="view" className="page settings">
            {canRenderGrid && (
                <Grid onGridReady={handleOnGridReady} rowData={bundles} gridOptions={gridOptions} noLeftColumn />
            )}
        </div>
    );
};

export default loading([FETCH_SOFTWARE_BUNDLES.default, FETCH_USERS.default])(SoftwareBundles);
