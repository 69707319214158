/* eslint-disable camelcase */
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash-es';
import { loading, arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import Grid from 'components/reusables/Grid';
import { clearViewData } from 'actions';
import { fetchLocations, fetchCreateLocation, fetchUpdateLocation, fetchDeleteLocation } from './actions';
import { FETCH_LOCATIONS, FETCH_CREATE_LOCATION, FETCH_UPDATE_LOCATION, FETCH_DELETE_LOCATION } from './actionTypes';
import { fetchLocationTypes } from '../LocationTypes/actions';
import { fetchUsers } from '../Users/actions';
import { fetchCompanies } from '../Companies/actions';

const Locations = () => {
    const [, setGridApi] = useState(null);

    const dispatch = useDispatch();

    const locations = useSelector(state => state.locations.locations, arrayOfObjectsShallowEqual);
    const isFetched = useSelector(state => state.locations.isFetched);
    const locationTypes = useSelector(state => state.locationTypes.types, arrayOfObjectsShallowEqual);
    const areLocationTypesFetched = useSelector(state => state.locationTypes.isFetched);
    const users = useSelector(state => state.users.users, arrayOfObjectsShallowEqual);
    const areUsersFetched = useSelector(state => state.users.isFetched);
    const companies = useSelector(state => state.companies.companies, arrayOfObjectsShallowEqual);
    const areCompaniesFetched = useSelector(state => state.companies.isFetched);

    useEffect(() => {
        dispatch(fetchLocations());
        dispatch(fetchLocationTypes());
        dispatch(fetchUsers());
        dispatch(fetchCompanies());

        return () => dispatch(clearViewData());
    }, [dispatch]);

    const handleOnGridReady = useCallback(gridApi => {
        setGridApi(gridApi);
    }, []);

    const handleNewItemSave = useCallback(
        newItem => {
            const { id, location_type, created_by, company_id, ...rest } = newItem;
            const locationType = get(
                locationTypes.find(lt => lt.name === location_type),
                'id',
                null
            );
            const createdBy = get(
                users.find(user => user.username === created_by),
                'id',
                null
            );
            const companyId = get(
                companies.find(company => company.name === company_id),
                'id',
                null
            );

            dispatch(
                fetchCreateLocation({
                    ...rest,
                    location_type: locationType,
                    created_by: createdBy,
                    company_id: companyId,
                })
            );
        },
        [dispatch, locationTypes, users, companies]
    );

    const handleItemDelete = useCallback(data => dispatch(fetchDeleteLocation(data.id)), [dispatch]);

    const onCellChange = useCallback(
        ({ data }) => {
            const { id, location_type, created_by, company_id, ...location } = data;

            dispatch(
                fetchUpdateLocation(id, {
                    ...location,
                    location_type: get(
                        locationTypes.find(lt => lt.id === location_type || lt.name === location_type),
                        'id',
                        null
                    ),
                    created_by: get(
                        users.find(user => user.id === created_by || user.username === created_by),
                        'id',
                        null
                    ),
                    company_id: get(
                        companies.find(company => company.id === company_id || company.name === company_id),
                        'id',
                        null
                    ),
                })
            );
        },
        [dispatch, locationTypes, users, companies]
    );

    const gridOptions = useMemo(
        () => ({
            columnDefs: [
                { headerName: 'ID', field: 'id', editable: false },
                { headerName: 'Name', field: 'name', editable: true },
                { headerName: 'Latitude', field: 'lat', editable: true },
                { headerName: 'Longitude', field: 'lng', editable: true },
                {
                    headerName: 'Created at',
                    field: 'created_at',
                    editable: false,
                },
                {
                    headerName: 'Company ID',
                    field: 'company_id',
                    cellRenderer: 'link',
                    cellRendererParams: {
                        url: '/companies',
                    },
                    cellEditor: 'agSelectCellEditor',
                    editable: params => params.data.addMode && companies.length,
                    cellEditorParams: {
                        values: [null, ...companies.map(company => company.name)],
                    },
                    valueGetter: params =>
                        params.data.addMode
                            ? params.data.company_id
                            : get(
                                  companies.find(company => company.id === params.data.company_id),
                                  'name'
                              ),
                },
                {
                    headerName: 'Created by',
                    field: 'created_by',
                    cellRenderer: 'link',
                    cellRendererParams: {
                        url: '/users',
                    },
                    cellEditor: 'agSelectCellEditor',
                    editable: false,
                    valueGetter: params =>
                        params.data.addMode
                            ? params.data.created_by
                            : get(
                                  users.find(user => user.id === params.data.created_by),
                                  'username'
                              ),
                },
                {
                    headerName: 'Location type',
                    field: 'location_type',
                    cellRenderer: 'link',
                    cellRendererParams: {
                        url: '/location-types',
                    },
                    cellEditor: 'agSelectCellEditor',
                    editable: !!locationTypes.length,
                    cellEditorParams: {
                        values: [null, ...locationTypes.map(lt => lt.name)],
                    },
                    valueGetter: params =>
                        params.data.addMode
                            ? params.data.location_type
                            : get(
                                  locationTypes.find(lt => lt.id === params.data.location_type),
                                  'name'
                              ),
                },
            ],
        }),
        [users, locationTypes, companies]
    );

    const canRenderGrid = useMemo(
        () => !!(isFetched && areLocationTypesFetched && areUsersFetched && areCompaniesFetched),
        [isFetched, areLocationTypesFetched, areUsersFetched, areCompaniesFetched]
    );

    return (
        <div id="view" className="page locations">
            {canRenderGrid && (
                <Grid
                    onGridReady={handleOnGridReady}
                    rowData={locations}
                    gridOptions={gridOptions}
                    handleNewItemSave={handleNewItemSave}
                    handleItemDelete={handleItemDelete}
                    onCellChange={onCellChange}
                />
            )}
        </div>
    );
};

export default loading([
    FETCH_LOCATIONS.default,
    FETCH_CREATE_LOCATION.default,
    FETCH_UPDATE_LOCATION.default,
    FETCH_DELETE_LOCATION.default,
])(Locations);
