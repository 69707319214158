import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@beewise/modal';
import Button from '@beewise/button';
import Grid from 'components/reusables/Grid';
import './GridModal.scss';

const BhomeSelectGridModal = ({
    isOpen,
    setIsOpen,
    gridName,
    options,
    gridOptions,
    setValue,
    selectProps: { value = [] },
    size = 'large',
    selectedBhomeIds = [],
}) => {
    const [gridApi, setGridApi] = useState();

    const handleOnGridReady = useCallback(gridApi => {
        setGridApi(gridApi);
    }, []);

    const dataOptions = options.map(({ data }) => data);
    const onClose = () => setIsOpen(false);

    const handleDone = () => {
        const selected = gridApi?.getSelectedRows();
        const ids = selected.map(({ id }) => ({ value: id }));
        onClose();
        setValue(ids);
    };

    useEffect(() => {
        if (!gridApi) return;

        gridApi?.forEachNode(node => {
            const data = [...value, ...selectedBhomeIds];
            const valueIds = (data || []).map(({ value }) => value);
            if (valueIds.includes(node.data.id)) {
                node.setSelected(true);
            }
        });
    }, [gridApi, value, selectedBhomeIds]);

    return (
        <Modal header={gridName} isOpen={isOpen} onClose={onClose} size={size} style={{ height: '100%' }}>
            <div id="view" className="grid-select">
                <Grid
                    onGridReady={handleOnGridReady}
                    rowData={dataOptions}
                    gridOptions={gridOptions}
                    isSearch={false}
                    noLeftColumn
                />
            </div>
            <Button onClick={handleDone} className="primary-blue">
                Done
            </Button>
        </Modal>
    );
};

BhomeSelectGridModal.propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    gridName: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape()),
    gridOptions: PropTypes.shape(),
    setValue: PropTypes.func,
    selectProps: PropTypes.shape(),
    size: PropTypes.string,
    selectedBhomeIds: PropTypes.arrayOf(PropTypes.string),
};

export default BhomeSelectGridModal;
