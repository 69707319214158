import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import PropTypes from 'prop-types';
import { TYPES } from './utils';

const Icon = ({ type, inputProps, isPasswordShown, togglePasswordVisibility }) => {
  const iconUsed =
    type === TYPES.PASSWORD ? (
      <FontAwesomeIcon
        icon={isPasswordShown ? faEyeSlash : faEye}
        onClick={togglePasswordVisibility}
        className="password-icon"
      />
    ) : (
      inputProps?.component
    );

  return iconUsed && <div className="text-field-icon">{iconUsed}</div>;
};

Icon.propTypes = {
  type: PropTypes.string,
  inputProps: PropTypes.shape(),
  isPasswordShown: PropTypes.bool,
  togglePasswordVisibility: PropTypes.func
};

export default Icon;
