import { CLEAR_VIEW_DATA } from 'actionTypes';
import {
    FETCH_BHOME_MODELS,
    FETCH_CREATE_BHOME_MODEL,
    FETCH_UPDATE_BHOME_MODEL,
    FETCH_DELETE_BHOME_MODEL,
} from '../actionTypes';

const initialState = {
    models: [],
    isFetched: false,
};

const bhomeModels = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BHOME_MODELS.success:
            return {
                ...state,
                models: action.data.models,
                isFetched: true,
            };
        case FETCH_CREATE_BHOME_MODEL.success:
            return {
                ...state,
                models: [action.data.model, ...state.models],
            };
        case FETCH_UPDATE_BHOME_MODEL.success:
            return {
                ...state,
                models: state.models.map(item => {
                    if (item.id === action.data.model.id) {
                        return {
                            ...action.data.model,
                        };
                    }

                    return item;
                }),
            };
        case FETCH_DELETE_BHOME_MODEL.success:
            return {
                ...state,
                models: state.models.filter(item => item.id !== action.data.id),
            };
        case CLEAR_VIEW_DATA:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default bhomeModels;
