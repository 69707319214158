/* eslint-disable camelcase */
import React, { useCallback, useEffect } from 'react';
import { noop } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { loading, arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { clearViewData } from 'actions';
import Grid from 'components/reusables/Grid';
import { FETCH_DELETE_PUBLIC_CODE, FETCH_ALL_PUBLIC_CODES, FETCH_CREATE_PUBLIC_CODE } from './actionTypes';
import { fetchCreatePublicCode, fetchAllPublicCodes, fetchDeletePublicCode } from './actions';
import { gridOptions } from './utils';

const PublicBhomeView = () => {
    const dispatch = useDispatch();
    const isFetched = useSelector(state => state.publicCodes.isFetched);
    const publicCodes = useSelector(state => state.publicCodes.codes, arrayOfObjectsShallowEqual);

    useEffect(() => {
        dispatch(fetchAllPublicCodes());

        return () => dispatch(clearViewData());
    }, [dispatch]);

    const handleNewItemSave = useCallback(
        newItem => {
            const { bhome_id } = newItem;

            dispatch(
                fetchCreatePublicCode({
                    bhomeId: bhome_id,
                })
            );
        },
        [dispatch]
    );

    const handleItemDelete = useCallback(data => dispatch(fetchDeletePublicCode(data.id)), [dispatch]);

    return (
        <div id="view" className="page public-bhome-codes">
            {isFetched && (
                <Grid
                    onGridReady={noop}
                    rowData={publicCodes}
                    gridOptions={gridOptions}
                    handleNewItemSave={handleNewItemSave}
                    handleItemDelete={handleItemDelete}
                    enableDelete
                />
            )}
        </div>
    );
};

export default loading([
    FETCH_DELETE_PUBLIC_CODE.default,
    FETCH_ALL_PUBLIC_CODES.default,
    FETCH_CREATE_PUBLIC_CODE.default,
])(PublicBhomeView);
