import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Contact = forwardRef(({ color = '#616161', width = 16, height = 15, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    {...rest}
  >
    <path
      d="M8 0C3.8645 0 0.5 3.3645 0.5 7.5C0.5 11.6355 3.8645 15 8 15C12.1355 15 15.5 11.6355 15.5 7.5C15.5 3.3645 12.1355 0 8 0ZM8 1C11.5845 1 14.5 3.916 14.5 7.5C14.5 8.9525 14.0155 10.2905 13.207 11.374C12.5795 10.272 11.4035 9.549 10.0925 9.549H5.908C4.5975 9.5495 3.421 10.273 2.7935 11.374C1.985 10.291 1.5 8.9525 1.5 7.5C1.5 3.916 4.416 1 8 1ZM3.506 12.1845C3.894 11.211 4.8405 10.549 5.9085 10.549H10.0925C11.161 10.549 12.1065 11.211 12.495 12.1845C11.326 13.3055 9.744 14 8 14C6.2565 14 4.675 13.3055 3.506 12.1845Z"
      fill={color}
    />
    <path
      d="M7.99984 8.96394C9.50134 8.96394 10.7223 7.64544 10.7223 6.02494C10.7223 4.40444 9.50134 3.08594 7.99984 3.08594C6.49884 3.08594 5.27734 4.40444 5.27734 6.02494C5.27734 7.64544 6.49884 8.96394 7.99984 8.96394ZM7.99984 4.08594C8.94984 4.08594 9.72234 4.95544 9.72234 6.02494C9.72234 7.09444 8.94984 7.96394 7.99984 7.96394C7.05034 7.96394 6.27734 7.09444 6.27734 6.02494C6.27734 4.95544 7.05034 4.08594 7.99984 4.08594Z"
      fill={color}
    />
  </svg>
));

Contact.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

Contact.displayName = 'Contact';

export default Contact;
