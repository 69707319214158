import { FETCH_COMPANY_BHOMES, FETCH_CREATE_COMPANY_BHOME, FETCH_DELETE_COMPANY_BHOME } from '../actionTypes';

export const fetchCompanyBhomes = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_COMPANY_BHOMES.default,
        url: '/bhomes/company-bhomes',
    },
});

export const fetchCreateCompanyBhome = (companyId, bhomeId) => ({
    type: 'FETCH',
    params: {
        type: FETCH_CREATE_COMPANY_BHOME.default,
        url: '/bhomes/company-bhomes',
        method: 'POST',
        body: {
            companyId,
            bhomeId,
        },
    },
});

export const fetchDeleteCompanyBhome = (companyId, bhomeId) => ({
    type: 'FETCH',
    params: {
        type: FETCH_DELETE_COMPANY_BHOME.default,
        url: '/bhomes/company-bhomes',
        method: 'DELETE',
        body: {
            companyId,
            bhomeId,
        },
    },
});
