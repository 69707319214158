import { FETCH_ALL_PUBLIC_CODES, FETCH_DELETE_PUBLIC_CODE, FETCH_CREATE_PUBLIC_CODE } from '../actionTypes';

export const fetchAllPublicCodes = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_ALL_PUBLIC_CODES.default,
        url: '/public/bhomes/codes',
    },
});

export const fetchCreatePublicCode = publicCode => ({
    type: 'FETCH',
    params: {
        type: FETCH_CREATE_PUBLIC_CODE.default,
        url: '/public/bhomes/codes',
        method: 'POST',
        body: {
            ...publicCode,
        },
    },
});

export const fetchDeletePublicCode = id => ({
    type: 'FETCH',
    params: {
        type: FETCH_DELETE_PUBLIC_CODE.default,
        url: `/public/bhomes/codes/${id}`,
        method: 'DELETE',
    },
});
