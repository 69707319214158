import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { groupBy, sortBy } from 'lodash-es';
import { loading, arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import Grid from 'components/reusables/Grid';
import { clearViewData } from 'actions';
import './RanchLocations.scss';
import { FETCH_RANCH_LOCATIONS, UPDATE_RANCH_LOCATION } from './actionTypes';
import { fetchRanchLocations, updateRanchLocation } from './actions';

const RanchLocations = () => {
    const [, setGridApi] = useState(null);

    const dispatch = useDispatch();

    const ranchLocations = useSelector(state => state.ranchLocations.locations, arrayOfObjectsShallowEqual);
    const sortedLocations = useMemo(() => {
        const locationByRanch = groupBy(ranchLocations, 'ranch_id');
        return Object.keys(locationByRanch).reduce((acc, key) => {
            const sortedLocations = sortBy(locationByRanch[key], item => `${item.lat}${item.lng}`).map((item, i) => ({
                ...item,
                index: i + 1,
            }));
            return [...acc, ...sortedLocations];
        }, []);
    }, [ranchLocations]);

    const isFetched = useSelector(state => state.ranchLocations.isFetched);

    useEffect(() => {
        dispatch(fetchRanchLocations());
        return () => dispatch(clearViewData());
    }, [dispatch]);

    const handleOnGridReady = useCallback(gridApi => {
        setGridApi(gridApi);
    }, []);

    const onCellChange = useCallback(
        ({ data }) => {
            // eslint-disable-next-line camelcase
            const { id, status, bhome_id } = data;
            // eslint-disable-next-line camelcase
            dispatch(updateRanchLocation(id, { status, bhome: bhome_id }));
        },
        [dispatch]
    );

    const gridOptions = useMemo(
        () => ({
            columnDefs: [
                { headerName: 'ID', field: 'location_id', editable: false },
                { headerName: 'Ranch name', field: 'name', editable: false },
                { headerName: 'Ranch id', field: 'ranch_id', editable: false },
                { headerName: 'lat', field: 'lat', editable: true },
                { headerName: 'lng', field: 'lng', editable: true },
                { headerName: 'bhome', field: 'bhome_id', editable: true },
            ],
            columnTypes: {
                nonEditableColumn: { editable: false },
            },
        }),
        []
    );

    return (
        <div id="view" className="page ranch-locations">
            {isFetched && (
                <Grid
                    onGridReady={handleOnGridReady}
                    rowData={sortedLocations}
                    gridOptions={gridOptions}
                    onCellChange={onCellChange}
                />
            )}
        </div>
    );
};

export default loading([FETCH_RANCH_LOCATIONS.default, UPDATE_RANCH_LOCATION.default])(RanchLocations);
