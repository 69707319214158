import React from 'react';
import PhoneInput from 'react-phone-input-2';
import PropTypes from 'prop-types';
import cn from 'classnames';
import FormField from '../FormField';
import './PhoneInputFormField.scss';

const PhoneInputField = (props) => (
  <div className={cn({ 'phone-input--error': props.error })}>
    <PhoneInput {...props} />
    {props.error && <span className="text-field--error-message">{props.error}</span>}
  </div>
);

PhoneInputField.propTypes = {
  error: PropTypes.string
};

const PhoneInputFormField = (props) => (
  <FormField
    {...props}
    inputProps={{ required: props.required, name: props.name }}
    Component={PhoneInputField}
    country="us"
    preferredCountries={['us', 'il', 'ua']}
    enableSearch
  />
);

PhoneInputFormField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape().isRequired,
  required: PropTypes.bool,
  error: PropTypes.string
};

export default PhoneInputFormField;
