import React from 'react';
import { CheckboxFormField } from '@beewise/hook-form';
import PropTypes from 'prop-types';
import SelectGridField from 'components/reusables/SelectGridField';
import CreationFields from './CreationFields';
import { gridOptions } from '../utils';

const DirectBhomeUpdate = ({ bhomeOptions, form }) => {
    const { control, setValue } = form;
    return (
        <>
            <div className="row software-bundle-input">
                <SelectGridField
                    name="bhomeIds"
                    gridName="Select Bhomes"
                    isMulti
                    gridOptions={gridOptions}
                    onSelect={value => setValue('bhomeIds', value)}
                    label="Select Bhome"
                    options={bhomeOptions}
                    className="software-update-input"
                    control={control}
                    required
                />
                <CheckboxFormField
                    name="rebootAfterUpdate"
                    className="software-reboot-update"
                    label="Reboot after update"
                    disabled={!bhomeOptions.length}
                    control={control}
                />
                <CheckboxFormField
                    name="enforceImmediateAction"
                    className="software-reboot-update"
                    label="Enforce Immediate"
                    disabled={!bhomeOptions.length}
                    control={control}
                />
            </div>
            <CreationFields control={control} />
        </>
    );
};

DirectBhomeUpdate.propTypes = {
    bhomeOptions: PropTypes.arrayOf(PropTypes.shape()),
    form: PropTypes.shape(),
};

export default DirectBhomeUpdate;
