import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const InfoCircleWithDot = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...rest}
  >
    <rect width="24" height="24" rx="12" fill="#EBEDF8" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1188 4.28567C13.4344 4.09766 12.7222 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 11.2778 19.9023 10.5656 19.7143 9.88119C19.4791 10.1982 19.198 10.4793 18.881 10.7145C18.9596 11.1355 19 11.5657 19 12C19 13.8565 18.2625 15.637 16.9497 16.9497C15.637 18.2625 13.8565 19 12 19C10.1435 19 8.36301 18.2625 7.05025 16.9497C5.7375 15.637 5 13.8565 5 12C5 10.1435 5.7375 8.36301 7.05025 7.05025C8.36301 5.7375 10.1435 5 12 5C12.4343 5 12.8645 5.04037 13.2855 5.11902C13.5207 4.80196 13.8018 4.52092 14.1188 4.28567ZM10 15.5C10 15.225 10.225 15 10.5 15H11.5V12H10.75C10.475 12 10.25 11.775 10.25 11.5C10.25 11.225 10.475 11 10.75 11H12C12.275 11 12.5 11.225 12.5 11.5V15H13.5C13.775 15 14 15.225 14 15.5C14 15.775 13.775 16 13.5 16H10.5C10.225 16 10 15.775 10 15.5ZM12.3518 9.35176C12.2111 9.49241 12.0203 9.57143 11.8214 9.57143C11.6225 9.57143 11.4318 9.49241 11.2911 9.35176C11.1504 9.21111 11.0714 9.02034 11.0714 8.82143C11.0714 8.62252 11.1504 8.43175 11.2911 8.2911C11.4318 8.15045 11.6225 8.07143 11.8214 8.07143C12.0203 8.07143 12.2111 8.15045 12.3518 8.2911C12.4924 8.43175 12.5714 8.62252 12.5714 8.82143C12.5714 9.02034 12.4924 9.21111 12.3518 9.35176Z"
      fill={color}
    />
    <circle cx="16.5" cy="7.5" r="2.5" fill={color} />
  </svg>
));

InfoCircleWithDot.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

InfoCircleWithDot.displayName = 'InfoCircleWithDot';

export default InfoCircleWithDot;
