import { CLEAR_VIEW_DATA } from 'actionTypes';
import {
    FETCH_USER_ROLES,
    FETCH_CREATE_USER_ROLE,
    FETCH_DELETE_USER_ROLE,
    FETCH_UPDATE_USER_ROLE,
} from '../actionTypes';

const initialState = {
    roles: [],
    isFetched: false,
};

const userRoles = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_ROLES.success:
            return {
                ...state,
                roles: action.data.roles,
                isFetched: true,
            };
        case FETCH_CREATE_USER_ROLE.success:
            return {
                ...state,
                roles: [action.data.role, ...state.roles],
            };
        case FETCH_DELETE_USER_ROLE.success:
            return {
                ...state,
                roles: state.roles.filter(item => item.id !== action.data.id),
            };
        case FETCH_UPDATE_USER_ROLE.success:
            return {
                ...state,
                roles: state.roles.map(item => {
                    if (item.id === action.data.role.id) {
                        return {
                            ...action.data.role,
                        };
                    }

                    return item;
                }),
            };
        case CLEAR_VIEW_DATA:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default userRoles;
