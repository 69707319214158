import { CLEAR_VIEW_DATA } from 'actionTypes';
import {
    FETCH_LOCATION_TYPES,
    FETCH_CREATE_LOCATION_TYPE,
    FETCH_UPDATE_LOCATION_TYPE,
    FETCH_DELETE_LOCATION_TYPE,
} from '../actionTypes';

const initialState = {
    types: [],
    isFetched: false,
};

const locationTypes = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_LOCATION_TYPES.success:
            return {
                ...state,
                types: action.data.types,
                isFetched: true,
            };
        case FETCH_CREATE_LOCATION_TYPE.success:
            return {
                ...state,
                types: [action.data.type, ...state.types],
            };
        case FETCH_UPDATE_LOCATION_TYPE.success:
            return {
                ...state,
                types: state.types.map(item => {
                    if (item.id === action.data.type.id) {
                        return {
                            ...action.data.type,
                        };
                    }

                    return item;
                }),
            };
        case FETCH_DELETE_LOCATION_TYPE.success:
            return {
                ...state,
                types: state.types.filter(item => item.id !== action.data.id),
            };
        case CLEAR_VIEW_DATA:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default locationTypes;
