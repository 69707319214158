import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loading, arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { clearViewData } from 'actions';
import Grid from 'components/reusables/Grid';
import {
    fetchConferenceUsers,
    FETCH_CONFERENCE_USERS,
    FETCH_REMOVE_CONFERENCE_USER,
    FETCH_UPDATE_CONFERENCE_USER,
    fetchRemoveConferenceUsers,
    fetchUpdateConferenceUsers,
} from './actions';

const Conference = () => {
    const [, setGridApi] = useState(null);

    const dispatch = useDispatch();

    const types = useSelector(state => state.conference.contacts, arrayOfObjectsShallowEqual);

    useEffect(() => {
        dispatch(fetchConferenceUsers());
        return () => dispatch(clearViewData());
    }, [dispatch]);

    const handleOnGridReady = useCallback(gridApi => {
        setGridApi(gridApi);
    }, []);

    const handleItemDelete = useCallback(data => dispatch(fetchRemoveConferenceUsers(data.id)), [dispatch]);

    const onCellChange = useCallback(
        ({ data }) => {
            const { id, ...rest } = data;

            dispatch(fetchUpdateConferenceUsers(id, rest));
        },
        [dispatch]
    );

    const gridOptions = useMemo(
        () => ({
            columnDefs: [
                { headerName: 'ID', field: 'id', editable: false },
                { headerName: 'Name', field: 'name', editable: true },
                {
                    headerName: 'Total acres',
                    field: 'total_acres',
                    editable: true,
                },
                { headerName: 'Email', field: 'email', editable: true },
                {
                    headerName: 'Contact number',
                    field: 'contact_number',
                    editable: true,
                },
                {
                    headerName: 'Ranch name',
                    field: 'ranch_name',
                    editable: false,
                },
                {
                    headerName: 'Scheduled time',
                    field: 'scheduled_time',
                    editable: false,
                },
                {
                    headerName: 'Created by',
                    field: 'created_by_email',
                    editable: false,
                },
                {
                    headerName: 'Created at',
                    field: 'created_at',
                    editable: false,
                },
            ],
            columnTypes: {
                nonEditableColumn: { editable: false },
            },
        }),
        []
    );

    return (
        <div id="view" className="page company-type">
            <Grid
                onGridReady={handleOnGridReady}
                rowData={types}
                gridOptions={gridOptions}
                handleItemDelete={handleItemDelete}
                onCellChange={onCellChange}
            />
        </div>
    );
};

export default loading([
    FETCH_UPDATE_CONFERENCE_USER.default,
    FETCH_CONFERENCE_USERS.default,
    FETCH_REMOVE_CONFERENCE_USER.default,
])(Conference);
