/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Icon from '@beewise/icon';
import Modal from '@beewise/modal';
import { capitalize } from 'lodash-es';
import TextField from '@beewise/text-field';
import { signOut } from 'components/views/SignIn/actions/sign.in.actions';

const Profile = ({ isOpen, toggleModal, user, signOut }) => {
    const [name, setName] = useState(user.username);
    const [email, setEmail] = useState(user.email);

    return (
        <Modal
            isOpen={isOpen}
            appendTo="#app"
            header={`${capitalize(user.username)}'s Profile`}
            onClose={() => toggleModal(false)}
        >
            <div className="profile">
                <div className="profile-item">
                    <h2>Details</h2>
                    <div className="profile-form">
                        <TextField label="Name" value={name} onChange={setName} type="text" size="small" disabled />
                        <TextField label="Email" value={email} onChange={setEmail} type="text" size="small" disabled />
                        <div className="profile-actions">
                            <div className="reset-password">Reset Password</div>
                            <button
                                className="btn btn-secondary logout"
                                onClick={() => {
                                    toggleModal(false);
                                    signOut();
                                }}
                            >
                                Log out
                            </button>
                        </div>
                    </div>
                </div>
                <div className="profile-item">
                    <div className="profile-sub">
                        <h2>Notifications</h2>
                        <Icon type="switch-on" className="profile-switch" />
                    </div>
                    <div className="profile-form notifications-form" />
                </div>
            </div>
        </Modal>
    );
};

Profile.propTypes = {
    isOpen: PropTypes.bool,
    toggleModal: PropTypes.func,
    signOut: PropTypes.func,
    user: PropTypes.shape(),
};

export default connect(
    state => ({
        user: state.app.user,
    }),
    {
        signOut,
    }
)(Profile);
