import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Pollen = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    stroke={color}
    {...rest}
  >
    <path
      d="M16.5994 20.1829V22.8125"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2845 9.66433L15.3371 6.81556L11.5943 9.7213L8.89889 6.5L6.2079 9.56791L2.57462 6.65339C2.57462 6.65339 2.57462 11.5138 2.57462 11.5358C2.56313 13.1682 3.18031 14.7425 4.2982 15.9322C5.41609 17.1219 6.94894 17.8357 8.57895 17.9258L9.80612 17.8776C10.0393 17.8473 10.2705 17.8034 10.4986 17.7461"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.4554 12.0267L18.7469 14.1304L16.8185 11.7988L14.8682 13.9902L12.2385 11.9128C12.2385 11.9128 12.2385 15.419 12.2385 15.4496C12.23 16.6307 12.6763 17.7698 13.4849 18.6306C14.2936 19.4915 15.4025 20.0081 16.5818 20.0734L17.4583 20.0427C18.5607 19.887 19.5694 19.3373 20.2978 18.4954C21.0261 17.6534 21.4249 16.5761 21.4203 15.4628L21.4554 12.0267Z"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.58698 17.9915V22.3742"
      stroke={color}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.08999 4.7451C6.08999 4.87267 6.05216 4.99737 5.98129 5.10344C5.91041 5.20951 5.80968 5.29218 5.69182 5.341C5.57396 5.38982 5.44427 5.40259 5.31915 5.3777C5.19404 5.35282 5.07911 5.29139 4.9889 5.20118C4.8987 5.11098 4.83727 4.99605 4.81238 4.87093C4.78749 4.74581 4.80027 4.61613 4.84909 4.49827C4.8979 4.38041 4.98058 4.27967 5.08665 4.2088C5.19271 4.13793 5.31742 4.1001 5.44499 4.1001C5.61605 4.1001 5.78011 4.16805 5.90107 4.28901C6.02203 4.40997 6.08999 4.57403 6.08999 4.7451Z"
      fill={color}
    />
    <path
      d="M9.69751 2.34496C9.69826 2.47359 9.66074 2.59953 9.58974 2.70679C9.51874 2.81405 9.41746 2.89778 9.29877 2.94735C9.18007 2.99692 9.04932 3.01008 8.92313 2.98517C8.79693 2.96025 8.68099 2.89839 8.59004 2.80744C8.49908 2.71648 8.43722 2.60054 8.41231 2.47435C8.3874 2.34816 8.40056 2.2174 8.45013 2.09871C8.49969 1.98001 8.58342 1.87873 8.69068 1.80773C8.79794 1.73673 8.92389 1.69922 9.05251 1.69996C9.22328 1.70095 9.38676 1.76922 9.50751 1.88997C9.62826 2.01071 9.69653 2.1742 9.69751 2.34496Z"
      fill={color}
    />
    <path
      d="M18.0975 3.54491C18.0982 3.67338 18.0608 3.79918 17.99 3.90636C17.9191 4.01353 17.8181 4.09727 17.6996 4.14695C17.5811 4.19663 17.4506 4.21001 17.3245 4.1854C17.1984 4.16079 17.0824 4.0993 16.9913 4.00873C16.9002 3.91815 16.8381 3.80256 16.8127 3.67661C16.7874 3.55067 16.8 3.42003 16.849 3.30127C16.898 3.18251 16.9811 3.08097 17.0879 3.00951C17.1947 2.93806 17.3203 2.89992 17.4487 2.89991C17.5337 2.89942 17.618 2.91574 17.6967 2.94793C17.7754 2.98012 17.8469 3.02756 17.9072 3.0875C17.9675 3.14744 18.0154 3.21871 18.048 3.29721C18.0807 3.37571 18.0975 3.45989 18.0975 3.54491Z"
      fill={color}
    />
    <path
      d="M20.4975 8.34496C20.4982 8.47359 20.4607 8.59953 20.3897 8.70679C20.3187 8.81405 20.2174 8.89778 20.0988 8.94735C19.9801 8.99692 19.8493 9.01008 19.7231 8.98517C19.5969 8.96026 19.481 8.89839 19.39 8.80744C19.2991 8.71648 19.2372 8.60054 19.2123 8.47435C19.1874 8.34816 19.2005 8.2174 19.2501 8.09871C19.2997 7.98001 19.3834 7.87873 19.4907 7.80773C19.5979 7.73673 19.7239 7.69922 19.8525 7.69996C20.0236 7.69996 20.1876 7.76792 20.3086 7.88888C20.4295 8.00984 20.4975 8.1739 20.4975 8.34496Z"
      fill={color}
    />
    <path
      d="M13.2972 4.7301C13.301 4.85914 13.2661 4.98636 13.1971 5.09549C13.1281 5.20461 13.0282 5.29067 12.91 5.34266C12.7918 5.39465 12.6609 5.41021 12.5338 5.38733C12.4067 5.36446 12.2894 5.3042 12.1968 5.21426C12.1042 5.12432 12.0405 5.0088 12.0139 4.88247C11.9873 4.75614 11.999 4.62475 12.0475 4.50511C12.096 4.38547 12.1791 4.28302 12.2862 4.21087C12.3932 4.13872 12.5194 4.10015 12.6485 4.1001C12.8173 4.10003 12.9796 4.1658 13.1007 4.28344C13.2219 4.40107 13.2923 4.56131 13.2972 4.7301Z"
      fill={color}
    />
  </svg>
));

Pollen.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

Pollen.displayName = 'Pollen';

export default Pollen;
