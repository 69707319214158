import {
    FETCH_USER_ROLES,
    FETCH_CREATE_USER_ROLE,
    FETCH_DELETE_USER_ROLE,
    FETCH_UPDATE_USER_ROLE,
} from '../actionTypes';

export const fetchUserRoles = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_USER_ROLES.default,
        url: '/users/roles',
    },
});

export const fetchCreateUserRole = role => ({
    type: 'FETCH',
    params: {
        type: FETCH_CREATE_USER_ROLE.default,
        url: '/users/roles',
        method: 'POST',
        body: {
            role,
        },
    },
});

export const fetchDeleteUserRole = id => ({
    type: 'FETCH',
    params: {
        type: FETCH_DELETE_USER_ROLE.default,
        url: `/users/roles/${id}`,
        method: 'DELETE',
    },
});

export const fetchUpdateUserRole = (id, role) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_USER_ROLE.default,
        url: `/users/roles/${id}`,
        method: 'PUT',
        body: {
            role,
        },
    },
});
