import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const EmptyBox = forwardRef(({ size = 33 }, ref) => (
  <svg
    ref={ref}
    width={size}
    height={size}
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.24219 13.3336L8.37552 8.26697H24.6289L27.7622 13.3336H5.24219Z" fill="#F6F6F6" />
    <path
      d="M29.7115 13.4466L25.7515 7.04663C25.6826 6.9312 25.585 6.83556 25.4682 6.76897C25.3513 6.70239 25.2193 6.66714 25.0849 6.66663H7.92486C7.7904 6.66714 7.65837 6.70239 7.54156 6.76897C7.42475 6.83556 7.32714 6.9312 7.25819 7.04663L3.29819 13.4466C3.20348 13.6152 3.15733 13.8067 3.16485 14V24.6666C3.16485 24.8434 3.23509 25.013 3.36012 25.138C3.48514 25.2631 3.65471 25.3333 3.83152 25.3333H29.1649C29.3417 25.3333 29.5112 25.2631 29.6363 25.138C29.7613 25.013 29.8315 24.8434 29.8315 24.6666V14C29.8381 13.8084 29.7969 13.6182 29.7115 13.4466ZM17.6982 20.2666H15.2982C15.086 20.2666 14.8825 20.1823 14.7325 20.0323C14.5825 19.8823 14.4982 19.6788 14.4982 19.4666C14.4982 19.2545 14.5825 19.051 14.7325 18.9009C14.8825 18.7509 15.086 18.6666 15.2982 18.6666H17.6982C17.9104 18.6666 18.1138 18.7509 18.2639 18.9009C18.4139 19.051 18.4982 19.2545 18.4982 19.4666C18.4982 19.6788 18.4139 19.8823 18.2639 20.0323C18.1138 20.1823 17.9104 20.2666 17.6982 20.2666ZM5.23819 13.3333L8.37152 8.26663H24.6249L27.7582 13.3333H5.23819Z"
      fill="#D9D9D9"
    />
    <mask id="mask0_2507_331774" maskUnits="userSpaceOnUse" x="3" y="6" width="27" height="20">
      <path
        d="M29.7115 13.4466L25.7515 7.04663C25.6826 6.9312 25.585 6.83556 25.4682 6.76897C25.3513 6.70239 25.2193 6.66714 25.0849 6.66663H7.92486C7.7904 6.66714 7.65837 6.70239 7.54156 6.76897C7.42475 6.83556 7.32714 6.9312 7.25819 7.04663L3.29819 13.4466C3.20348 13.6152 3.15733 13.8067 3.16485 14V24.6666C3.16485 24.8434 3.23509 25.013 3.36012 25.138C3.48514 25.2631 3.65471 25.3333 3.83152 25.3333H29.1649C29.3417 25.3333 29.5112 25.2631 29.6363 25.138C29.7613 25.013 29.8315 24.8434 29.8315 24.6666V14C29.8381 13.8084 29.7969 13.6182 29.7115 13.4466ZM17.6982 20.2666H15.2982C15.086 20.2666 14.8825 20.1823 14.7325 20.0323C14.5825 19.8823 14.4982 19.6788 14.4982 19.4666C14.4982 19.2545 14.5825 19.051 14.7325 18.9009C14.8825 18.7509 15.086 18.6666 15.2982 18.6666H17.6982C17.9104 18.6666 18.1138 18.7509 18.2639 18.9009C18.4139 19.051 18.4982 19.2545 18.4982 19.4666C18.4982 19.6788 18.4139 19.8823 18.2639 20.0323C18.1138 20.1823 17.9104 20.2666 17.6982 20.2666ZM5.23819 13.3333L8.37152 8.26663H24.6249L27.7582 13.3333H5.23819Z"
        fill="#DEDEDE"
      />
    </mask>
    <g mask="url(#mask0_2507_331774)">
      <path d="M8.5 26L30.5109 6.66663H33.8333V26H8.5Z" fill="#C8CACB" />
    </g>
    <path
      d="M22.4984 1.59998C21.6148 1.59998 20.8984 2.31632 20.8984 3.19998C20.8984 4.08363 21.6148 4.79998 22.4984 4.79998C23.3821 4.79998 24.0984 4.08363 24.0984 3.19998C24.0984 2.31632 23.3821 1.59998 22.4984 1.59998ZM22.4984 2.39998C22.9403 2.39998 23.2984 2.75815 23.2984 3.19998C23.2984 3.6418 22.9403 3.99998 22.4984 3.99998C22.0566 3.99998 21.6984 3.6418 21.6984 3.19998C21.6984 2.75815 22.0566 2.39998 22.4984 2.39998Z"
      fill="#B5B6B7"
    />
    <path
      d="M13.8984 2C14.4507 2 14.8984 1.55228 14.8984 1C14.8984 0.447715 14.4507 0 13.8984 0C13.3462 0 12.8984 0.447715 12.8984 1C12.8984 1.55228 13.3462 2 13.8984 2Z"
      fill="#B5B6B7"
    />
    <path
      d="M15.8542 5.81021L15.8942 5.8543L17.2018 7.46221L18.8098 6.15469C19.0435 5.96464 19.387 6.00004 19.5771 6.23374C19.7545 6.45186 19.7354 6.76565 19.5421 6.96103L19.498 7.00101L17.8901 8.30864L19.1482 9.8558C19.3382 10.0895 19.3028 10.433 19.0691 10.6231C18.851 10.8004 18.5372 10.7814 18.3418 10.5881L18.3019 10.544L17.0437 8.99695L15.4965 10.255C15.2628 10.445 14.9193 10.4097 14.7293 10.1759C14.5519 9.95783 14.5709 9.64404 14.7642 9.44866L14.8083 9.40868L16.3554 8.15052L15.0479 6.54252C14.8578 6.30882 14.8932 5.9653 15.1269 5.77525C15.3295 5.61055 15.6145 5.61517 15.8106 5.77146L15.8542 5.81021Z"
      fill="#B5B6B7"
    />
  </svg>
));

EmptyBox.propTypes = {
  size: PropTypes.number
};

export default EmptyBox;
