import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import { usePermission } from '@beewise/react-utils';
import TextField from '@beewise/text-field';
import { SelectField } from '@beewise/select-field';
import constants from 'appConstants';
import Creatable from 'react-select/creatable';
import { JsonEditor as Editor } from 'components/reusables/JsonEditor';

const UserTab = ({
    companies,
    handleSetCompany,
    invitationUrl,
    createAnotherUser,
    state,
    onInputChange,
    signupError,
    createUser,
    stateDispatch,
    bhomes,
    handleSelectBhome,
    roles,
    companyTypes,
    isSuperViewer,
    handleCompaniesDelete,
    handleBhomesDelete,
    handleFetchPopulatedCsvReport,
    handleFetchDeadoutsCsvReport,
}) => {
    const [companiesToDelete, setCompaniesToDelete] = useState([]);
    const [bhomesToDelete, setBhomesToDelete] = useState([]);

    const isAdminRolePicked = roles.find(
        role =>
            state.role === role.id &&
            (role.name === constants.ROLES.ADMIN ||
                role.name === constants.ROLES.READ ||
                role.name === constants.ROLES.INSTALLER ||
                role.name === constants.ROLES.GROWER)
    );
    const isInstallerRolePicked = roles.find(role => state.role === role.id && role.name === constants.ROLES.INSTALLER);

    const isGenerateButtonEnabled =
        state.email &&
        state.role &&
        ((isAdminRolePicked && state.companyName) || !isAdminRolePicked) &&
        ((isInstallerRolePicked && state.phone) || !isInstallerRolePicked);
    const isCreationAllowed = usePermission('createUser', 'admin');
    const isCompanyDeleteAllowed = usePermission('deleteCompanyAndContent', 'admin');
    const canEraseBhome = usePermission('canEraseBhome', 'admin');

    const rolesOptions = useMemo(() => {
        if (roles.length) {
            return [
                {
                    label: roles.find(role => role.name === constants.ROLES.READ).name,
                    value: roles.find(role => role.name === constants.ROLES.READ).id,
                },
                {
                    label: roles.find(role => role.name === constants.ROLES.ADMIN).name,
                    value: roles.find(role => role.name === constants.ROLES.ADMIN).id,
                },
                {
                    label: roles.find(role => role.name === constants.ROLES.SUPER_ADMIN).name,
                    value: roles.find(role => role.name === constants.ROLES.SUPER_ADMIN).id,
                },
                {
                    label: roles.find(role => role.name === constants.ROLES.TECHNICIAN).name,
                    value: roles.find(role => role.name === constants.ROLES.TECHNICIAN).id,
                },
                {
                    label: roles.find(role => role.name === constants.ROLES.SUPER_VIEWER).name,
                    value: roles.find(role => role.name === constants.ROLES.SUPER_VIEWER).id,
                },
                {
                    label: roles.find(role => role.name === constants.ROLES.GROWER).name,
                    value: roles.find(role => role.name === constants.ROLES.GROWER).id,
                },
                {
                    label: roles.find(role => role.name === constants.ROLES.INSTALLER).name,
                    value: roles.find(role => role.name === constants.ROLES.INSTALLER).id,
                },
            ];
        }
        return [];
    }, [roles]);

    const bhomesOptions = useMemo(() => bhomes.map(bhome => ({ label: bhome.id, value: bhome.id })), [bhomes]);
    const companiesOptions = useMemo(
        () =>
            companies.map(company => ({
                label: company.name,
                value: company.id,
            })),
        [companies]
    );
    const companiesCreateOptions = useMemo(
        () =>
            companies.map(company => ({
                label: company.name,
                value: company.id,
            })),
        [companies]
    );
    const isExistingCompany = useMemo(
        () => companiesOptions.find(item => item.value === state.companyName),
        [companiesOptions, state.companyName]
    );

    const companyTypesOptions = useMemo(() => {
        if (companyTypes.length) {
            return companyTypes.map(type => ({
                label: type.name,
                value: type.id,
            }));
        }
        return [];
    }, [companyTypes]);

    const handleCompaniesDeleteClick = useCallback(() => {
        if (companiesToDelete?.length) {
            handleCompaniesDelete(companiesToDelete);
            setCompaniesToDelete([]);
        }
    }, [companiesToDelete, handleCompaniesDelete]);

    const handleBhomesDeleteClick = useCallback(() => {
        if (bhomesToDelete?.length) {
            handleBhomesDelete(bhomesToDelete);
            setBhomesToDelete([]);
        }
    }, [bhomesToDelete, handleBhomesDelete]);

    return (
        <>
            <div className="dashboard-login-wrapper">
                <div className="dashboard-login-item">
                    <h3 className="dashboard-login">Login under company:</h3>
                    <SelectField options={companiesOptions} onChange={handleSetCompany} size="small" />
                </div>
                <div className="dashboard-login-item">
                    <h3 className="dashboard-login">Login to see beehome:</h3>
                    <SelectField options={bhomesOptions} onChange={handleSelectBhome} size="small" />
                </div>
                <Button className="dashboard-csv-report-download" onClick={handleFetchPopulatedCsvReport}>
                    Get Populated Csv Report
                </Button>
                <Button className="dashboard-csv-report-download" onClick={handleFetchDeadoutsCsvReport}>
                    Get Deadouts Csv Report
                </Button>
            </div>
            {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
            {isCreationAllowed &&
                (invitationUrl ? (
                    <div className="dashboard-result">
                        <a href={invitationUrl}>{invitationUrl}</a>
                        <Button className="btn-primary" onClick={createAnotherUser}>
                            Create One More
                        </Button>
                    </div>
                ) : (
                    <div className="dashboard-form">
                        <h3>Create new Admin/Super Admin User:</h3>
                        <TextField
                            label="Email"
                            className="dashboard-form-input"
                            value={state.email}
                            onChange={value => onInputChange('email', value.trim())}
                            error={signupError}
                            size="small"
                        />
                        <SelectField
                            value={state.role}
                            options={rolesOptions}
                            onChange={value => onInputChange('role', value)}
                            size="small"
                            placeholder="Select role"
                        />
                        <TextField
                            label="Phone"
                            className="dashboard-form-input"
                            value={state.phone}
                            onChange={value => onInputChange('phone', value.trim())}
                            size="small"
                        />
                        {state?.permissions?.id && (
                            <div className="permissions-json-editor" key={state?.permissions?.id}>
                                <Editor
                                    mode="tree"
                                    value={state?.permissions?.data}
                                    onChange={permissions => onInputChange('permissions', permissions)}
                                />
                            </div>
                        )}
                        {isAdminRolePicked && (
                            <>
                                <Creatable
                                    styles={{
                                        placeholder: provided => ({
                                            ...provided,
                                            marginLeft: '8px',
                                            color: 'rgba(191, 191, 191, .87)',
                                        }),
                                    }}
                                    className="creatable-select"
                                    placeholder="Enter company name"
                                    onChange={e => onInputChange('companyName', e.value)}
                                    options={companiesCreateOptions}
                                />
                                {!isExistingCompany && (
                                    <SelectField
                                        placeholder="Select company type(s)"
                                        options={companyTypesOptions}
                                        value={state.companyTypes}
                                        onChange={value => onInputChange('companyTypes', value)}
                                        isMulti
                                        size="small"
                                    />
                                )}
                            </>
                        )}
                        <div className="dashboard-actions">
                            {!isSuperViewer && (
                                <Button
                                    className="btn-primary"
                                    onClick={createUser}
                                    disabled={!isGenerateButtonEnabled}
                                >
                                    Generate Invitation Link
                                </Button>
                            )}
                            <Button className="btn-secondary" onClick={() => stateDispatch({ type: 'CLEAR' })}>
                                Clear Form
                            </Button>
                        </div>
                    </div>
                ))}
            {isCompanyDeleteAllowed && (
                <div className="dashboard-company-delete">
                    <h3 className="dashboard-login">Pick companies to delete:</h3>
                    <SelectField
                        options={companiesOptions.filter(item => item.value !== 1)}
                        value={companiesToDelete}
                        onChange={setCompaniesToDelete}
                        isMulti
                        size="small"
                    />
                    <Button className="btn-primary" onClick={handleCompaniesDeleteClick}>
                        Delete companies with all relations
                    </Button>
                </div>
            )}
            {canEraseBhome && (
                <div className="dashboard-bhome-delete">
                    <SelectField
                        options={bhomesOptions}
                        value={bhomesToDelete}
                        onChange={setBhomesToDelete}
                        isMulti
                        size="small"
                    />
                    <Button className="btn-primary" onClick={handleBhomesDeleteClick}>
                        Delete bhomes with all relations
                    </Button>
                </div>
            )}
        </>
    );
};

UserTab.propTypes = {
    companies: PropTypes.arrayOf(PropTypes.shape()),
    bhomes: PropTypes.arrayOf(PropTypes.shape()),
    roles: PropTypes.arrayOf(PropTypes.shape()),
    companyTypes: PropTypes.arrayOf(PropTypes.shape()),
    handleSetCompany: PropTypes.func,
    invitationUrl: PropTypes.string,
    createAnotherUser: PropTypes.func,
    state: PropTypes.shape(),
    onInputChange: PropTypes.func,
    signupError: PropTypes.string,
    handleSelectBhome: PropTypes.func,
    createUser: PropTypes.func,
    handleCompaniesDelete: PropTypes.func,
    handleBhomesDelete: PropTypes.func,
    handleFetchPopulatedCsvReport: PropTypes.func,
    handleFetchDeadoutsCsvReport: PropTypes.func,
    stateDispatch: PropTypes.func,
    isSuperViewer: PropTypes.bool,
};

export default UserTab;
