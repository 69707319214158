import { CLEAR_VIEW_DATA } from 'actionTypes';
import { FETCH_DELETE_USER_BHOME, FETCH_CREATE_USER_BHOME, FETCH_USER_BHOMES } from '../actionTypes';

const initialState = {
    bhomes: [],
    isFetched: false,
};

const userBhomes = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_BHOMES.success:
            return {
                ...state,
                bhomes: action.data.bhomes.map((item, index) => ({
                    ...item,
                    id: index,
                })),
                isFetched: true,
            };
        case FETCH_CREATE_USER_BHOME.success:
            return {
                ...state,
                bhomes: [action.data.bhome, ...state.bhomes].map((item, index) => ({
                    ...item,
                    id: index,
                })),
            };
        case FETCH_DELETE_USER_BHOME.success:
            return {
                ...state,
                bhomes: state.bhomes.filter(
                    item => item.user_id !== action.data.userId || item.bhome_id !== action.data.bhomeId
                ),
            };
        case CLEAR_VIEW_DATA:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default userBhomes;
