import { CLEAR_VIEW_DATA } from 'actionTypes';
import { FETCH_COMPANIES, FETCH_CREATE_COMPANY, FETCH_DELETE_COMPANY, FETCH_UPDATE_COMPANY } from '../actionTypes';

const initialState = {
    companies: [],
    isFetched: false,
};

const companies = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_COMPANIES.success:
            return {
                ...state,
                companies: action.data.companies,
                isFetched: true,
            };
        case FETCH_CREATE_COMPANY.success:
            return {
                ...state,
                companies: [action.data.company, ...state.companies],
            };
        case FETCH_DELETE_COMPANY.success:
            return {
                ...state,
                companies: state.companies.filter(item => item.id !== +action.data.id),
            };
        case FETCH_UPDATE_COMPANY.success:
            return {
                ...state,
                companies: state.companies.map(item => {
                    if (item.id === action.data.company.id) {
                        return {
                            ...action.data.company,
                        };
                    }

                    return item;
                }),
            };
        case CLEAR_VIEW_DATA:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default companies;
