import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const BluredMarker = forwardRef(
  ({ color = 'currentColor', width = 24, height = 28, ...rest }, ref) => (
    <svg
      {...rest}
      width={width}
      height={height}
      viewBox="-2 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      stroke={color}
    >
      <g filter="url(#filter0_b_3423_6763)">
        <path
          d="M0 8.09121C0 7.74723 0.176784 7.42741 0.468051 7.24443L11.4681 0.334173C11.7933 0.129875 12.2067 0.129875 12.5319 0.334173L23.532 7.24443C23.8232 7.42741 24 7.74723 24 8.09121V19.9088C24 20.2528 23.8232 20.5726 23.5319 20.7556L12.5319 27.6658C12.2067 27.8701 11.7933 27.8701 11.4681 27.6658L0.46805 20.7556C0.176783 20.5726 0 20.2528 0 19.9088V8.09121Z"
          fill="#424242"
          fillOpacity="0.8"
        />
        <path
          d="M23 8.09121V19.9088L12 26.8191L1 19.9088L1 8.09121L12 1.18095L23 8.09121Z"
          stroke="#EEEEEE"
          strokeOpacity="0.8"
          strokeWidth="2"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_3423_6763"
          x="-4"
          y="-4"
          width="32"
          height="36"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_3423_6763" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_3423_6763"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
);

BluredMarker.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

BluredMarker.displayName = 'BluredMarker';

export default BluredMarker;
