import {
    FETCH_ALL_INCUBATORS,
    FETCH_CREATE_INCUBATOR,
    FETCH_DELETE_INCUBATOR,
    FETCH_UPDATE_INCUBATOR,
} from '../actionTypes';

export const fetchAllIncubators = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_ALL_INCUBATORS.default,
        url: '/incubators',
    },
});

export const fetchCreateIncubator = incubator => ({
    type: 'FETCH',
    params: {
        type: FETCH_CREATE_INCUBATOR.default,
        url: '/incubators',
        method: 'POST',
        body: {
            ...incubator,
        },
    },
});

export const fetchDeleteIncubator = id => ({
    type: 'FETCH',
    params: {
        type: FETCH_DELETE_INCUBATOR.default,
        url: `/incubators/${id}`,
        method: 'DELETE',
    },
});

export const fetchUpdateIncubator = (id, incubator) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_INCUBATOR.default,
        url: `/incubators/${id}`,
        method: 'PUT',
        body: {
            ...incubator,
        },
    },
});
