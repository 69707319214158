import { CLEAR_VIEW_DATA } from 'actionTypes';
import { FETCH_RANCH_LOCATIONS, UPDATE_RANCH_LOCATION } from '../actionTypes';

const initialState = {
    locations: [],
    isFetched: false,
};

const locations = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RANCH_LOCATIONS.success:
            return {
                ...state,
                locations: action.data,
                isFetched: true,
            };
        case UPDATE_RANCH_LOCATION.success:
            return {
                locations: state.locations.map(location =>
                    location.id === action.data?.id
                        ? {
                              ...location,
                              bhome_id: action.data?.bhome_id,
                              status: action.data.status,
                          }
                        : location
                ),
                isFetched: true,
            };
        case CLEAR_VIEW_DATA:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default locations;
