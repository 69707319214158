import { CLEAR_VIEW_DATA } from 'actionTypes';
import { FETCH_BHOME_ASSEMBLIES, FETCH_CREATE_BHOME_ASSEMBLY, FETCH_DELETE_BHOME_ASSEMBLY } from '../actionTypes';

const initialState = {
    assemblies: [],
    isFetched: false,
};

const assemblies = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BHOME_ASSEMBLIES.success:
            return {
                ...state,
                assemblies: action.data.assemblies,
                isFetched: true,
            };
        case FETCH_CREATE_BHOME_ASSEMBLY.success:
            return {
                ...state,
                assemblies: [...state.assemblies, action.data.assembly],
            };
        case FETCH_DELETE_BHOME_ASSEMBLY.success:
            return {
                ...state,
                assemblies: state.assemblies.filter(item => item.id !== action.data.id),
            };
        case CLEAR_VIEW_DATA:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default assemblies;
