import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { TYPES } from './utils';

export const BaseInput = forwardRef(({ multiline, type, size, active, error, ...props }, ref) => {
  const Component = multiline ? 'textarea' : 'input';
  const inputType = type === TYPES.PERCENTAGE ? TYPES.TEXT : type;

  return (
    <Component
      ref={ref}
      type={inputType}
      className={cn('text-field', `text-field--${size}`, {
        'text-field--active': active,
        'text-field--error': error
      })}
      {...props}
    />
  );
});

BaseInput.propTypes = {
  multiline: PropTypes.bool,
  type: PropTypes.string,
  size: PropTypes.string,
  active: PropTypes.bool,
  error: PropTypes.bool
};

export default BaseInput;
