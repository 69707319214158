import {
    FETCH_UPDATE_COMPANY_TYPE,
    FETCH_DELETE_COMPANY_TYPE,
    FETCH_CREATE_COMPANY_TYPE,
    FETCH_COMPANY_TYPES,
} from '../actionTypes';

export const fetchCompanyTypes = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_COMPANY_TYPES.default,
        url: '/companies/types',
    },
});

export const fetchCreateCompanyType = type => ({
    type: 'FETCH',
    params: {
        type: FETCH_CREATE_COMPANY_TYPE.default,
        url: '/companies/types',
        method: 'POST',
        body: {
            type,
        },
    },
});

export const fetchDeleteCompanyType = id => ({
    type: 'FETCH',
    params: {
        type: FETCH_DELETE_COMPANY_TYPE.default,
        url: `/companies/types/${id}`,
        method: 'DELETE',
    },
});

export const fetchUpdateCompanyType = (id, type) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_COMPANY_TYPE.default,
        url: `/companies/types/${id}`,
        method: 'PUT',
        body: {
            type,
        },
    },
});
