import React from 'react';
import { isBoolean } from 'lodash-es';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import useSuperViewer from 'utils/useSuperViewer';

const SaveNewItem = ({ value, handleSave }) => {
    const isAvailable = !useSuperViewer();

    if (!isAvailable) {
        return null;
    }

    if (!isBoolean(value)) {
        return '';
    }

    return <Button onClick={handleSave}>Save</Button>;
};

SaveNewItem.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    handleSave: PropTypes.func,
};

export default SaveNewItem;
