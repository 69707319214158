import { keyBy } from 'lodash-es';
import { CLEAR_VIEW_DATA } from 'actionTypes';
import { FETCH_RANCH_ORDERS, UPDATE_RANCH_ORDERS } from '../actionTypes';

const initialState = {
    orders: [],
    isFetched: false,
};

const orders = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RANCH_ORDERS.success:
            return {
                ...state,
                orders: action.data,
                isFetched: true,
            };
        case UPDATE_RANCH_ORDERS.success:
            const ordersById = keyBy(action.data, 'id');
            return {
                orders: state.orders.map(order =>
                    ordersById[order.id]
                        ? {
                              ...order,
                              status: ordersById[order.id].status,
                              delivery_date: ordersById[order.id].delivery_date,
                              exit_date: ordersById[order.id].exit_date,
                          }
                        : order
                ),
                isFetched: true,
            };
        case CLEAR_VIEW_DATA:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default orders;
