import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@beewise/checkbox-field';
import FormField from '../FormField';

const CheckboxFormField = React.forwardRef(({ ...props }, ref) => (
  <FormField {...props} Component={Checkbox} ref={ref} />
));

CheckboxFormField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape().isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func
};

export default CheckboxFormField;
