export const SIZES = {
  NORMAL: 'normal',
  SMALL: 'small',
  XSMALL: 'xsmall'
};

export const SIZE_VALUES = {
  [SIZES.NORMAL]: 48,
  [SIZES.SMALL]: 40,
  [SIZES.XSMALL]: 30
};

export const TYPES = {
  TEXT: 'text',
  PASSWORD: 'password',
  PERCENTAGE: 'percentage',
  FILE: 'file'
};

export const POSITIONS = {
  START: 'start',
  END: 'end'
};

export const getFieldValue = (type, e) =>
  type === TYPES.FILE ? e.target.files?.[0] : e.target.value;
export const getInitialActiveState = ({ value, inputRef }) =>
  (value !== undefined && value !== null && value !== '') || inputRef.current?.value;

export const getDisplayValue = ({ type, value }) => {
  switch (type) {
    case TYPES.PERCENTAGE:
      return `${value}%`;
    case TYPES.FILE:
      return value?.fileName;
    default:
      return value;
  }
};

export const getCurrentInputType = ({ type, isPasswordShown }) => {
  if (type === TYPES.PASSWORD && isPasswordShown) {
    return TYPES.TEXT;
  }
  return type;
};

export const getIsWithIcon = ({ type, inputProps }) =>
  type === TYPES.PASSWORD || inputProps?.component;
