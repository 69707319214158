import React from 'react';
import PropTypes from 'prop-types';
import './Tab.scss';

const TabPane = ({ children }) => <div>{children}</div>;

TabPane.propTypes = {
  children: PropTypes.node.isRequired
};

export { TabPane };
