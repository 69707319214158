import {
    FETCH_BHOMES,
    FETCH_CREATE_BHOME,
    FETCH_UPDATE_BHOME,
    FETCH_DELETE_BHOME,
    FETCH_BHOME_CERTIFICATES,
} from '../actionTypes';

export const fetchBhomes = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_BHOMES.default,
        url: '/bhomes/bhomes',
    },
});

export const fetchCreateBhome = bhome => ({
    type: FETCH_CREATE_BHOME,
    data: { bhome },
});

export const fetchUpdateBhome = (id, updateBody) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_BHOME.default,
        url: `/bhomes/${id}/update`,
        method: 'PUT',
        body: {
            updateBody,
        },
    },
});

export const fetchDeleteBhome = id => ({
    type: FETCH_DELETE_BHOME,
    data: { id },
});

export const fetchBhomeCertificates = (id, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_BHOME_CERTIFICATES.default,
        url: `/bhomes/iot/${id}`,
        resolver,
    },
});
