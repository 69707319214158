/* eslint-disable camelcase */
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loading, arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import Grid from 'components/reusables/Grid';
import { clearViewData } from 'actions';
import { fetchCompaniesTypes, fetchCreateCompaniesTypes, fetchDeleteCompaniesTypes } from './actions';
import { FETCH_COMPANIES_TYPES, FETCH_CREATE_COMPANIES_TYPES, FETCH_DELETE_COMPANIES_TYPES } from './actionTypes';

const CompaniesTypes = () => {
    const [, setGridApi] = useState(null);

    const dispatch = useDispatch();

    const companiesTypes = useSelector(state => state.companiesTypes.types, arrayOfObjectsShallowEqual);

    useEffect(() => {
        dispatch(fetchCompaniesTypes());

        return () => dispatch(clearViewData());
    }, [dispatch]);

    const handleOnGridReady = useCallback(gridApi => {
        setGridApi(gridApi);
    }, []);

    const handleNewItemSave = useCallback(
        ({ company_id, company_type_id }) => {
            dispatch(fetchCreateCompaniesTypes(company_id, company_type_id));
        },
        [dispatch]
    );

    const handleItemDelete = useCallback(
        ({ company_id, company_type_id }) => dispatch(fetchDeleteCompaniesTypes(company_id, company_type_id)),
        [dispatch]
    );

    const gridOptions = useMemo(
        () => ({
            columnDefs: [
                {
                    headerName: 'Company ID',
                    field: 'company_id',
                    editable: params => params.data.addMode,
                    cellRenderer: 'link',
                    cellRendererParams: {
                        url: '/companies',
                    },
                },
                {
                    headerName: 'Company Type ID',
                    field: 'company_type_id',
                    editable: params => params.data.addMode,
                    cellRenderer: 'link',
                    cellRendererParams: {
                        url: '/company-type',
                    },
                },
            ],
        }),
        []
    );

    return (
        <div id="view" className="page companies-types">
            <Grid
                onGridReady={handleOnGridReady}
                rowData={companiesTypes}
                gridOptions={gridOptions}
                handleNewItemSave={handleNewItemSave}
                handleItemDelete={handleItemDelete}
            />
        </div>
    );
};

export default loading([
    FETCH_COMPANIES_TYPES.default,
    FETCH_CREATE_COMPANIES_TYPES.default,
    FETCH_DELETE_COMPANIES_TYPES.default,
])(CompaniesTypes);
