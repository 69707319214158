import { CLEAR_VIEW_DATA } from 'actionTypes';
import { FETCH_ASSEMBLIES, FETCH_CREATE_ASSEMBLY, FETCH_UPDATE_ASSEMBLY, FETCH_DELETE_ASSEMBLY } from '../actionTypes';

const initialState = {
    assemblies: [],
    isFetched: false,
};

const assemblies = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ASSEMBLIES.success:
            return {
                ...state,
                assemblies: action.data.assemblies,
                isFetched: true,
            };
        case FETCH_CREATE_ASSEMBLY.success:
            return {
                ...state,
                assemblies: [action.data.assembly, ...state.assemblies],
            };
        case FETCH_DELETE_ASSEMBLY.success:
            return {
                ...state,
                assemblies: state.assemblies.filter(item => item.id !== action.data.id),
            };
        case FETCH_UPDATE_ASSEMBLY.success:
            return {
                ...state,
                assemblies: state.assemblies.map(item => {
                    if (item.id === action.data.assembly.id) {
                        return {
                            ...action.data.assembly,
                        };
                    }

                    return item;
                }),
            };
        case CLEAR_VIEW_DATA:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default assemblies;
