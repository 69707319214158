import { CLEAR_VIEW_DATA } from 'actionTypes';
import { FETCH_CONFERENCE_USERS, FETCH_UPDATE_CONFERENCE_USER, FETCH_REMOVE_CONFERENCE_USER } from './actions';

const initialState = {
    contacts: [],
    isFetched: false,
};

const companyUsers = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CONFERENCE_USERS.success:
            return {
                ...state,
                contacts: action.data,
                isFetched: true,
            };
        case FETCH_REMOVE_CONFERENCE_USER.success:
            return {
                ...state,
                contacts: state.contacts.filter(item => item.id !== action.data.id),
            };
        case FETCH_UPDATE_CONFERENCE_USER:
            return {
                ...state,
                contacts: state.contacts.map(contact =>
                    contact.id === action.data.id ? { ...contact, ...action.data } : contact
                ),
            };
        case CLEAR_VIEW_DATA:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default companyUsers;
