import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const BeehomeInverted = forwardRef(({ color = '#616161', width = 18, height = 18 }, ref) => (
  <svg
    ref={ref}
    width={width}
    height={height}
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7844 0.609303C10.735 0.540942 10.6527 0.499997 10.5647 0.5L1.6153 0.500281C1.53359 0.500284 1.45644 0.535617 1.40617 0.596059L0.330077 1.88991C0.194137 2.05336 0.318261 2.29198 0.53922 2.29197L11.499 2.29173C11.7117 2.29172 11.8379 2.06848 11.7186 1.90319L10.7844 0.609303ZM2.46632 1.12231C2.34422 1.12231 2.24525 1.21518 2.24525 1.32974C2.24525 1.4443 2.34422 1.53718 2.46632 1.53718H9.54054C9.66264 1.53718 9.76161 1.4443 9.76161 1.32974C9.76161 1.21518 9.66264 1.12231 9.54054 1.12231H2.46632Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.608 6.87151C10.608 6.73401 10.4892 6.62253 10.3427 6.62253H1.67677C1.53027 6.62253 1.41151 6.73395 1.41148 6.87141L1.41144 7.10837C1.41141 7.24582 1.29266 7.35723 1.14618 7.35724L0.265307 7.35732C0.118786 7.35733 0 7.24588 0 7.1084V2.89345C0 2.75598 0.118772 2.64453 0.265284 2.64453H11.7347C11.8812 2.64453 12 2.75598 12 2.89345V7.1084C12 7.24588 11.8812 7.35733 11.7347 7.35732L10.8732 7.35725C10.7267 7.35723 10.6079 7.24576 10.6079 7.10827L10.608 6.87151ZM2.46714 5.27119C2.34505 5.27119 2.24607 5.36406 2.24607 5.47862C2.24607 5.59318 2.34505 5.68606 2.46714 5.68606H3.05666C3.17876 5.68606 3.27773 5.59318 3.27773 5.47862C3.27773 5.36406 3.17876 5.27119 3.05666 5.27119H2.46714ZM4.2357 5.27119C4.11361 5.27119 4.01463 5.36406 4.01463 5.47862C4.01463 5.59318 4.11361 5.68606 4.2357 5.68606H5.41474C5.53683 5.68606 5.63581 5.59318 5.63581 5.47862C5.63581 5.36406 5.53683 5.27119 5.41474 5.27119H4.2357ZM6.59378 5.27119C6.47168 5.27119 6.37271 5.36406 6.37271 5.47862C6.37271 5.59318 6.47168 5.68606 6.59378 5.68606H7.77281C7.89491 5.68606 7.99388 5.59318 7.99388 5.47862C7.99388 5.36406 7.89491 5.27119 7.77281 5.27119H6.59378ZM8.95185 5.27119C8.82976 5.27119 8.73078 5.36406 8.73078 5.47862C8.73078 5.59318 8.82976 5.68606 8.95185 5.68606H9.54137C9.66346 5.68606 9.76244 5.59318 9.76244 5.47862C9.76244 5.36406 9.66346 5.27119 9.54137 5.27119H8.95185Z"
      fill={color}
    />
  </svg>
));

BeehomeInverted.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

export default BeehomeInverted;
