import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Installer = forwardRef(({ color = '#111111', width = 20, height = 20, ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    {...rest}
  >
    <path
      d="M9.5 7.75V2.5M9.5 2.5L11.75 4.75M9.5 2.5L7.25 4.75M3.5 9.625V17.125H15.5V9.625M3.5 9.625L2 7.75M3.5 9.625H8M15.5 9.625L17 7.75M15.5 9.625H11M8 9.625V13.75L9.5 12.25L11 13.75V9.625M8 9.625H11"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

Installer.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

Installer.displayName = 'Installer';

export default Installer;
