import {
    FETCH_LOCATION_TYPES,
    FETCH_CREATE_LOCATION_TYPE,
    FETCH_UPDATE_LOCATION_TYPE,
    FETCH_DELETE_LOCATION_TYPE,
} from '../actionTypes';

export const fetchLocationTypes = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_LOCATION_TYPES.default,
        url: '/companies/location-types',
    },
});

export const fetchCreateLocationType = type => ({
    type: 'FETCH',
    params: {
        type: FETCH_CREATE_LOCATION_TYPE.default,
        url: '/companies/location-types',
        method: 'POST',
        body: {
            type,
        },
    },
});

export const fetchUpdateLocationType = (id, type) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_LOCATION_TYPE.default,
        url: `/companies/location-types/${id}`,
        method: 'PUT',
        body: {
            type,
        },
    },
});

export const fetchDeleteLocationType = id => ({
    type: 'FETCH',
    params: {
        type: FETCH_DELETE_LOCATION_TYPE.default,
        url: `/companies/location-types/${id}`,
        method: 'DELETE',
    },
});
