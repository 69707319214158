/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, omit } from 'lodash-es';
import { loading, arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { clearViewData } from 'actions';
import Grid from 'components/reusables/Grid';
import Icon from '@beewise/icon';
import PropTypes from 'prop-types';
import { fetchBhomes, fetchCreateBhome, fetchUpdateBhome, fetchDeleteBhome, fetchBhomeCertificates } from './actions';
import { FETCH_BHOMES, FETCH_CREATE_BHOME, FETCH_UPDATE_BHOME, FETCH_DELETE_BHOME } from './actionTypes';
import { fetchLocations } from '../Locations/actions';
import { fetchBhomeModels } from '../BhomeModels/actions';

const downloadFile =
    bhomeId =>
    ([key, value]) => {
        const link = document.createElement('a');
        const file = new Blob([value], { type: 'text/plain' });
        link.href = URL.createObjectURL(file);
        const extension = key.includes('key') ? 'key' : 'crt';
        link.download = `${key}-${bhomeId}.pem.${extension}`;
        link.click();
    };

const DownloadCertificates = ({ onClick, data: { id } }) => (
    <Icon className="icon-download" type="arrow-move" onClick={() => onClick(id)}>
        Download{' '}
    </Icon>
);

DownloadCertificates.propTypes = {
    onClick: PropTypes.func,
    data: PropTypes.shape({ id: PropTypes.number }),
};

const Bhomes = () => {
    const [, setGridApi] = useState(null);
    const dispatch = useDispatch();

    const bhomes = useSelector(state => state.bhomes.bhomes, arrayOfObjectsShallowEqual);
    const isFetched = useSelector(state => state.bhomes.isFetched);
    const locations = useSelector(state => state.locations.locations, arrayOfObjectsShallowEqual);
    const areLocationsFetched = useSelector(state => state.locations.isFetched);
    const models = useSelector(state => state.bhomeModels.models, arrayOfObjectsShallowEqual);
    const areModelsFetched = useSelector(state => state.bhomeModels.isFetched);

    useEffect(() => {
        dispatch(fetchBhomes());
        dispatch(fetchLocations());
        dispatch(fetchBhomeModels());

        return () => dispatch(clearViewData());
    }, [dispatch]);

    const handleOnGridReady = useCallback(gridApi => {
        setGridApi(gridApi);
    }, []);

    const handleNewItemSave = useCallback(
        newItem => {
            const { id, location_id, model_id, ...rest } = newItem;
            const locationId = get(
                locations.find(location => location.name === location_id),
                'id',
                null
            );
            const modelId = get(
                models.find(model => model.name === model_id),
                'id',
                null
            );

            dispatch(
                fetchCreateBhome({
                    ...rest,
                    location_id: locationId,
                    model_id: modelId,
                })
            );
        },
        [dispatch, locations, models]
    );

    const handleItemDelete = useCallback(data => dispatch(fetchDeleteBhome(data.id)), [dispatch]);

    const onCellChange = useCallback(
        ({ data }) => {
            const { id, location_id, model_id, software_version, ...rest } = data;

            dispatch(
                fetchUpdateBhome(id, {
                    ...rest,
                    location_id: get(
                        locations.find(item => item.id === location_id || item.name === location_id),
                        'id',
                        null
                    ),
                    model_id: get(
                        models.find(item => item.id === model_id || item.name === model_id),
                        'id',
                        null
                    ),
                })
            );
        },
        [dispatch, locations, models]
    );

    const downloadCertificates = useCallback(
        id => {
            const resolver = data => {
                if (data?.private_key) {
                    Object.entries(omit(data, ['bhome_id', 'public_key'])).forEach(downloadFile(id));
                }
            };
            dispatch(fetchBhomeCertificates(id, resolver));
        },
        [dispatch]
    );
    const gridOptions = useMemo(
        () => ({
            columnDefs: [
                { headerName: 'ID', field: 'id', editable: false },
                {
                    headerName: 'Last updated',
                    field: 'last_updated',
                    editable: false,
                },
                {
                    headerName: 'Data',
                    field: 'data',
                    editable: true,
                    valueGetter: params => JSON.stringify(params.data.data),
                },
                { headerName: 'Status', field: 'status', editable: true },
                {
                    headerName: 'Live stream',
                    field: 'live_stream',
                    editable: true,
                },
                {
                    headerName: 'Location ID',
                    field: 'location_id',
                    cellRenderer: 'link',
                    cellRendererParams: {
                        url: '/locations',
                    },
                    cellEditor: 'agSelectCellEditor',
                    editable: !!locations.length,
                    cellEditorParams: {
                        values: [null, ...locations.map(item => item.name)],
                    },
                    valueGetter: params =>
                        params.data.addMode
                            ? params.data.location_id
                            : get(
                                  locations.find(location => location.id === params.data.location_id),
                                  'name'
                              ),
                },
                {
                    headerName: 'Sensors',
                    field: 'sensors',
                    editable: true,
                    valueGetter: params => JSON.stringify(params.data.sensors),
                },
                {
                    headerName: 'GPS',
                    field: 'gps',
                    editable: true,
                    valueGetter: params => JSON.stringify(params.data.gps),
                },
                { headerName: 'Version', field: 'version', editable: true },
                {
                    headerName: 'Software version',
                    field: 'software_version',
                    editable: false,
                },
                {
                    headerName: 'Manufacturing date',
                    field: 'manufacturing_date',
                    editable: false,
                },
                {
                    headerName: 'Model ID',
                    field: 'model_id',
                    cellRenderer: 'link',
                    cellRendererParams: {
                        url: '/bhome-models',
                    },
                    cellEditor: 'agSelectCellEditor',
                    editable: !!models.length,
                    cellEditorParams: {
                        values: [null, ...models.map(item => item.name)],
                    },
                    valueGetter: params =>
                        params.data.addMode
                            ? params.data.model_id
                            : get(
                                  models.find(model => model.id === params.data.model_id),
                                  'name'
                              ),
                },
                {
                    headerName: 'Hive configuration',
                    field: 'hive_configuration',
                    editable: true,
                },
                {
                    headerName: 'Certificates',
                    sortable: false,
                    editable: false,
                    field: 'download',
                    cellRenderer: 'downloadButton',
                    cellRendererParams: {
                        onClick: downloadCertificates,
                    },
                },
            ],
        }),
        [locations, models, downloadCertificates]
    );

    const canRenderGrid = useMemo(
        () => !!(isFetched && areLocationsFetched && areModelsFetched),
        [isFetched, areLocationsFetched, areModelsFetched]
    );

    return (
        <div id="view" className="page bhomes">
            {canRenderGrid && (
                <Grid
                    components={{
                        downloadButton: DownloadCertificates,
                    }}
                    onGridReady={handleOnGridReady}
                    rowData={bhomes}
                    gridOptions={gridOptions}
                    handleNewItemSave={handleNewItemSave}
                    handleItemDelete={handleItemDelete}
                    onCellChange={onCellChange}
                />
            )}
        </div>
    );
};

export default loading([FETCH_BHOMES.default, FETCH_CREATE_BHOME, FETCH_UPDATE_BHOME.default, FETCH_DELETE_BHOME])(
    Bhomes
);
