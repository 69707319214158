import React from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

const getError = (error) => {
  switch (error.type) {
    case 'required':
      return 'This field is required';
    case 'pattern':
    case 'min':
    case 'max':
    case 'minLength':
    case 'maxLength':
    case 'manual':
    case 'validate':
      return error.message ? error.message : 'This field is invalid';
    default:
      return 'This field is invalid';
  }
};

const FormField = React.forwardRef(
  (
    {
      name,
      control,
      required,
      Component,
      pattern,
      min,
      max,
      minLength,
      maxLength,
      validate,
      onChange,
      ...props
    },
    ref
  ) => {
    const {
      field,
      fieldState: { error }
    } = useController({
      name,
      control,
      rules: {
        required,
        pattern,
        min,
        max,
        minLength,
        maxLength,
        validate
      }
    });

    const handleChange = (value, ...rest) => {
      if (onChange) {
        onChange(value, ...rest);
      }
      field.onChange(value);
    };

    const errorMessage = error ? getError(error) : null;

    return (
      <Component
        ref={ref}
        value={field.value}
        onChange={handleChange}
        name={name}
        error={errorMessage}
        required={required}
        {...props}
      />
    );
  }
);

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape().isRequired,
  required: PropTypes.bool,
  Component: PropTypes.elementType,
  pattern: PropTypes.shape({ value: PropTypes.instanceOf(RegExp), message: PropTypes.string }),
  min: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({ value: PropTypes.number, message: PropTypes.string })
  ]),
  max: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({ value: PropTypes.number, message: PropTypes.string })
  ]),
  minLength: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({ value: PropTypes.number, message: PropTypes.string })
  ]),
  maxLength: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({ value: PropTypes.number, message: PropTypes.string })
  ]),
  validate: PropTypes.func,
  onChange: PropTypes.func
};

export default FormField;
