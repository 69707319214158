import { FETCH_WEATHER_DATA, AGGREGATE_WEATHER_DATA, SAVE_WEATHER_DATA } from '../actionTypes';

export const aggregateWeatherData = (id, from, to, resolver) => ({
    type: 'FETCH',
    params: {
        type: AGGREGATE_WEATHER_DATA.default,
        url: `/weather/${id}/aggregate?from=${from}&to=${to}`,
        resolver,
    },
});

export const fetchWeatherData = (id, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_WEATHER_DATA.default,
        url: `/weather/${id}`,
        resolver,
    },
});

export const saveWeatherData = (id, body) => ({
    type: 'FETCH',
    params: {
        method: 'POST',
        type: SAVE_WEATHER_DATA.default,
        url: `/weather/${id}`,
        body,
    },
});
