import { CLEAR_VIEW_DATA } from 'actionTypes';
import { FETCH_COMPANY_BHOMES, FETCH_CREATE_COMPANY_BHOME, FETCH_DELETE_COMPANY_BHOME } from '../actionTypes';

const initialState = {
    bhomes: [],
    isFetched: false,
};

const companyBhomes = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_COMPANY_BHOMES.success:
            return {
                ...state,
                bhomes: action.data.bhomes.map((item, index) => ({
                    ...item,
                    id: index,
                })),
                isFetched: true,
            };
        case FETCH_CREATE_COMPANY_BHOME.success:
            return {
                ...state,
                bhomes: [action.data.bhome, ...state.bhomes].map((item, index) => ({
                    ...item,
                    id: index,
                })),
            };
        case FETCH_DELETE_COMPANY_BHOME.success:
            return {
                ...state,
                bhomes: state.bhomes.filter(
                    item => item.company_id !== action.data.companyId || item.bhome_id !== action.data.bhomeId
                ),
            };
        case CLEAR_VIEW_DATA:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default companyBhomes;
