import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ReactDOM from 'react-dom';
import { noop } from 'lodash-es';
import Button from '@beewise/button';
import './Modal.scss';

class Modal extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {};
    this.container = document.createElement('div');
    this.parent = document.body;
  }

  componentDidMount() {
    this.parent =
      this.props.appendTo && document.querySelector(this.props.appendTo)
        ? document.querySelector(this.props.appendTo)
        : document.body;

    this.parent.appendChild(this.container);
  }

  componentWillUnmount() {
    this.parent.removeChild(this.container);
  }

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  closeButton = () => {
    if (this.props.suppressClose) {
      return null;
    }

    return (
      <Button
        iconType="x"
        iconClassName="modal-close-icon"
        onClick={this.props.onClose}
        className="btn modal-close"
      />
    );
  };

  render() {
    const {
      onOpen,
      onClose,
      children,
      className,
      header,
      isOpen,
      suppressClose,
      suppressCoverClose,
      contentClassName,
      footerComponent,
      isPurple,
      size
    } = this.props;

    if (!isOpen) {
      return null;
    }

    (onOpen || noop)();

    return ReactDOM.createPortal(
      <div
        className={cx('modal-wrapper', {
          [className]: !!className
        })}
        onClick={suppressClose || suppressCoverClose ? noop : onClose}
        role="presentation"
      >
        <div
          className={cx('modal-content', {
            'modal-content-with-header': !!header,
            [contentClassName]: contentClassName,
            'modal-content-purple': isPurple,
            [`modal-${size}`]: !!size
          })}
          onClick={this.stopPropagation}
          role="presentation"
        >
          {header && (
            <header className="modal-header">
              <h2 className="modal-header__text">{header}</h2>
              {this.closeButton()}
            </header>
          )}
          <div className="modal-body">{children}</div>
          {footerComponent && <div className="modal-footer">{footerComponent}</div>}
        </div>
      </div>,
      this.container
    );
  }
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  className: PropTypes.string,
  header: PropTypes.string.isRequired,
  appendTo: PropTypes.string,
  children: PropTypes.node,
  suppressClose: PropTypes.bool,
  suppressCoverClose: PropTypes.bool,
  contentClassName: PropTypes.string,
  footerComponent: PropTypes.node,
  isPurple: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'extra-large'])
};

Modal.defaultProps = {
  appendTo: '#app'
};

export default Modal;
