import { keyBy } from 'lodash-es';
import StatusRenderer from './BhomeSoftwareUpdate/StatusRenderer';
import NoteRenderer from './BhomeSoftwareUpdate/NoteRenderer';

export const gridOptions = {
    columnDefs: [
        { headerName: '#', valueGetter: 'node.rowIndex + 1', sortable: false, suppressMenu: true },
        {
            headerName: 'Id',
            field: 'id',
            editable: false,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
        },
        { headerName: 'status', field: 'status', editable: false },
        { headerName: 'Ranch', field: 'ranch', editable: false },
        { headerName: 'Yard', field: 'yardName', editable: false },
        { headerName: 'MW version', field: 'mw', editable: false },
        { headerName: 'BH type', field: 'bhType', editable: false },
        { headerName: 'Online', field: 'isOnline', editable: false, cellRenderer: StatusRenderer },
        { headerName: 'Note', field: 'note', editable: false, cellRenderer: NoteRenderer },
    ],
    columnTypes: {
        nonEditableColumn: { editable: false },
    },
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
};

export const gridBhomeSoftwareUpdateOptions = {
    columnDefs: [
        { headerName: '#', valueGetter: 'node.rowIndex + 1', sortable: false, suppressMenu: true },
        {
            headerName: 'Id',
            field: 'id',
            editable: false,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
        },
        { headerName: 'MW version', field: 'mw', editable: false },
        { headerName: 'FW versions', field: 'fw', editable: false },
        { headerName: 'Vision version', field: 'vv', editable: false },
        { headerName: 'Company', field: 'companyName', editable: false },
        { headerName: 'Region', field: 'region', editable: false },
        {
            field: 'yardName',
            headerName: 'Yard/Ranch',
            flex: 1.2,
            valueGetter: params => {
                const values = [];
                if (params.data.yardName) {
                    values.push(params.data.yardName);
                }
                if (params.data.ranch) {
                    values.push(params.data.ranch);
                }
                return values.join('/') || '-';
            },
        },
    ],
    columnTypes: {
        nonEditableColumn: { editable: false },
    },
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
};

export const gridFullOptions = {
    columnDefs: [
        { headerName: '#', valueGetter: 'node.rowIndex + 1', sortable: false, suppressMenu: true },
        {
            headerName: 'Id',
            field: 'id',
            editable: false,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
        },
        { headerName: 'Status', field: 'status', editable: false },
        { headerName: 'Region', field: 'region', editable: false },
        { headerName: 'Ranch', field: 'ranch', editable: false },
        { headerName: 'Yard', field: 'yardName', editable: false },
        { headerName: 'MW version', field: 'mw', editable: false },
        { headerName: 'BH type', field: 'bhType', editable: false },
        { headerName: 'Online', field: 'isOnline', editable: false, cellRenderer: StatusRenderer },
        { headerName: 'Note', field: 'note', editable: false, cellRenderer: NoteRenderer },
        { headerName: 'Feeding', field: 'feeding', editable: false },
        { headerName: 'Auto Scan', field: 'autoScan', editable: false },
        { headerName: 'Auto Count Bees', field: 'autoCountBees', editable: false },
        { headerName: 'Visual Inspection Type', field: 'visualInspectionType', editable: false },
        { headerName: 'Visit status', field: 'visitStatus', editable: false },
        { headerName: 'Transportation status', field: 'transportationStatus', editable: false },
    ],
    columnTypes: {
        nonEditableColumn: { editable: false },
    },
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
};

export const ALL_VERSIONS = 'All';

const pattern = /v(\d+\.\d+)/;

const isMatchingVersion = (configuration, settings, pattern) =>
    configuration?.middleware_version?.match(pattern) ||
    settings?.firmware_version?.match(pattern) ||
    settings?.middleware_version?.match(pattern);

export const getBhomeOptions = ({ bhomes, bhomeVersion, connectivityMap = {} }) => {
    const connectivityByKeys = keyBy(connectivityMap, 'id');
    return bhomes.reduce((acc, bhome) => {
        const isOnline = connectivityByKeys[bhome.id]?.connected;
        const settings = bhome.settings?.machine_specific?.general_settings || bhome.settings?.general;
        const { configuration } = bhome;
        const match = isMatchingVersion(configuration, settings, pattern);
        const bhType = settings?.beehome_type || match?.[1];

        const isSameVersion = bhType === bhomeVersion || bhomeVersion === ALL_VERSIONS;

        if (isSameVersion) {
            acc.push({
                value: bhome.id,
                label: bhome.id,
                data: {
                    id: bhome.id,
                    status: bhome.status,
                    ranch: bhome.ranch_name,
                    mw: configuration?.middleware_version || settings?.middleware_version,
                    fw: settings?.firmware_version,
                    yardName: bhome.yard_name,
                    bhType,
                    isOnline,
                    note: bhome.note,
                    companyName: bhome.company_name,
                    region: bhome.region_name || bhome.coordsState || '-',
                    vv: settings?.camera_server_version,
                },
            });
        }
        return acc;
    }, []);
};

export const getFullBhomeOptions = ({ bhomes, bhomeVersion, connectivityMap = {} }) => {
    const connectivityByKeys = keyBy(connectivityMap, 'id');
    return bhomes.reduce((acc, bhome) => {
        const isOnline = connectivityByKeys[bhome.id]?.connected;
        const settings = bhome.settings?.machine_specific?.general_settings || bhome.settings?.general;
        const uiSettings = bhome.settings?.ui?.activity_manager;
        const { configuration } = bhome;
        const match = isMatchingVersion(configuration, settings, pattern);
        const bhType = settings?.beehome_type || match?.[1];

        const isSameVersion = bhType === bhomeVersion || bhomeVersion === ALL_VERSIONS;
        if (isSameVersion) {
            acc.push({
                value: bhome.id,
                label: bhome.id,
                data: {
                    id: bhome.id,
                    status: bhome.status,
                    ranch: bhome.ranch_name,
                    mw: configuration?.middleware_version || settings?.middleware_version,
                    fw: settings?.firmware_version,
                    yardName: bhome.yard_name,
                    bhType,
                    isOnline,
                    note: bhome.note,
                    feeding: uiSettings?.auto_feed_policy
                        ? `${uiSettings?.auto_feed_policy?.auto_feed_enabled} /
                    ${uiSettings?.auto_feed_policy?.auto_feed_cycle_days}`
                        : '',
                    autoScan: uiSettings?.auto_scan_policy
                        ? `${uiSettings?.auto_scan_policy?.auto_scan_enabled} /
                        ${uiSettings?.auto_scan_policy?.auto_scan_cycle_days}`
                        : '',
                    autoCountBees: uiSettings?.auto_count_bees_policy
                        ? `${uiSettings?.auto_count_bees_policy?.auto_count_bees_enabled} /
                    ${uiSettings?.auto_count_bees_policy?.auto_count_bees_cycle_days}`
                        : '',
                    visualInspectionType: uiSettings?.visual_inspection_type,
                    visitStatus: bhome.mode.visit,
                    transportationStatus: bhome.mode.transportation,
                    region: bhome?.region_name || bhome?.coordsState || '-',
                },
            });
        }
        return acc;
    }, []);
};
