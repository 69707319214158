import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loading, arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import Grid from 'components/reusables/Grid';
import { clearViewData } from 'actions';
import { fetchBhomeAssemblies, fetchCreateBhomeAssembly, fetchDeleteBhomeAssembly } from './actions';
import { FETCH_BHOME_ASSEMBLIES, FETCH_CREATE_BHOME_ASSEMBLY, FETCH_DELETE_BHOME_ASSEMBLY } from './actionTypes';
import { fetchAssemblies } from '../Assemblies/actions';
import { fetchBhomes } from '../Bhomes/actions';

const BhomeAssemblies = () => {
    const [, setGridApi] = useState(null);

    const dispatch = useDispatch();

    const bhomeAssemblies = useSelector(state => state.bhomeAssemblies.assemblies, arrayOfObjectsShallowEqual);
    const isFetched = useSelector(state => state.bhomeAssemblies.isFetched);
    const allAssemblies = useSelector(state => state.assemblies.assemblies, arrayOfObjectsShallowEqual);
    const areAssembliesFetched = useSelector(state => state.assemblies.isFetched);
    const bhomes = useSelector(state => state.bhomes.bhomes, arrayOfObjectsShallowEqual);
    const areBhomesFetched = useSelector(state => state.bhomes.isFetched);

    useEffect(() => {
        dispatch(fetchBhomeAssemblies());
        dispatch(fetchAssemblies());
        dispatch(fetchBhomes());

        return () => dispatch(clearViewData());
    }, [dispatch]);

    const handleOnGridReady = useCallback(gridApi => {
        setGridApi(gridApi);
    }, []);

    const handleNewItemSave = useCallback(
        newItem => {
            dispatch(fetchCreateBhomeAssembly(newItem));
        },
        [dispatch]
    );

    const handleItemDelete = useCallback(data => dispatch(fetchDeleteBhomeAssembly(data.id)), [dispatch]);

    const gridOptions = useMemo(
        () => ({
            columnDefs: [
                { headerName: 'ID', field: 'id', editable: false },
                {
                    headerName: 'Assembly ID',
                    field: 'assembly_id',
                    cellRenderer: 'link',
                    cellRendererParams: {
                        url: '/assemblies',
                    },
                    cellEditor: 'agSelectCellEditor',
                    editable: params => params.data.addMode && allAssemblies.length,
                    cellEditorParams: {
                        values: [null, ...allAssemblies.map(assembly => assembly.id)],
                    },
                },
                {
                    headerName: 'Bhome ID',
                    field: 'bhome_id',
                    cellRenderer: 'link',
                    cellRendererParams: {
                        url: '/bhomes',
                    },
                    cellEditor: 'agSelectCellEditor',
                    editable: params => params.data.addMode && bhomes.length,
                    cellEditorParams: {
                        values: [null, ...bhomes.map(bhome => bhome.id)],
                    },
                },
                {
                    headerName: 'Manufacturing date',
                    field: 'manufacturing_date',
                    editable: false,
                },
            ],
        }),
        [allAssemblies, bhomes]
    );

    const canRenderGrid = useMemo(
        () => !!(isFetched && areAssembliesFetched && areBhomesFetched),
        [isFetched, areAssembliesFetched, areBhomesFetched]
    );

    return (
        <div id="view" className="page bhome-assemblies">
            {canRenderGrid && (
                <Grid
                    onGridReady={handleOnGridReady}
                    rowData={bhomeAssemblies}
                    gridOptions={gridOptions}
                    handleNewItemSave={handleNewItemSave}
                    handleItemDelete={handleItemDelete}
                />
            )}
        </div>
    );
};

export default loading([
    FETCH_BHOME_ASSEMBLIES.default,
    FETCH_CREATE_BHOME_ASSEMBLY.default,
    FETCH_DELETE_BHOME_ASSEMBLY.default,
])(BhomeAssemblies);
