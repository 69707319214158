import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { noop } from 'lodash-es';
import './Tab.scss';

const Tab = ({
  panes,
  onTabChange,
  initialOpenTabIndex = 0,
  activeClassName = 'yellow-active'
}) => {
  const [activeIndex, setActiveIndex] = useState(initialOpenTabIndex);

  const handleTabClick = (index) => {
    setActiveIndex(index);
    onTabChange && onTabChange(index);
  };

  return (
    <div className="custom-tab">
      <ul className={`tab-list ${activeClassName}`}>
        {panes.map((pane, index) => (
          <li
            key={`${pane.menuItem}-${index}`}
            className={cn({ active: activeIndex === index })}
            onClick={() => handleTabClick(index)}
            onKeyDown={noop}
            role="button"
            tabIndex="0"
          >
            {pane.menuItem}
          </li>
        ))}
      </ul>
      <div className="tab-content">{panes[activeIndex]?.render()}</div>
    </div>
  );
};

Tab.propTypes = {
  panes: PropTypes.arrayOf(
    PropTypes.shape({
      menuItem: PropTypes.string.isRequired,
      render: PropTypes.func.isRequired
    })
  ).isRequired,
  onTabChange: PropTypes.func,
  initialOpenTabIndex: PropTypes.number,
  activeClassName: PropTypes.oneOf(['yellow-active', 'grey-active'])
};

export { Tab };
