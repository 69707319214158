import { FETCH_COMPANY_USERS, FETCH_CREATE_COMPANY_USER, FETCH_DELETE_COMPANY_USER } from '../actionTypes';

export const fetchCompanyUsers = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_COMPANY_USERS.default,
        url: '/users/company-users',
    },
});

export const fetchCreateCompanyUser = (companyId, userId) => ({
    type: 'FETCH',
    params: {
        type: FETCH_CREATE_COMPANY_USER.default,
        url: '/users/company-users',
        method: 'POST',
        body: {
            companyId,
            userId,
        },
    },
});

export const fetchDeleteCompanyUser = (companyId, userId) => ({
    type: 'FETCH',
    params: {
        type: FETCH_DELETE_COMPANY_USER.default,
        url: '/users/company-users',
        method: 'DELETE',
        body: {
            companyId,
            userId,
        },
    },
});
