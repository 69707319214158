/* eslint-disable camelcase */
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { loading, arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import constant from '@beewise/constants';
import Grid from 'components/reusables/Grid';
import { clearViewData } from 'actions';
import './RanchMilestones.scss';
import { FETCH_RANCH_ORDERS, UPDATE_RANCH_ORDERS } from './actionTypes';
import { fetchRanchOrders, updateRanchOrders } from './actions';

const statusOptions = [
    constant.ORDER_STATUSES.SECURED,
    constant.ORDER_STATUSES.ALLOCATED,
    constant.ORDER_STATUSES.IN_TRANSIT,
    constant.ORDER_STATUSES.DELIVERED,
];

const RanchMilestones = () => {
    const [, setGridApi] = useState(null);

    const dispatch = useDispatch();

    const ranchOrders = useSelector(state => state.ranchOrders.orders, arrayOfObjectsShallowEqual);

    const isFetched = useSelector(state => state.ranchOrders.isFetched);

    useEffect(() => {
        dispatch(fetchRanchOrders());
        return () => dispatch(clearViewData());
    }, [dispatch]);

    const handleOnGridReady = useCallback(gridApi => {
        setGridApi(gridApi);
    }, []);

    const onCellChange = useCallback(
        ({ data }) => {
            const { id, status, delivery_date, exit_date } = data;
            dispatch(updateRanchOrders([{ id, status, delivery_date, exit_date }]));
        },
        [dispatch]
    );

    const gridOptions = useMemo(
        () => ({
            columnDefs: [
                {
                    headerName: 'Company Id',
                    field: 'company_id',
                    editable: false,
                },
                {
                    headerName: 'Ranch name',
                    field: 'ranch_name',
                    editable: false,
                },
                {
                    headerName: 'Status',
                    field: 'status',
                    editable: true,
                    cellEditorParams: { values: statusOptions },
                    cellEditor: 'agSelectCellEditor',
                },
                {
                    headerName: 'Delivery date',
                    field: 'delivery_date',
                    editable: true,
                },
                { headerName: 'Exit date', field: 'exit_date', editable: true },
                {
                    headerName: 'Last updated',
                    field: 'updated_at',
                    editable: false,
                },
            ],
            columnTypes: {
                nonEditableColumn: { editable: false },
            },
        }),
        []
    );

    return (
        <div id="view" className="page ranch-locations">
            {isFetched && (
                <Grid
                    onGridReady={handleOnGridReady}
                    rowData={ranchOrders}
                    gridOptions={gridOptions}
                    onCellChange={onCellChange}
                />
            )}
        </div>
    );
};

export default loading([FETCH_RANCH_ORDERS.default, UPDATE_RANCH_ORDERS.default])(RanchMilestones);
