import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Expand = forwardRef(({ color = 'currentColor', ...rest }, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill={color}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 5.28572V0.857144C0 0.382145 0.382146 0 0.857145 0H5.28572C5.52143 0 5.71428 0.192857 5.71428 0.428575V1.85715C5.71428 2.09286 5.52143 2.28572 5.28572 2.28572H2.28572V5.28572C2.28572 5.52143 2.09286 5.71429 1.85715 5.71429H0.428576C0.192864 5.71429 6.46477e-06 5.52143 6.46477e-06 5.28572H0ZM10.2857 0.428575V1.85715C10.2857 2.09286 10.4786 2.28572 10.7143 2.28572H13.7143V5.28572C13.7143 5.52143 13.9071 5.71429 14.1429 5.71429H15.5714C15.8071 5.71429 16 5.52143 16 5.28572V0.857144C16 0.382145 15.6179 0 15.1429 0H10.7143C10.4786 0 10.2857 0.192857 10.2857 0.428575ZM15.5714 10.2857H14.1429C13.9071 10.2857 13.7143 10.4786 13.7143 10.7143V13.7143H10.7143C10.4786 13.7143 10.2857 13.9071 10.2857 14.1428V15.5714C10.2857 15.8071 10.4786 16 10.7143 16H15.1429C15.6179 16 16 15.6178 16 15.1428V10.7143C16 10.4786 15.8071 10.2857 15.5714 10.2857V10.2857ZM5.71428 15.5714V14.1429C5.71428 13.9071 5.52142 13.7143 5.28571 13.7143H2.28571V10.7143C2.28571 10.4786 2.09285 10.2857 1.85714 10.2857H0.428569C0.192857 10.2857 0 10.4786 0 10.7143V15.1429C0 15.6179 0.382146 16 0.857145 16H5.28572C5.52143 16 5.71428 15.8071 5.71428 15.5714L5.71428 15.5714Z"
    />
  </svg>
));

Expand.propTypes = {
  color: PropTypes.string
};

Expand.displayName = 'Expand';

export default Expand;
