import React from 'react';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import useSuperViewer from 'utils/useSuperViewer';

const AddHeaderComponent = ({ addNewRow }) => {
    const isAvailable = !useSuperViewer();

    if (!isAvailable) {
        return null;
    }

    return (
        <div className="add-header-component">
            <Button className="plus-button" onClick={addNewRow} iconType="plus" />
            <Button className="minus-button" onClick={addNewRow} iconType="x" />
        </div>
    );
};

AddHeaderComponent.propTypes = {
    addNewRow: PropTypes.func,
};

export default AddHeaderComponent;
