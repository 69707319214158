import { CLEAR_VIEW_DATA } from 'actionTypes';
import { FETCH_SETTINGS, FETCH_UPDATE_SETTING, FETCH_ALL_SETTINGS } from '../actionTypes';

const initialState = {
    settings: [],
    isFetched: false,
};

const settings = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SETTINGS.success:
            return {
                ...state,
                settings: [action.data.settings],
                isFetched: true,
            };
        case FETCH_ALL_SETTINGS.success:
            return {
                ...state,
                settings: action.data.settings,
                isFetched: true,
            };
        case FETCH_UPDATE_SETTING.success:
            return {
                ...state,
                settings: state.settings.map(item => {
                    if (item.id === action.data.setting.id) {
                        return {
                            ...action.data.setting,
                        };
                    }

                    return item;
                }),
            };
        case CLEAR_VIEW_DATA:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default settings;
