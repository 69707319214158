import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import SelectGridField from 'components/reusables/SelectGridField';
import { SelectFormField } from '@beewise/hook-form';
import { useForm } from 'react-hook-form';
import { gridOptions } from './utils';

const defaultValues = {
    bhomeIds: [],
    companyId: '',
};

const BhomeReassign = ({ bhomes, companies, handleBhomeCompanyChange }) => {
    const {
        control,
        handleSubmit,
        formState: { isSubmitSuccessful },
        reset,
    } = useForm({ defaultValues });

    const bhomeOptions = bhomes.map(({ id, ranch_name: ranch, yard_name: yardName }) => ({
        value: id,
        label: id,
        data: { id, ranch, yardName },
    }));

    const companyOptions = companies.map(({ id, name }) => ({ label: name, value: id }));

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset();
        }
    }, [isSubmitSuccessful, reset]);

    return (
        <div className="bhome-reassign">
            <SelectGridField
                name="bhomeIds"
                gridName="Select Bhomes"
                isMulti
                gridOptions={gridOptions}
                label="Select Bhome"
                options={bhomeOptions}
                control={control}
                required
            />
            <SelectFormField
                control={control}
                name="companyId"
                label="Pick company"
                options={companyOptions}
                required
            />
            <Button className="btn-primary" onClick={handleSubmit(handleBhomeCompanyChange)}>
                Submit
            </Button>
        </div>
    );
};

BhomeReassign.propTypes = {
    bhomes: PropTypes.arrayOf(PropTypes.shape()),
    companies: PropTypes.arrayOf(PropTypes.shape()),
    handleBhomeCompanyChange: PropTypes.func.isRequired,
};

export default BhomeReassign;
