import { CLEAR_VIEW_DATA } from 'actionTypes';
import {
    FETCH_ALL_INCUBATORS,
    FETCH_CREATE_INCUBATOR,
    FETCH_DELETE_INCUBATOR,
    FETCH_UPDATE_INCUBATOR,
} from '../actionTypes';

const initialState = {
    incubators: [],
    isFetched: false,
};

const incubators = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_INCUBATORS.success:
            return {
                ...state,
                incubators: action.data.incubators,
                isFetched: true,
            };
        case FETCH_UPDATE_INCUBATOR.success: {
            return {
                ...state,
                incubators: state.incubators.map(incubator => {
                    if (incubator.id === action.data.incubator.id) {
                        return {
                            ...incubator,
                            ...action.data.incubator,
                        };
                    }
                    return incubator;
                }),
            };
        }
        case FETCH_DELETE_INCUBATOR.success: {
            return {
                ...state,
                incubators: state.incubators.filter(incubator => incubator.id !== action.data.incubator.id),
            };
        }
        case FETCH_CREATE_INCUBATOR.success: {
            return {
                ...state,
                incubators: [...state.incubators, action.data.incubator],
            };
        }
        case CLEAR_VIEW_DATA:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default incubators;
