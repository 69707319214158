import { FETCH_COMPANIES_TYPES, FETCH_CREATE_COMPANIES_TYPES, FETCH_DELETE_COMPANIES_TYPES } from '../actionTypes';

export const fetchCompaniesTypes = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_COMPANIES_TYPES.default,
        url: '/companies/companies-types',
    },
});

export const fetchCreateCompaniesTypes = (companyId, typeId) => ({
    type: 'FETCH',
    params: {
        type: FETCH_CREATE_COMPANIES_TYPES.default,
        url: '/companies/companies-types',
        method: 'POST',
        body: {
            companyId,
            typeId,
        },
    },
});

export const fetchDeleteCompaniesTypes = (companyId, typeId) => ({
    type: 'FETCH',
    params: {
        type: FETCH_DELETE_COMPANIES_TYPES.default,
        url: '/companies/companies-types',
        method: 'DELETE',
        body: {
            companyId,
            typeId,
        },
    },
});
