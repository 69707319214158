import { FETCH_RANCH_ORDERS, UPDATE_RANCH_ORDERS } from '../actionTypes';

export const fetchRanchOrders = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_RANCH_ORDERS.default,
        url: '/ranch/orders',
    },
});

export const updateRanchOrders = orders => ({
    type: 'FETCH',
    params: {
        method: 'PATCH',
        type: UPDATE_RANCH_ORDERS.default,
        url: '/ranch/orders',
        body: orders,
    },
});
