import { createAsyncAction } from '@beewise/utils';

export const FETCH_CONFERENCE_USERS = createAsyncAction('FETCH_CONFERENCE_USERS');
export const FETCH_REMOVE_CONFERENCE_USER = createAsyncAction('FETCH_REMOVE_COMPANY_USERS');
export const FETCH_UPDATE_CONFERENCE_USER = createAsyncAction('FETCH_UPDATE_CONFERENCE_USER');

export const fetchConferenceUsers = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_CONFERENCE_USERS.default,
        url: '/conf',
    },
});

export const fetchRemoveConferenceUsers = id => ({
    type: 'FETCH',
    params: {
        type: FETCH_REMOVE_CONFERENCE_USER.default,
        url: `/conf/${id}`,
        method: 'DELETE',
    },
});

export const fetchUpdateConferenceUsers = (id, body) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_CONFERENCE_USER.default,
        url: `/conf/${id}`,
        method: 'PUT',
        body,
    },
});
