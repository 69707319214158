export const ROOT_ERRORS = {
  SERVER_ERROR: 'root.serverError',
  FORM_ERROR: 'root.formError'
};

export const getRootLevelErrors = (errors) =>
  Object.entries(errors).reduce((acc, [errorKey, errorValue]) => {
    if (errorKey === 'root') {
      acc.push(errorValue.formError?.message || errorValue.serverError?.message);
    }
    return acc;
  }, []);
