import { CLEAR_VIEW_DATA } from 'actionTypes';
import { FETCH_CREATE_PUBLIC_CODE, FETCH_DELETE_PUBLIC_CODE, FETCH_ALL_PUBLIC_CODES } from '../actionTypes';

const initialState = {
    codes: [],
    isFetched: false,
};

const publicCodes = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_PUBLIC_CODES.success:
            return {
                ...state,
                codes: action.data.codes,
                isFetched: true,
            };
        case FETCH_DELETE_PUBLIC_CODE.success: {
            return {
                ...state,
                codes: state.codes.filter(code => code.id !== action.data.id),
            };
        }
        case FETCH_CREATE_PUBLIC_CODE.success: {
            return {
                ...state,
                codes: [...state.codes, action.data.code],
            };
        }
        case CLEAR_VIEW_DATA:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

export default publicCodes;
